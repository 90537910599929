import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SearchIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/outline";
import {
  clearCourse,
  fetchCourse,
  searchClassList,
  sortClassList,
  fetchUserProgramsList,
  createClassModule,
} from "@classesaction";
import ContentHeader from "@contentheader";
import Pagination from "@pagination";
import { itemCount } from "@constant";
import ItemList from "@classelists";
import ClassCreate from "@classeadd";
import Loader from "@loader";
import LocaleStrings from "@language";
import classesimg from "../../assets/image/headerimages/classes.svg";
import blankImg from "../../assets/image/blank_classes.svg";

const Classes = (props) => {
  var { session, classList, globalLevels } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");
  var [sort, setSort] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  var [headerInfoTitle,setHeaderInfoTitle] = useState();


  useEffect(() => {
    props.clearCourse();
    props.createClassModule({ show: false });

    props.fetchCourse(session, search ,{
      userid: session?.userid,
      classmembers: true,
      myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
      classchapters: true,
    });

    var myClass = searchParams.has('myclass') ?  JSON.parse(searchParams.get('myclass')) : false;

    //SetHeaderTitle(myClassState == true ? "Mes classes" : "Bibliothèque de Quizzs");
    if(myClass == true){
      setHeaderInfoTitle("Mes classes");
    }else{
      setHeaderInfoTitle("Bibliothèque de Quizzs");
    }
    
    
    //props.fetchUserProgramsList(session);
  }, [searchParams]);

  const open = () => {
    props.createClassModule({ key: "create", show: true });
  };

  const handleActionButton = (obj) => {
    props.createClassModule({ ...obj });
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (classList?.count === 0) {
        props.clearCourse();
      }

      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
        },
        pageNum
      );
    } else {
      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
        },
        pageNum
      );
    }

    setSearch(search);
    setSort("");
    setPageNumber(pageNum);
  };

  const handleSort = (e) => {
    var sort = e.target.value;
    let pageNum = 1;
    console.log("handle sort")
    props.clearCourse();
    if (sort === "") {
      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
          sort : sort
        },
        pageNum
      );
    } else {
      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
          sort : sort,
        },
        pageNum
      );
    }

    setSort(sort);
    setSearch("");
    setPageNumber(pageNum);
  };

  const additionCallback = () => {
    props.fetchUserProgramsList(session);
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "" && sort !== "") {
      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
          sort
        },
        pageNum
      );
    } else if (search !== "" && sort === "") {
      props.searchClassList(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
        },
        pageNum
      );
    } else {
      props.fetchCourse(
        session,
        search,
        {
          userid: session?.userid,
          classmembers: true,
          myclass: searchParams.has('myclass') ? searchParams.get('myclass') : undefined,
          classchapters: true,
        },
        pageNum
      );
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return classList?.data?.map((item, index) => {
      return <ItemList key={`key_${index}`} listitem={item} />;
    });
  };

  var levels = [];
  if (globalLevels?.data) {
    var levelData = globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push({ value: item, text: item });
    });
  }

  
  return (
    <>
      <ContentHeader
        customData={{
          section: "classes",
          subsection: "own",
          image: classesimg,
          breadcrumbs: [{ key: "classes", text: headerInfoTitle }],
          button: [
            {
              key: "create",
              name: LocaleStrings.class_new_button,
              class: "theme-button-md",
              icon: PlusIcon,
            },
          ],
        }}
        handleAction={handleActionButton}
      />
      {classList?.data ? (
        <>
          <div className="content-body h-full">
            {search !== "" || sort !== "" || classList?.count > 0 ? (
              <div className="mb-5 2xs:mb-12 md:flex md:items-center md:justify-between">
                <div className="w-full 2xs:w-5/12">
                  <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                      <svg
                        className="h-6 w-6 2xs:h-8	2xs:w-8"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                          stroke="#1A242D"
                          strokeWidth="1.7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                          stroke="#1A242D"
                          strokeWidth="1.7"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="search-input-field"
                      placeholder={LocaleStrings.search}
                      type="search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className="mt-2 md:mt-0 w-full md:w-60">
                  <select
                    id="sortstudent"
                    name="sortstudent"
                    className="input-field"
                    value={sort}
                    onChange={handleSort}
                  >
                    <option value="">All</option>
                    {levels?.map((item) => {
                      return (
                        <option key={`key_${item?.value}`} value={item?.value}>
                          {item?.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}

            {classList?.count > 0 ? (
              <>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
                >
                  {renderItems()}
                </ul>

                {classList?.count > itemCount ? (
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={classList?.count}
                    onChange={paginationCallback}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="custom-item-center h-full">
                <div className="text-center">
                  <img className="inline" src={blankImg} />
                  <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                    {search === "" && sort === "" ? (
                      LocaleStrings.class_not_available
                    ) : (
                      <>
                        {LocaleStrings.class_search_not_available}
                        {" : "}
                        <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                          {sort ? sort : search ? search : ""}
                        </span>
                      </>
                    )}
                  </div>

                  {search === "" && sort === "" ? (
                    <>
                      <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                        {LocaleStrings.class_not_available_subtitle}
                      </p>
                      <div className="mt-6">
                        <button
                          type="button"
                          className="theme-button-md"
                          onClick={open}
                        >
                          <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          {LocaleStrings.class_new_button}
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          <ClassCreate finishCallback={additionCallback} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classList: state.classList,
    globalLevels: state.globalLevels,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCourse,
      fetchCourse,
      searchClassList,
      sortClassList,
      fetchUserProgramsList,
      createClassModule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Classes);
