import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { createClassModule, selectedClass } from "@classesaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import classImg from "../../../assets/image/defaultclassimg.png";

const ItemList = (props) => {
  const navigate = useNavigate();
  let { session, listitem } = props;

  const handleCLasses = () => {
    // console.log("listitem :- ", listitem);

    props.selectedClass(listitem);
    navigate(`${listitem.classid}`);
  };

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createClassModule({ key: "edit", show: true, value: listitem });
  };

  var displayMember = [];
  var classImage = listitem?.image
    ? `${BASE_IMAGES_URL}/${listitem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
    : classImg;
  var completedPercentage =
    listitem?.totalchapters > 0
      ? (parseInt(listitem?.totalcompletedchapters) /
          parseInt(listitem?.totalchapters)) *
        100
      : 0;
  // console.log("completedPercentage -> ", completedPercentage);

  return (
    <>
      <li
        className="bg-white col-span-1 border shadow-lg rounded-xl cursor-pointer hover:shadow-xl"
        onClick={handleCLasses}
      >
        <div className="w-full relative bg-theme rounded-tl-lg rounded-tr-lg">
          <div className="h-48">
            <img
              className="object-cover rounded-tl-lg rounded-tr-lg h-full w-full"
              src={classImage}
            />
          </div>

          {listitem?.createdby == session?.userid ? (
            <div className="absolute top-7 right-7">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="-m-2 p-2 flex items-center text-secondary hover:text-secondaryDark bg-white rounded-md py-2.5 px-2.5">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-7 w-7" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={(e) => editItem(e)}
                            className={classNames(
                              active ? "bg-blue-50" : "",
                              "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-secondary"
                            )}
                          >
                            <span>{LocaleStrings.button_edit}</span>
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="px-5 py-4 space-y-1">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1 space-y-3">
              <p className="text-xl sm:text-3xl text-primary font-semibold">
                {listitem?.classname} - {listitem?.programname}
              </p>
              <span className="flex-shrink-0 inline-block px-5 py-0.5 text-theme text-xl font-normal bg-blue-100 rounded-md ml-3 lg:ml-0">
                {listitem?.level}
              </span>
            </div>
          </div>
          {session?.userid === 1 ? (
            ""
          ) : (
            <>
              {/* <div className="space-y-2 pt-3">
                <div className="overflow-hidden h-2 flex rounded bg-blue-100">
                  <div
                    style={{
                      width: `${completedPercentage}%`,
                      backgroundColor:
                        listitem?.master_colors_by_coloridfk?.code,
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-theme"
                  />
                </div>
                <div className="text-lg font-normal text-secondary">
                  {listitem?.totalcompletedchapters} / {listitem?.totalchapters}{" "}
                  {LocaleStrings.class_text_chapters_completed}
                </div>
              </div> */}

              <div className="flex items-center pt-5 space-x-2">
                <div className="flex flex-shrink-0 -space-x-4">
                  {displayMember.map((member, index) => (
                    <span
                      key={member.memberid}
                      className="max-w-none h-10 w-10 rounded-full bg-theme ring ring-white"
                    >
                      {member?.image ? (
                        <img
                          className="max-w-none h-full w-full rounded-full"
                          src={
                            member?.image
                              ? `${BASE_IMAGES_URL}/${member?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  ))}
                </div>
                {listitem?.totalmembers >
                listitem?.programmembers_by_classidfk?.length && listitem.createdby == session.userid ? (
                  <span className="flex-shrink-0 text-lg font-normal text-secondary lowercase">
                    +
                    {listitem?.totalmembers -
                      listitem?.programmembers_by_classidfk?.length}{" "}
                    {LocaleStrings.members}
                  </span>
                ) : null}
              </div>

              <div className="text-lg font-normal text-secondary">
                {listitem?.userinvited}{" "}
                {LocaleStrings.class_text_invitations_sent}
              </div>
            </>
          )}
        </div>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createClassModule, selectedClass }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
