/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  handleDashboardQuizModal,
  fetchSingleSetAndQuestions,
} from "@dashboardaction";
import { attempteSet, attemptSetQstModule } from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { addSetFromMQSet } from "@classesaction";
import { fetchStudentAllClass } from "@studentsaction";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, CUSTOM_DATE_TIME_FORMAT } from "@constant";
import LocaleStrings from "@language";

const QuizSelectModal = (props) => {
  const cancelButtonRef = useRef(null);
  var { session, dashboardQuizModal, studentAllClassList } = props;
  var [loading, setLoading] = useState(false);
  var [selectedClass, setSelectedClass] = useState("");
  var [selectedChapter, setSelectedChapter] = useState("");
  var [selectedSet, setSelectedSet] = useState("");

  const close = () => {
    setSelectedClass("");
    setSelectedChapter("");
    setSelectedSet("");
    props.handleDashboardQuizModal({ show: false });
  };

  const handleClass = (e) => {
    var val = e.target.value;
    // console.log("class : - ", val);

    setSelectedClass(val);
    setSelectedChapter("");
    setSelectedSet("");
  };

  const handleChapter = (e) => {
    var val = e.target.value;
    // console.log("chapter : - ", val);

    setSelectedChapter(val);
    setSelectedSet("");
  };

  const handleSet = (e) => {
    var val = e.target.value;
    // console.log("set : - ", val);

    setSelectedSet(val);
  };

  const handleQuiz = () => {
    // console.log(selectedClass, selectedChapter, selectedSet);

    if (selectedClass && selectedChapter && selectedSet) {
      setLoading(true);

      // Create new dynamic set and then proceed
      if (selectedSet === "launch_mq_new_set") {
        // console.log("studentAllClassList :- ", studentAllClassList);
        var classChapter = _.filter(studentAllClassList?.data, (obj) => {
          return obj.classid == selectedClass;
        });

        var selectedCh = _.filter(
          classChapter?.[0]?.programchapters_by_classidfk,
          (obj) => {
            return obj?.chapterid == selectedChapter;
          }
        );
        var currentDateTime = convertDateIntoLocal(new Date()).format(
          CUSTOM_DATE_TIME_FORMAT
        );
        // console.log("selectedCh :- ", selectedCh);

        var values = {
          chapterid: selectedChapter,
          userid: session?.userid,
          setname: `${selectedCh?.[0]?.chaptername ?? "Chapter"} - Quiz ${
            selectedCh?.[0]?.totalquestionsets * 1 + 1 ?? 1
          } (${currentDateTime})`,
        };
        // console.log("values :- ", values);

        props.addSetFromMQSet(session, values, (res) => {
          // console.log("res :- ", res);

          if (res.success === 1 && res?.data?.resource?.length > 0) {
            props.fetchSingleSetAndQuestions(
              session,
              { setid: res?.data?.resource?.[0]?.setid },
              (response) => {
                // console.log("response :- ", response);
                if (response?.success) {
                  var type =
                    dashboardQuizModal?.key === "assessment_quiz"
                      ? "assessment"
                      : "practice";
                  var values = {
                    useridfk: session?.userid,
                    classidfk: response?.data?.[0]?.classidfk,
                    chapteridfk: response?.data?.[0]?.chapteridfk,
                    qestionsetidfk: response?.data?.[0]?.setid,
                    totalquestions:
                      response?.data?.[0]?.questions_by_questionsetidfk
                        ?.length ?? null,
                    mode: type,
                  };
                  // console.log("values :- ", values);
                  props.attempteSet(session, values, (res) => {
                    if (res?.success === 1) {
                      var aval = {
                        useridfk: session?.userid,
                        classidfk: response?.data?.[0]?.classidfk,
                        chapteridfk: response?.data?.[0]?.chapteridfk,
                        questionsetidfk: response?.data?.[0]?.setid,
                        activitytype:
                          type === "assessment"
                            ? "quiz_start"
                            : type === "practice" &&
                              response?.data?.[0]?.privacy === "private"
                            ? "quiz_practice_private"
                            : "quiz_practice",
                        relatedid: res?.data?.resource?.[0]?.id,
                      };
                      props.createUserActivity(session, aval, (res) => {});
                      // To get the chapter total sets count
                      props.fetchStudentAllClass(session, {
                        userid: session?.userid,
                        classchapters: true,
                        questionsets: true,
                      });
                      props.attemptSetQstModule({
                        show: true,
                        mode: type,
                        id: res?.data?.resource?.[0]?.id,
                        value: response?.data?.[0],
                      });
                      setLoading(false);
                      close();
                    }
                  });
                } else {
                  toast.error(LocaleStrings.common_fail_message);
                  setLoading(false);
                }
              }
            );
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        props.fetchSingleSetAndQuestions(
          session,
          { setid: selectedSet },
          (response) => {
            // console.log("response :- ", response);
            if (response?.success) {
              var type =
                dashboardQuizModal?.key === "assessment_quiz"
                  ? "assessment"
                  : "practice";
              var values = {
                useridfk: session?.userid,
                classidfk: response?.data?.[0]?.classidfk,
                chapteridfk: response?.data?.[0]?.chapteridfk,
                qestionsetidfk: response?.data?.[0]?.setid,
                totalquestions:
                  response?.data?.[0]?.questions_by_questionsetidfk?.length ??
                  null,
                mode: type,
              };
              // console.log("values :- ", values);
              props.attempteSet(session, values, (res) => {
                if (res?.success === 1) {
                  var aval = {
                    useridfk: session?.userid,
                    classidfk: response?.data?.[0]?.classidfk,
                    chapteridfk: response?.data?.[0]?.chapteridfk,
                    questionsetidfk: response?.data?.[0]?.setid,
                    activitytype:
                      type === "assessment"
                        ? "quiz_start"
                        : type === "practice" &&
                          response?.data?.[0]?.privacy === "private"
                        ? "quiz_practice_private"
                        : "quiz_practice",
                    relatedid: res?.data?.resource?.[0]?.id,
                  };
                  props.createUserActivity(session, aval, (res) => {});
                  props.attemptSetQstModule({
                    show: true,
                    mode: type,
                    id: res?.data?.resource?.[0]?.id,
                    value: response?.data?.[0],
                  });
                  setLoading(false);
                  close();
                }
              });
            } else {
              toast.error(LocaleStrings.common_fail_message);
              setLoading(false);
            }
          }
        );
      }
    }
  };

  // console.log("studentAllClassList :- ", studentAllClassList);

  var classes = [];
  _.map(studentAllClassList?.data, (obj) => {
    classes.push({
      value: obj?.classid,
      text: obj?.classname + " - " + obj?.programname,
    });
  });

  var classChapter = _.filter(studentAllClassList?.data, (obj) => {
    return obj.classid == selectedClass;
  });
  // console.log("classChapter :- ", classChapter);

  var chapters = [];
  _.map(classChapter?.[0]?.programchapters_by_classidfk, (obj) => {
    chapters.push({ value: obj?.chapterid, text: obj?.chaptername });
  });
  // console.log("chapters :- ", chapters);

  var sets = [];
  if (classChapter?.[0]?.createdby == 1 && selectedChapter !== "") {
    sets.push({
      value: "launch_mq_new_set",
      text: LocaleStrings.class_chapter_text_first_set,
    });
  }
  _.map(classChapter?.[0]?.questionsets_by_classidfk, (obj) => {
    if (obj?.classidfk == selectedClass && obj?.chapteridfk == selectedChapter)
      sets.push({ value: obj?.setid, text: obj?.setname });
  });
  // console.log("sets :- ", sets);

  return (
    <Transition.Root
      show={dashboardQuizModal?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex items-start justify-between">
                  <div>
                    <span className="sr-only">Blank space</span>
                  </div>
                  <Dialog.Title className="text-center text-2xl 2xs:text-4xl font-medium text-primary">
                    {dashboardQuizModal?.key === "practice_quiz"
                      ? LocaleStrings.button_practice_quiz
                      : LocaleStrings.button_take_quiz}
                  </Dialog.Title>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      type="button"
                      className="bg-white rounded-md text-secondary hover:text-secondaryDark outline-none"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon
                        className="w-3.5 h-3.5 2xs:h-6 2xs:w-6 text-primary"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-5 space-y-6">
                  <div className="space-y-2.5 2xs:space-y-4">
                    <label className="text-lg 2xs:text-2xl font-medium text-label">
                      {LocaleStrings.dashboard_label_program}
                    </label>
                    <select
                      id="program"
                      name="program"
                      className="block w-full max-w-full h-12 2xs:h-16 shadow-sm text-lg 2xs:text-xl font-normal text-secondary border-inputbox border-1 rounded-lg focus:ring-theme focus:border-theme"
                      onChange={handleClass}
                      value={selectedClass}
                    >
                      <option key={`program_key_0`} value="">
                        {LocaleStrings.dashboard_ph_program}
                      </option>
                      {_.map(classes, (obj, index) => {
                        return (
                          <option
                            key={`program_key_${index}`}
                            value={obj?.value}
                          >
                            {obj?.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="space-y-2.5 2xs:space-y-4">
                    <label className="text-lg 2xs:text-2xl font-medium text-label">
                      {LocaleStrings.dashboard_label_chapter}
                    </label>
                    <select
                      id="chapter"
                      name="chapter"
                      className="block w-full max-w-full h-12 2xs:h-16 shadow-sm text-lg 2xs:text-xl font-normal text-secondary border-inputbox border-1 rounded-lg focus:ring-theme focus:border-theme"
                      onChange={handleChapter}
                      value={selectedChapter}
                    >
                      <option key={`chapter_key_0`} value="">
                        {LocaleStrings.dashboard_ph_chapter}
                      </option>
                      {_.map(chapters, (obj, index) => {
                        return (
                          <option
                            key={`chapter_key_${index}`}
                            value={obj?.value}
                          >
                            {obj?.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="space-y-2.5 2xs:space-y-4">
                    <label className="text-lg 2xs:text-2xl font-medium text-label">
                      {LocaleStrings.dashboard_label_set}
                    </label>
                    <select
                      id="set"
                      name="set"
                      className="block w-full max-w-full h-12 2xs:h-16 shadow-sm text-lg 2xs:text-xl font-normal text-secondary border-inputbox border-1 rounded-lg focus:ring-theme focus:border-theme"
                      onChange={handleSet}
                      value={selectedSet}
                    >
                      <option key={`set_key_0`} value="">
                        {LocaleStrings.dashboard_ph_set}
                      </option>
                      {_.map(sets, (obj, index) => {
                        return (
                          <option key={`set_key_${index}`} value={obj?.value}>
                            {obj?.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="text-center">
                    <button
                      className="bg-4DB896 text-xl 2xs:text-2xl font-medium text-white py-3 px-8 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center w-full 2xs:w-auto focus:outline-none mt-5"
                      onClick={handleQuiz}
                      disabled={loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {dashboardQuizModal?.key === "practice_quiz"
                        ? LocaleStrings.button_practice_quiz
                        : LocaleStrings.button_take_quiz}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardQuizModal: state.dashboardQuizModal,
    studentAllClassList: state.studentAllClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleDashboardQuizModal,
      fetchSingleSetAndQuestions,
      attempteSet,
      attemptSetQstModule,
      createUserActivity,
      addSetFromMQSet,
      fetchStudentAllClass,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuizSelectModal);
