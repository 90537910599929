import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  CheckIcon,
  ThumbUpIcon,
  CalendarIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal } from "@constant";
import LocaleStrings from "@language";
import blankImg from "../../../assets/image/blank_my_activityD.svg";
import EmptyActivity from '../../../assets/image/EmptyActivity.svg'
import { useNavigate } from "react-router-dom";

const Myactivities = (props) => {
  var { userAllActivities } = props;
  const navigate = useNavigate();
  const renderActivity = () => {
    return _.map(userAllActivities?.data, (item, index) => (
      <li key={`activity_key_${index}`}>
        <div className="relative pb-8">
          {index !== userAllActivities?.count - 1 ? (
            <span
              className="absolute top-4 left-6 2xs:left-7 h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex item-center space-x-4">
            <div>
              <span
                className={classNames(
                  item?.activitytype === "quiz_practice_private" ||
                    item?.activitytype === "quiz_practice"
                    ? "bg-theme"
                    : item?.activitytype === "quiz_start" ||
                      item?.activitytype === "quiz_finish" ||
                      item?.activitytype === "quiz_initial_finish"
                    ? "bg-4DB896"
                    : item?.activitytype === "exam_date"
                    ? "bg-6A7687"
                    : item?.activitytype === "quiz_abort"
                    ? "bg-6A7687"
                    : "bg-gray-200",
                  "h-12 w-12 2xs:h-15 2xs:w-15 rounded-full flex items-center justify-center ring-8 ring-white"
                )}
              >
                {item?.activitytype === "quiz_practice_private" ||
                item?.activitytype === "quiz_practice" ? (
                  <ThumbUpIcon className="w-6 h-6 text-white" />
                ) : item?.activitytype === "quiz_start" ||
                  item?.activitytype === "quiz_finish" ||
                  item?.activitytype === "quiz_initial_finish" ? (
                  <CheckIcon className="w-6 h-6 text-white" />
                ) : item?.activitytype === "exam_date" ? (
                  <CalendarIcon className="w-6 h-6 text-white" />
                ) : item?.activitytype === "quiz_abort" ? (
                  <XCircleIcon className="w-6 h-6 text-white" />
                ) : (
                  <CheckIcon className="w-6 h-6 text-white" />
                )}
              </span>
            </div>

            <div className="min-w-0 flex-1 flex item-center justify-between space-x-4">
              <div className="flex items-center">
                <div className="normal-case">
                  <span className="text-xl 2xs:text-xl font-normal text-primary">
                    {item?.activitytype === "quiz_practice_private"
                      ? LocaleStrings.student_progress_text_practic_private_set
                      : item?.activitytype === "quiz_practice"
                      ? LocaleStrings.student_progress_text_practiced_set
                      : item?.activitytype === "quiz_start"
                      ? LocaleStrings.student_progress_text_started_set
                      : item?.activitytype === "quiz_finish"
                      ? LocaleStrings.student_progress_text_completed_set
                      : item?.activitytype === "quiz_initial_finish"
                      ? LocaleStrings.student_progress_text_initial_completed_set
                      : item?.activitytype === "quiz_abort"
                      ? LocaleStrings.student_progress_text_aborted_set
                      : item?.activitytype === "exam_date"
                      ? LocaleStrings.student_progress_text_added_exam
                      : ""}
                  </span>{" "}
                  <span className="text-xl 2xs:text-xl font-normal text-primary">
                    {item?.activitytype === "quiz_practice_private" ||
                    item?.activitytype === "exam_date"
                      ? item?.programchapters_by_chapteridfk?.chaptername
                      : item?.questionsets_by_questionsetidfk?.setname}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-right whitespace-nowrap text-xl 2xs:text-xl font-normal text-primary">
                <time dateTime={item.createdon}>
                  {convertDateIntoLocal(item?.createdon).format("MMM, DD")}
                </time>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  // console.log("userAllActivities :- ", userAllActivities);

  return (
    <>
      <div className="text-2xl sm:text-3xl font-semibold text-primary capitalize mb-8">
        {LocaleStrings.dashboard_text_my_activity}
      </div>
      {userAllActivities?.count > 0 ? (
        <div className="px-4 py-4 border shadow-lg rounded-xl overflow-auto h-80">
          <ul role="list" className="-mb-8">
            {renderActivity()}
          </ul>
        </div>
      ) : (
        <div className="custom-item-center max-w-full mx-auto border shadow-lg rounded-xl h-80 p-4">
          <div className="custom-item-center w-full 2xl:w-80% 2xl:m-auto h-full p-4">
            <div className="text-center flex items-center space-x-3 2xs:space-x-5">
              <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                <img className="inline w-full h-full" src={EmptyActivity} />
              </div>
              <div className="mt-7 text-xl 2xs:text-xl font-medium text-primary">
                Avec Kojyto, renforce ta mémoire et tes connaissances dans toutes les matières !
                <p>
                  <button onClick={() => navigate(`/myspace`)} className="mt-10 text-lg font-light py-1.5 text-white w-full bg-gradient-to-r from-[#675af0] to-[#f0a45e] rounded-3xl">Réponds à ton premier quizz</button>
                </p>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userAllActivities: state.userAllActivities,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Myactivities);
