import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm } from "redux-form";
import { useParams } from "react-router-dom";
import _ from "lodash";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, TrashIcon, MenuIcon, PlusIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import {
  createSetModule,
  addQusetionSet,
  updateQusetionSet,
  deleteBulkQuestions,
  importSetModule,
  clearGlobalSetList,
  fetchMyGlobalSetList,
  notifyClassAboutSet,
} from "@classesaction";
import { termsImportModal, importSetTerms } from "@questionsetsaction";
import { BASE_IMAGES_URL } from "@constant";
import {
  renderAutoComplete,
  renderFieldRadio,
  renderFieldText,
  renderHiddenFieldText,
  renderFieldTextarea,
  renderSelect,
  renderFieldCheckbox,
  classNames,
} from "@basecomponent";
import ImageCropper from "@imageupload";
import SetTermsImport from "@questionsettermsimport";
import LocaleStrings from "@language";
import { InlineMath, BlockMath } from 'react-katex';

var defaultArrayData = {
  questions_by_questionsetidfk: [
    {
      question: "",
      image: "",
      questionmode: "term",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: "",
      difficulty: "medium",
      skill: "general",
      duration: "",
      openterm: true,
    },
  ],
};

const difficulties = [
  {
    value: "high",
    text: LocaleStrings.class_chapter_set_form_label_difficulty_option1,
  },
  {
    value: "medium",
    text: LocaleStrings.class_chapter_set_form_label_difficulty_option2,
  },
  {
    value: "low",
    text: LocaleStrings.class_chapter_set_form_label_difficulty_option3,
  },
];

const skills = [
  {
    value: "memory",
    text: LocaleStrings.class_chapter_set_form_label_skill_option1,
  },
  {
    value: "general",
    text: LocaleStrings.class_chapter_set_form_label_skill_option2,
  },
];

const removeQuestion = (fields, index) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-confirm-alert-body">
          <div className="confirmation-title">
            {LocaleStrings.class_chapter_set_form_question_delete_title}
          </div>
          <div className="confirmation-subtitle">
            {LocaleStrings.class_chapter_set_form_question_delete_text}
          </div>

          <div className="custom-confirm-alert-button-group">
            <button className="confirmation-action-button" onClick={onClose}>
              {LocaleStrings.button_no}
            </button>
            <button
              className="confirmation-action-button"
              onClick={() => {
                onClose();
                fields.remove(index);
              }}
            >
              {LocaleStrings.button_yes}
            </button>
          </div>
        </div>
      );
    },
  });
};

const renderQuestions = ({
  fields,
  meta: { error, submitFailed },
  updateTermOpen,
  updateImage,
  updateImageChange,
  updateQstMode,
  updateMcqCorrectAns,
  session,
  createQuestionSet,
  initialValues,
}) => {
  // console.log("fields: ", fields);
  // console.log("initialValues: ", initialValues);
  const convertForMath = (question) => {
    if(question.includes("```math")){
      /* /(?<=```math)(.*)(?=```)/g  */
      const startString = question.split("```math")
      const endString = question.replace('```math', "").split("```")
      const finalData = []
      for(const k in startString){
        if(startString[k].includes("```")){
          let first = true
          for (const v of startString[k].split("```")){
            if(first == true){
              finalData.push({type: "math", content: v})
              first = false
            }else{
              finalData.push({type: "text", content: v})
            }
          }
        }else{
          finalData.push({type: "text",content: startString[k]})
        }
      }
      console.log(startString)

      console.log(finalData)
      
      return (
        finalData.map((data,key) =>
          <>
          {console.log(data)}
            {data.type === "text"
              ? <span>{data.content}</span>
              : <InlineMath math={data.content} />
            }
          </>
        )
      )
    }


    return question
  }

  return (
    <ul className="space-y-4">
      {fields.map((obj, index) => {
        var currentInxedData = fields.get(index);
        var imagePreviewUrl = "";
        var forChangeMode =
          currentInxedData?.questionmode === "term" ? "mcq" : "term";
        var allowClose = false;
        if (
          (currentInxedData?.question !== "" &&
            currentInxedData?.answer !== "") ||
          (currentInxedData?.questionmode === "mcq" &&
            currentInxedData?.question !== "" &&
            currentInxedData?.answer !== "" &&
            currentInxedData?.option1 !== "" &&
            currentInxedData?.option2 !== "" &&
            currentInxedData?.radio_qst !== "")
        ) {
          allowClose = true;
        }
        // console.log("currentInxedData :- ", currentInxedData);
        if (
          currentInxedData?.image &&
          currentInxedData?.image.search(";base64,") > 0
        ) {
          imagePreviewUrl = currentInxedData?.image;
        } else if (currentInxedData?.image && currentInxedData?.questionid) {
          imagePreviewUrl = `${BASE_IMAGES_URL}/${currentInxedData?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;
        }
        // console.log("imagePreviewUrl :- ", index + "-" + imagePreviewUrl);

        return (
          <li key={index}>
            <div className="border rounded-lg shadow-lg">
              {/* header */}
              <div className="border-b-1 border-defaultColor px-3 py-3 flex justify-between">
                <span className="text-xl 2xs:text-2xl font-normal text-primary">
                  {index + 1}.&nbsp;
                  {currentInxedData?.questionmode === "term"
                    ? LocaleStrings.class_chapter_set_add_form_text_term
                    : LocaleStrings.class_chapter_set_add_form_text_mcq}
                </span>
                <div className="flex space-x-4">
                  {createQuestionSet?.key === "clone_set" ? (
                    ""
                  ) : (
                    <>
                      {fields.length > 1 ? (
                        <>
                          {(initialValues?.totalattempted > 0 ||
                            initialValues?.totalpracticed > 0) &&
                          currentInxedData?.questionid ? (
                            ""
                          ) : (
                            <TrashIcon
                              className="h-5 w-5 2xs:h-7 2xs:w-7 cursor-pointer text-customred"
                              onClick={
                                currentInxedData?.questionid
                                  ? () => removeQuestion(fields, index)
                                  : () => fields.remove(index)
                              }
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  <MenuIcon
                    className={classNames(
                      allowClose ? "cursor-pointer" : "",
                      "h-5 w-5 2xs:h-7 2xs:w-7"
                    )}
                    onClick={
                      allowClose
                        ? () =>
                            updateTermOpen(index, !currentInxedData?.openterm)
                        : null
                    }
                  />
                </div>
              </div>

              {/* body */}
              {currentInxedData?.openterm ? (
                <div className="grid grid-cols-6 space-y-2 px-3 py-4">
                  <div className="col-span-6 md:col-span-6 z-10">
                    {createQuestionSet?.key === "clone_set" ? (
                      <div className={`image-section image-display h-40`}>
                        <img
                          src={imagePreviewUrl}
                          alt=""
                          className={`img-responsive h-full w-full object-contain`}
                        />
                      </div>
                    ) : (
                      <>
                        <ImageCropper
                          onImageSave={(img) => updateImage(index, img)}
                          onImageChange={(bool) =>
                            updateImageChange(index, bool)
                          }
                          imagepath={imagePreviewUrl}
                          displayText={true}
                          imageType="jpg"
                          dropboxClassName="h-40 flex justify-center items-center px-6 pt-5 pb-6 border-2 border-inputbox border-dashed rounded-md"
                          imageClassName="h-40 rounded-md border border-defaultColor"
                        />
                        <Field
                          name={`${obj}.chaptericon`}
                          type="text"
                          component={renderHiddenFieldText}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    {createQuestionSet?.key === "clone_set" ? (
                      <div className="input-div-disabled-field">
                        <span>{currentInxedData?.question}</span>
                      </div>
                    ) : (
                      <Field
                        name={`${obj}.question`}
                        label={LocaleStrings.class_chapter_set_form_label_term}
                        placeholder={
                          LocaleStrings.class_chapter_set_form_ph_term
                        }
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                        maxlength={450}
                      />
                    )}
                  </div>
                  {currentInxedData?.questionmode === "term" ? (
                    <div className="col-span-6">
                      {createQuestionSet?.key === "clone_set" ? (
                        <div className="input-div-disabled-field mt-2">
                          <span>{convertForMath(currentInxedData?.answer)}</span>
                        </div>
                      ) : (
                        <Field
                          name={`${obj}.answer`}
                          label={
                            LocaleStrings.class_chapter_set_form_label_definition
                          }
                          placeholder={
                            LocaleStrings.class_chapter_set_form_ph_definition
                          }
                          component={renderFieldTextarea}
                          mandatory={
                            currentInxedData?.questionmode === "term"
                              ? "true"
                              : "false"
                          }
                          isdisabled={currentInxedData?.questionmode === "mcq"}
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-span-6">
                    {createQuestionSet?.key === "clone_set" ? (
                      ""
                    ) : (
                      <>
                        <div className="flex item-center space-x-2 pt-3 pb-3">
                          <svg
                            className="h-5 w-5 2xs:h-7 2xs:w-7"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 4H14M1 8H10M1 12H7M1 16H10"
                              stroke="#0098f1"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 9V12M15 12V15M15 12H18M15 12H12"
                              stroke="#0098f1"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span
                            className="cursor-pointer text-xl 2xs:text-2xl font-normal text-theme"
                            onClick={() => updateQstMode(index, forChangeMode)}
                          >
                            {currentInxedData?.questionmode === "term"
                              ? LocaleStrings.class_chapter_set_add_form_btn_add_mcq
                              : LocaleStrings.class_chapter_set_add_form_btn_remove_mcq}
                          </span>
                        </div>
                        <Field
                          name={`${obj}.questionmode`}
                          type="text"
                          component={renderHiddenFieldText}
                        />
                      </>
                    )}
                  </div>
                  {currentInxedData?.questionmode === "term" ? (
                    ""
                  ) : (
                    <>
                      <div className="col-span-6">
                        <label className="control-label-top">
                          {LocaleStrings.class_chapter_set_form_label_option1}
                          <span className="label-mandatory">*</span>
                        </label>
                        <div className="custom-item-vcenter space-x-1">
                          {createQuestionSet?.key === "clone_set" ? (
                            <>
                              <span
                                className={classNames(
                                  currentInxedData?.radio_qst === "option1"
                                    ? "bg-blue-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 rounded-full flex items-center justify-center mr-3"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    currentInxedData?.radio_qst === "option1"
                                      ? "bg-blue-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 rounded-full"
                                  )}
                                />
                              </span>
                              <div className="input-div-disabled-field-full">
                                <span>{currentInxedData?.option1}</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="custom-item-vcenter">
                                <Field
                                  name={`${obj}.radio_qst`}
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="option1"
                                  onChange={() =>
                                    updateMcqCorrectAns(
                                      index,
                                      currentInxedData?.option1
                                    )
                                  }
                                  disabled={
                                    currentInxedData?.option1 === ""
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  name={`${obj}.option1`}
                                  type="text"
                                  placeholder={
                                    LocaleStrings.class_chapter_set_form_ph_option1
                                  }
                                  component={renderFieldText}
                                  onChange={
                                    currentInxedData?.radio_qst === "option1"
                                      ? (e) =>
                                          updateMcqCorrectAns(
                                            index,
                                            e.target.value
                                          )
                                      : null
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-span-6">
                        <label className="control-label-top">
                          {LocaleStrings.class_chapter_set_form_label_option2}
                          <span className="label-mandatory">*</span>
                        </label>
                        <div className="custom-item-vcenter space-x-1">
                          {createQuestionSet?.key === "clone_set" ? (
                            <>
                              <span
                                className={classNames(
                                  currentInxedData?.radio_qst === "option2"
                                    ? "bg-blue-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 rounded-full flex items-center justify-center mr-3"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    currentInxedData?.radio_qst === "option2"
                                      ? "bg-blue-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 rounded-full"
                                  )}
                                />
                              </span>
                              <div className="input-div-disabled-field-full">
                                <span>{currentInxedData?.option2}</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="custom-item-vcenter">
                                <Field
                                  name={`${obj}.radio_qst`}
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="option2"
                                  onChange={() =>
                                    updateMcqCorrectAns(
                                      index,
                                      currentInxedData?.option2
                                    )
                                  }
                                  disabled={
                                    currentInxedData?.option2 === ""
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  name={`${obj}.option2`}
                                  type="text"
                                  placeholder={
                                    LocaleStrings.class_chapter_set_form_ph_option2
                                  }
                                  component={renderFieldText}
                                  onChange={
                                    currentInxedData?.radio_qst === "option2"
                                      ? (e) =>
                                          updateMcqCorrectAns(
                                            index,
                                            e.target.value
                                          )
                                      : null
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-span-6">
                        <label className="control-label-top">
                          {LocaleStrings.class_chapter_set_form_label_option3}
                        </label>
                        <div className="custom-item-vcenter space-x-1">
                          {createQuestionSet?.key === "clone_set" ? (
                            <>
                              <span
                                className={classNames(
                                  currentInxedData?.radio_qst === "option3"
                                    ? "bg-blue-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 rounded-full flex items-center justify-center mr-3"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    currentInxedData?.radio_qst === "option3"
                                      ? "bg-blue-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 rounded-full"
                                  )}
                                />
                              </span>
                              <div className="input-div-disabled-field-full">
                                <span>
                                  {currentInxedData?.option3 != ""
                                    ? currentInxedData?.option3
                                    : "-"}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="custom-item-vcenter">
                                <Field
                                  name={`${obj}.radio_qst`}
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="option3"
                                  onChange={() =>
                                    updateMcqCorrectAns(
                                      index,
                                      currentInxedData?.option3
                                    )
                                  }
                                  disabled={
                                    currentInxedData?.option3 === ""
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  name={`${obj}.option3`}
                                  type="text"
                                  placeholder={
                                    LocaleStrings.class_chapter_set_form_ph_option3
                                  }
                                  component={renderFieldText}
                                  onChange={
                                    currentInxedData?.radio_qst === "option3"
                                      ? (e) =>
                                          updateMcqCorrectAns(
                                            index,
                                            e.target.value
                                          )
                                      : null
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-span-6">
                        <label className="control-label-top">
                          {LocaleStrings.class_chapter_set_form_label_option4}
                        </label>
                        <div className="custom-item-vcenter space-x-1">
                          {createQuestionSet?.key === "clone_set" ? (
                            <>
                              <span
                                className={classNames(
                                  currentInxedData?.radio_qst === "option4"
                                    ? "bg-blue-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 rounded-full flex items-center justify-center mr-3"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    currentInxedData?.radio_qst === "option4"
                                      ? "bg-blue-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 rounded-full"
                                  )}
                                />
                              </span>
                              <div className="input-div-disabled-field-full">
                                <span>
                                  {currentInxedData?.option4 != ""
                                    ? currentInxedData?.option4
                                    : "-"}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="custom-item-vcenter">
                                <Field
                                  name={`${obj}.radio_qst`}
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="option4"
                                  onChange={() =>
                                    updateMcqCorrectAns(
                                      index,
                                      currentInxedData?.option4
                                    )
                                  }
                                  disabled={
                                    currentInxedData?.option4 === ""
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  name={`${obj}.option4`}
                                  type="text"
                                  placeholder={
                                    LocaleStrings.class_chapter_set_form_ph_option4
                                  }
                                  component={renderFieldText}
                                  onChange={
                                    currentInxedData?.radio_qst === "option4"
                                      ? (e) =>
                                          updateMcqCorrectAns(
                                            index,
                                            e.target.value
                                          )
                                      : null
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="px-3 py-3 space-y-2 sm:space-y-0 sm:space-x-2 text-xl 2xs:text-2xl font-normal text-primary">
                  {imagePreviewUrl !== "" && (
                    <div
                      className={`image-section image-display h-40 mb-2 rounded-md border border-defaultColor`}
                    >
                      <img
                        src={imagePreviewUrl}
                        alt=""
                        className={`img-responsive h-full w-full object-contain`}
                      />
                    </div>
                  )}
                  {currentInxedData?.question !== "" ? (
                    <div className="">{convertForMath(currentInxedData?.question)}</div>
                  ) : (
                    <>
                      {currentInxedData?.questionmode === "term"
                        ? LocaleStrings.class_chapter_set_add_form_text_term
                        : LocaleStrings.class_chapter_set_add_form_text_mcq}
                    </>
                  )}
                </div>
              )}

              {/* footer */}
              {currentInxedData?.openterm ? (
                <div className="border-t-1 border-defaultColor px-3 py-3 ">
                  <div className="gap-3 sm:flex sm:items-center">
                    {createQuestionSet?.key === "clone_set" ? (
                      <>
                        <div className="input-div-disabled-field">
                          <span>{currentInxedData?.difficulty}</span>
                        </div>
                        <div className="input-div-disabled-field">
                          <span>{currentInxedData?.skill}</span>
                        </div>
                        <div className="input-div-disabled-field">
                          <span>
                            {currentInxedData?.duration} - (in minutes)
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-full sm:w-1/3">
                          <Field
                            name={`${obj}.difficulty`}
                            component={renderSelect}
                            mandatory="false"
                            opts={difficulties}
                          />
                        </div>
                        <div className="w-full sm:w-1/3">
                          <Field
                            name={`${obj}.skill`}
                            component={renderSelect}
                            mandatory="false"
                            opts={skills}
                          />
                        </div>
                        <div className="w-full sm:w-1/3">
                          <Field
                            name={`${obj}.duration`}
                            placeholder={
                              LocaleStrings.class_chapter_set_form_ph_duration
                            }
                            type="text"
                            component={renderFieldText}
                            mandatory="false"
                            maxlength={2}
                          />
                        </div>

                      </>
                    )}
                  </div>
                  <div id="chatgpt">
                    <Field
                      name={`${obj}.chatgpt_allowed`}
                      label="Activer la génération de questions par intelligence artificielle"
                      sublabel=""
                      component={renderFieldCheckbox}
                      format={v => v === 1}
                      normalize={v => v ? 1 : 0}
                      type="checkbox"
                    />
                    
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </li>
        );
      })}
      <li>
        {createQuestionSet?.key === "clone_set" ? (
          ""
        ) : (
          <div className="flex mt-3 items-center justify-end">
            <button
              type="button"
              className="theme-button-md"
              onClick={() =>
                fields.push({
                  question: "",
                  image: "",
                  questionmode: "term",
                  option1: "",
                  option2: "",
                  option3: "",
                  option4: "",
                  answer: "",
                  difficulty: "medium",
                  skill: "general",
                  duration: "",
                  openterm: true,
                })
              }
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {LocaleStrings.class_chapter_set_add_form_btn_new_term}
            </button>
          </div>
        )}
        <div className="text-help">
          {submitFailed && error && <span>{error}</span>}
        </div>
      </li>
    </ul>
  );
};

const QuestionsetCreate = (props) => {
  const { classid, chapterid } = useParams();
  var {
    handleSubmit,
    reset,
    pristine,
    invalid,
    submitting,
    autofill,
    session,
    createQuestionSet,
    importTermsModal,
    initialValues,
    toEdit,
    classDetails,
    classChapterDetails,
    globalLevels,
  } = props;
  var [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [createQuestionSet?.show]);

  const close = () => {
    props.createSetModule({ show: false });
    props.importSetTerms({ importedTerms: false });
    setLoading(false);

    if (createQuestionSet?.key === "clone_set") {
      props.clearGlobalSetList();
      props.importSetModule({ key: "import_set", show: true });
      props.fetchMyGlobalSetList(session);
    }
  };

  const close1 = () => {};

  const onSubmitForm = (data) => {
    var values = { ...data };
    // console.log("values :- ", values);
    // console.log("initialValues :- ", initialValues);

    let lev = values.levels;

    let tags = _.map(lev, (el) => el.value);
    values.levels = tags.join(",");

    setLoading(true);
    if (createQuestionSet?.key === "clone_set") {
      values.classidfk = classid;
      values.chapteridfk = chapterid;
      values.createdby = session?.userid;
      values.creator = session?.usertype;
      values.importedfrom = values?.setid;
      values.type = "program";
      if (session?.usertype === "student") {
        values.privacy = "private";
      }

      delete values["setid"]; // Removing unwanted elememts which is not required to clone
      delete values["createdon"]; // Removing unwanted elememts which is not required to clone
      delete values["updatedon"]; // Removing unwanted elememts which is not required to clone
      delete values["classprograms_by_classidfk"]; // Removing unwanted elememts which is not required to clone
      delete values["programchapters_by_chapteridfk"]; // Removing unwanted elememts which is not required to clone
      delete values["questionsets_by_setid"]; // Removing unwanted elememts which is not required to clone
      delete values["users_by_createdby"]; // Removing unwanted elememts which is not required to update

      _.map(values?.questions_by_questionsetidfk, (o) => {
        delete o["questionid"]; // Removing unwanted elememts which is not required to clone
        delete o["questionsetidfk"]; // Removing unwanted elememts which is not required to clone
        delete o["createdon"]; // Removing unwanted elememts which is not required to clone
        delete o["updatedon"]; // Removing unwanted elememts which is not required to clone
      });

      // console.log("final values :- ", values);
      props.addQusetionSet(session, values, (res) => {
        setLoading(false);
        if (res.success == 1) {
          toast.success(
            LocaleStrings.class_chapter_set_import_clone_add_success
          );
          props.finishCallback();
          close();

          if (values?.notifyclass === true) {
            props.notifyClassAboutSet(
              session,
              res?.data?.resource?.[0]?.setid,
              (response) => {}
            );
          }
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      if (toEdit) {
        delete values["users_by_createdby"]; // Removing unwanted elememts which is not required to update
        delete values["questionsets_by_setid"]; // Removing unwanted elememts which is not required to update
        delete values["programchapters_by_chapteridfk"]; // Removing unwanted elememts which is not required to update
        delete values["classprograms_by_classidfk"]; // Removing unwanted elememts which is not required to update

        var deleteData = [];
        _.map(initialValues?.questions_by_questionsetidfk, (ob) => {
          var match = _.filter(values?.questions_by_questionsetidfk, (o) => {
            return ob.questionid == o.questionid;
          });

          if (match.length === 0) {
            deleteData.push(ob);
          }
        });

        var deleteQuestion = [];
        deleteData.map((obj) => {
          deleteQuestion.push({
            questionid: obj.questionid,
          });
        });

        props.updateQusetionSet(session, values, (res) => {
          setLoading(false);
          if (res.success == 1) {
            if (deleteQuestion.length > 0) {
              props.deleteBulkQuestions(session, deleteQuestion, (response) => {
                toast.success(
                  LocaleStrings.class_chapter_set_form_update_success
                );
                props.finishCallback();
                close();

                if (values?.notifyclass === true) {
                  props.notifyClassAboutSet(
                    session,
                    res?.data?.resource?.[0]?.setid,
                    (response) => {}
                  );
                }
              });
            } else {
              toast.success(
                LocaleStrings.class_chapter_set_form_update_success
              );
              props.finishCallback();
              close();

              if (values?.notifyclass === true) {
                props.notifyClassAboutSet(
                  session,
                  res?.data?.resource?.[0]?.setid,
                  (response) => {}
                );
              }
            }
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        values.classidfk = classid;
        values.chapteridfk = chapterid;
        values.createdby = session?.userid;
        values.creator = session?.userid === 1 ? "mq" : session?.usertype; // userid 1 represent to mq teacer
        props.addQusetionSet(session, values, (res) => {
          setLoading(false);
          if (res.success == 1) {
            toast.success(LocaleStrings.class_chapter_set_form_add_success);
            props.finishCallback();
            close();

            if (values?.notifyclass === true) {
              props.notifyClassAboutSet(
                session,
                res?.data?.resource?.[0]?.setid,
                (response) => {}
              );
            }
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    }
  };

  const handleTermOpenClose = (index, status) => {
    // console.log("status :- ", status);
    autofill(`questions_by_questionsetidfk[${index}].openterm`, status);
  };

  const onProfileImageSave = (index, img) => {
    // console.log("index :- ", index);
    // console.log("img :- ", img);
    props.autofill(
      `questions_by_questionsetidfk[${index}].image`,
      img == "" ? "" : img
    );
  };

  const onProfileImageChange = (index, bool) => {};

  const handleQuestionMode = (index, forChangeMode) => {
    // console.log("forChangeMode :- ", forChangeMode);
    autofill(
      `questions_by_questionsetidfk[${index}].questionmode`,
      forChangeMode
    );
    autofill(`questions_by_questionsetidfk[${index}].answer`, "");
    autofill(`questions_by_questionsetidfk[${index}].option1`, "");
    autofill(`questions_by_questionsetidfk[${index}].option2`, "");
    autofill(`questions_by_questionsetidfk[${index}].option3`, "");
    autofill(`questions_by_questionsetidfk[${index}].option4`, "");
    autofill(`questions_by_questionsetidfk[${index}].radio_qst`, "");
  };

  const handleMcqCorrectAns = (index, ans) => {
    // console.log("index :- ", index);
    // console.log("ans :- ", ans);
    autofill(`questions_by_questionsetidfk[${index}].answer`, ans);
  };

  const openImportModal = () => {
    props.termsImportModal({ show: true });
  };

  var levels = [];
  if (globalLevels?.data) {
    var levelData = globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push({ value: item, label: item });
    });
  }

  return (
    <>
      <Transition.Root
        show={createQuestionSet?.show ? true : false}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={close1}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-2xl">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col">
                        <div className="px-4 py-4 sm:px-6 bg-default">
                          <div className="flex items-start justify-between">
                            <Dialog.Title>
                              <div className="modal-title-text">
                                {createQuestionSet?.key === "clone_set" ? (
                                  LocaleStrings.class_chapter_set_import_title
                                ) : (
                                  <>
                                    {toEdit
                                      ? LocaleStrings.class_chapter_set_edit_form_title
                                      : LocaleStrings.class_chapter_set_add_form_title}
                                  </>
                                )}
                              </div>
                              <div className="text-xl 2xs:text-xl font-normal text-secondary">
                                {classDetails?.data?.classname ?? ""} -{" "}
                                {classChapterDetails?.data?.chaptername ?? ""}
                              </div>
                            </Dialog.Title>
                            <div className="flex items-center">
                              <button
                                type="button"
                                className="outline-none"
                                onClick={() => close()}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon
                                  className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="p-6 relative flex-1 overflow-y-auto">
                          <div className="base-form">
                            {createQuestionSet?.key === "clone_set" ? (
                              <div className="text-lg 2xs:text-xl font-normal text-theme">
                                {
                                  LocaleStrings.class_chapter_set_import_text_imported_from
                                }
                                <span className="font-semibold">
                                  {" "}
                                  {initialValues?.setname ?? ""}
                                </span>
                              </div>
                            ) : (
                              <>
                                {toEdit ? (
                                  <div className="text-lg 2xs:text-xl font-normal text-theme">
                                    {
                                      LocaleStrings.class_chapter_set_form_update_note1
                                    }
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            <div className="grid grid-cols-5">
                              {/* <div className="col-span-6 sm:col-span-2 space-y-1 2xs:space-y-3">
                              <label
                                htmlFor="type"
                                className="control-label-top"
                              >
                                {
                                  LocaleStrings.class_chapter_set_form_label_createdfor
                                }
                                <span className="label-mandatory">*</span>
                              </label>
                              <div className="flex space-x-5">
                                <Field
                                  name="type"
                                  label={
                                    LocaleStrings.class_chapter_set_form_createdfor_option1
                                  }
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="program"
                                />
                                <Field
                                  name="type"
                                  label={
                                    LocaleStrings.class_chapter_set_form_createdfor_option2
                                  }
                                  type="radio"
                                  component={renderFieldRadio}
                                  value="general"
                                />
                              </div>
                            </div> */}
                              {session?.usertype === "student" ||
                              session?.userid === 1 ? (
                                ""
                              ) : (
                                <div className="col-span-6 sm:col-span-3 space-y-1 2xs:space-y-3 mt-3 sm:mt-0">
                                  <label
                                    htmlFor="privacy"
                                    className="control-label-top"
                                  >
                                    {
                                      LocaleStrings.class_chapter_set_form_label_privacy
                                    }
                                    <span className="label-mandatory">*</span>
                                  </label>
                                  <div className="flex space-x-5">
                                    <Field
                                      name="privacy"
                                      label={
                                        LocaleStrings.class_chapter_set_form_privacy_option1
                                      }
                                      type="radio"
                                      component={renderFieldRadio}
                                      value="private"
                                    />
                                    <Field
                                      name="privacy"
                                      label={
                                        LocaleStrings.class_chapter_set_form_privacy_option2
                                      }
                                      type="radio"
                                      component={renderFieldRadio}
                                      value="global"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <Field
                              name="levels"
                              label={
                                LocaleStrings.class_chapter_set_form_label_level
                              }
                              placeholder={
                                LocaleStrings.class_chapter_set_form_ph_level
                              }
                              component={renderAutoComplete}
                              mandatory="true"
                              isMulti={true}
                              options={levels}
                            />
                            <Field
                              name="setname"
                              label={
                                LocaleStrings.class_chapter_set_form_label_title
                              }
                              placeholder={
                                LocaleStrings.class_chapter_set_form_ph_title
                              }
                              type="text"
                              component={renderFieldText}
                              mandatory="true"
                              maxlength={150}
                            />
                            <div className="flex items-center justify-between">
                              <h1 className="text-xl 2xs:text-3xl font-normal text-label pt-2 pb-2">
                                {
                                  LocaleStrings.class_chapter_set_add_form_text_create_terms
                                }
                              </h1>
                              {toEdit === false ? (
                                <h1
                                  className="text-xl 2xs:text-3xl font-normal text-theme pt-2 pb-2 cursor-pointer hover:underline"
                                  onClick={openImportModal}
                                >
                                  {
                                    LocaleStrings.class_chapter_set_add_form_text_import
                                  }
                                </h1>
                              ) : (
                                ""
                              )}
                            </div>
                            <FieldArray
                              name="questions_by_questionsetidfk"
                              component={renderQuestions}
                              updateTermOpen={handleTermOpenClose}
                              updateImage={onProfileImageSave}
                              updateImageChange={onProfileImageChange}
                              updateQstMode={handleQuestionMode}
                              updateMcqCorrectAns={handleMcqCorrectAns}
                              session={session}
                              createQuestionSet={createQuestionSet}
                              initialValues={initialValues}
                            />
                            {session?.usertype === "teacher" ? (
                              <Field
                                name="notifyclass"
                                label={
                                  LocaleStrings.class_chapter_set_form_label_notify_class
                                }
                                sublabel={
                                  LocaleStrings.class_chapter_set_form_sublabel_notify_class
                                }
                                component={renderFieldCheckbox}
                                type="checkbox"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 px-4 py-4 flex justify-end gap-3">
                        <button
                          type="button"
                          className="default-button-md"
                          onClick={() => close()}
                        >
                          {LocaleStrings.button_cancel}
                        </button>

                        {/* For import set allow to direct save without any changes or new set restrice to save without any changes or valid data*/}
                        <button
                          type="submit"
                          disabled={
                            createQuestionSet?.key === "clone_set"
                              ? false
                              : pristine || invalid || submitting || loading
                          }
                          className="theme-button-md"
                        >
                          <svg
                            className={classNames(
                              loading ? "" : "hidden",
                              "cust-btn-spinner"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="btnloading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {createQuestionSet?.key === "clone_set"
                            ? LocaleStrings.button_import
                            : LocaleStrings.button_save}
                        </button>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </form>
          </div>
        </Dialog>
      </Transition.Root>
      {importTermsModal?.show ? <SetTermsImport /> : ""}
    </>
  );
};

const validate = (values) => {
  // console.log("error values :- ", values);
  var errors = { questions_by_questionsetidfk: [] };

  var levels = values["levels"];
  var setname = values["setname"];
  var questions_by_questionsetidfk = values["questions_by_questionsetidfk"];

  if (!levels || (levels && levels.length == 0)) {
    errors["levels"] = LocaleStrings.required;
  }
  if (!setname || setname.trim() === "") {
    errors["setname"] = LocaleStrings.required;
  }

  questions_by_questionsetidfk?.forEach((v, i) => {
    errors.questions_by_questionsetidfk.push({});
    if (!v?.question || v?.question.trim() === "") {
      errors.questions_by_questionsetidfk[i].question = LocaleStrings.required;
    }
    if ((!v?.answer || v?.answer.trim() === "") && v?.questionmode === "term") {
      errors.questions_by_questionsetidfk[i].answer = LocaleStrings.required;
    }
    if (v?.questionmode === "mcq") {
      if (!v?.option1 || v?.option1.trim() === "") {
        errors.questions_by_questionsetidfk[i].option1 = LocaleStrings.required;
      }
      if (!v?.option2 || v?.option2.trim() === "") {
        errors.questions_by_questionsetidfk[i].option2 = LocaleStrings.required;
      }
      // if (!v?.option3 || v?.option3.trim() === "") {
      //   errors.questions_by_questionsetidfk[i].option3 = LocaleStrings.required;
      // }
      // if (!v?.option4 || v?.option4.trim() === "") {
      //   errors.questions_by_questionsetidfk[i].option4 = LocaleStrings.required;
      // }

      if (!v?.answer || v?.answer.trim() === "") {
        errors.questions_by_questionsetidfk[i].questionmode =
          LocaleStrings.class_chapter_set_form_validation_correct_ans_select;
      }

      if (!v?.radio_qst || v?.radio_qst.trim() === "") {
        errors.questions_by_questionsetidfk[i].questionmode =
          LocaleStrings.class_chapter_set_form_validation_correct_ans_select;
      }
    }
  });

  // console.log("errors :- ", errors);
  return errors;
};

const mapStateToProps = (state) => {
  var init = {
    type: "program",
    privacy: "private",
    notifyclass: false,
    levels: {
      value: state?.classDetails?.data?.level,
      label: state?.classDetails?.data?.level,
    },
    ...defaultArrayData,
  };
  var toEdit = !_.isEmpty(state?.createQuestionSet?.value);

  if (toEdit) {
    _.map(
      state?.createQuestionSet?.value?.questions_by_questionsetidfk,
      (o) => {
        if (o.questionmode === "mcq") {
          o.radio_qst =
            o.answer === o.option1
              ? "option1"
              : o.answer === o.option2
              ? "option2"
              : o.answer === o.option3
              ? "option3"
              : o.answer === o.option4
              ? "option4"
              : "";
        }
      }
    );
    init = state?.createQuestionSet?.value;

    let lvl = state?.createSetModal?.value?.levels;
    let levelsArr = [];
    if (lvl && !Array.isArray(lvl)) {
      let lvlArr = lvl.split(",");
      lvlArr?.map((item) => {
        levelsArr.push({ value: item, label: item });
      });
      init.levels = levelsArr;
    }
  }

  if (state?.importedSetTerms?.importedTerms) {
    init.questions_by_questionsetidfk = state?.importedSetTerms?.data;
  }

  return {
    session: state.session,
    createQuestionSet: state.createQuestionSet,
    importTermsModal: state.importTermsModal,
    initialValues: init,
    toEdit,
    classDetails: state.classDetails,
    classChapterDetails: state.classChapterDetails,
    globalLevels: state.globalLevels,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSetModule,
      termsImportModal,
      importSetTerms,
      addQusetionSet,
      updateQusetionSet,
      deleteBulkQuestions,
      importSetModule,
      clearGlobalSetList,
      fetchMyGlobalSetList,
      notifyClassAboutSet,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "QusetionsetForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(QuestionsetCreate)
);
