import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { selectedClassTeacher } from "@myspaceaction";
import { selectedClass,fetchCourse } from "@classesaction";
import ReactPlayer from "react-player";
import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import classImg from "../../../assets/image/defaultclassimg.png";
import blankImg from "../../../assets/image/blank_classes.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoPlay from "../../cognivideos/components/video-play";
import {
  clearVideoList,
  openVideoModal,
  fetchVideoList,
  searchVideoList,
} from "@cognivideosaction";

const VideoCarousel = (props) => {
  const navigate = useNavigate();
  var { session, videoPlayModal } = props;
  const slider = React.useRef(null);
  const [videoList,SetVideoList] = useState({})
  const [Init,SetInit] = useState(false);
  
  useEffect(()=>{
      if(Init == false){
        SetInit(true);
        props.fetchVideoList(session, 0,0,function(res){
          SetVideoList(res)
          slider.current.innerSlider.list.getElementsByClassName("slick-track")[0].style.transform = "translate3d(0px, 0px, 0px)"
        });
      }

  },[videoList])

  const renderCourese = () => {
    return _.map(videoList?.data, (item, index) => {

      return (
        <div
          key={`course_key_${index}`}
          className="bg-white border shadow-lg rounded-xl cursor-pointer hover:shadow-xl"
        >
          <div className="w-full bg-theme rounded-tl-xl rounded-tr-xl">
            <div className="h-48">
              <ReactPlayer
                controls={true}
                light={true}
                volume={1}
                muted={false}
                playsinline={true}
                pip={true}
                progressInterval={10000}
                url={item.url}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="px-4 py-5">
            <div>
              <div className="text-2xl 2xs:text-3xl text-primary font-normal h-20">
                {item?.name} <br/>
                {item.level}
              </div>
              
              
            </div>
          </div>
        </div>
      );
    });
  };

  const next = () => slider.current.slickNext();
  const previous = () => slider.current.slickPrev();

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="text-2xl 2xs:text-3xl font-semibold text-primary capitalize mb-8">
        Les Vidéos Kojyto
      </div>
        <>
        {videoPlayModal?.show ? <VideoPlay /> : ""}
          <Slider {...settings} className="custom-silck" ref={slider}>
            {renderCourese()}
          </Slider>
          <div className="mt-8 text-center space-x-3">
            <button type="button" className="" onClick={previous}>
              <ChevronLeftIcon className="h-6 w-5" />
            </button>
            <button type="button" className="" onClick={next}>
              <ChevronRightIcon className="h-6 w-5" />
            </button>
          </div>
        </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classList: state.classList,
    allClassAndItsChapter: state.studentAllClassList,
    videoPlayModal: state.videoPlayModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchVideoList  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoCarousel);
