import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { PlusIcon } from "@heroicons/react/outline";
import {
  fetchClassDetails,
  fetchClassChapterDetails,
  clearClassChapterSetList,
  fetchClassChapterSetList,
  searchClassChapterSetList,
  checkChapterExamDate,
  createSetModule,
  importSetModule,
  createSetsFromMQModal,
  createExamDateModal,
  clearGlobalSetList,
  fetchMyGlobalSetList,
} from "@classesaction";
import { attemptSetQstModule } from "@questionsetsaction";
import { classNames } from "@basecomponent";
import { itemCount, LOCAL_TO_GMT_TIME, BASE_IMAGES_URL } from "@constant";
import ContentHeader from "@contentheader";
import Pagination from "@pagination";
import Loader from "@loader";
import LocaleStrings from "@language";
import ItemList from "./item-list";
import QuestionsetCreate from "@qusetionsetadd";
import QuestionsetImport from "@qusetionsetimport";
import ChapterExamCreate from "@chapternextexamadd";
import QuestionsetAttempt from "@questionsetattempt";
import MqSetCreate from "../mq-set-add";
import blankImg from "../../../../../../assets/image/blank_classes.svg";
import chapterImg from "../../../../../../assets/image/defaultclassimg.png";
import CopyQuestions from "./copy-questions";

const ChapterDetails = (props) => {
  const { classid, chapterid } = useParams();
  
  var {
    session,
    classDetails,
    classChapterDetails,
    classChapterSets,
    createChapterNextExam,
    attemptSetModal,
    createSetFromMQModal,
  } = props;

  //createChapterNextExam.show = true;
  var [pageNumber, setPageNumber] = useState(1);
  var [search, setSearch] = useState("");
  var [copyOpen, setCopyOpen] = useState(false);
  var [copySetId, setCopySetId] = useState(0);
  // console.log("classid :- ", classid);
  // console.log("chapterid :- ", chapterid);
  useEffect(() => {
    props.createSetModule({ show: false });
    props.importSetModule({ show: false });
    props.createExamDateModal({ show: false });
    props.attemptSetQstModule({ show: false });

    props.fetchClassDetails(session, classid);
    props.fetchClassChapterDetails(session, chapterid);
    props.clearClassChapterSetList();
    props.fetchClassChapterSetList(session, {
      classid: classid,
      chapterid: chapterid,
    });
    if (session?.usertype === "student") {
      props.checkChapterExamDate(session, {
        userid: session?.userid,
        classid: classid,
        chapterid: chapterid,
        today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
      });
    }
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);

    if (obj.key === "create_set") {
      // console.log("Create Set");
      props.createSetModule({ ...obj });
    } else if (obj.key === "import_set") {
      // console.log("Import Set");
      props.clearGlobalSetList();
      props.importSetModule({ ...obj });
      props.fetchMyGlobalSetList(session);
    } else if (obj?.key === "create_set_for_mq") {
      props.createSetsFromMQModal({
        ...obj,
        values: classChapterDetails?.data,
      });
    }
  };

  const handleCopyQuestions = (e,setid) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(setid)
    setCopySetId(setid);
    setCopyOpen(true);
  };

  const copyQuestionsClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCopyOpen(false);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);

    // we are calling chapter details api for generate new quiz dynamic name in mq set create modal
    if (classDetails?.data?.createdby === 1) {
      props.fetchClassChapterDetails(session, chapterid);
    }
  };

  const additionChapterNextExamCallback = () => {
    props.checkChapterExamDate(session, {
      userid: session?.userid,
      classid: classid,
      chapterid: chapterid,
      today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
    });
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      props.clearClassChapterSetList();
      props.fetchClassChapterSetList(session, {
        classid: classid,
        chapterid: chapterid,
      });
    } else {
      props.searchClassChapterSetList(
        session,
        {
          classid: classid,
          chapterid: chapterid,
        },
        search,
        pageNum,
        (response) => {}
      );
    }

    setSearch(search);
    setPageNumber(pageNum);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchClassChapterSetList(
        session,
        {
          classid: classid,
          chapterid: chapterid,
        },
        pageNum
      );
    } else {
      props.searchClassChapterSetList(
        session,
        {
          classid: classid,
          chapterid: chapterid,
        },
        search,
        pageNum
      );
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return classChapterSets?.data.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          classid={classid}
          chapterid={chapterid}
          listitem={item}
          bgWhite={index % 2 === 0}
          pageDataLength={classChapterSets?.data?.length}
          currentPage={pageNumber}
          pagination={paginationCallback}
          handleCopyQuestions={handleCopyQuestions}
        />
      );
    });
  };

  var headerButtons = [];
  if (
    classDetails?.data?.createdby === session?.userid ||
    classDetails?.data?.setquestion
  ) {
    headerButtons = [
      {
        key: "create_set",
        name:
          classDetails?.data?.createdby === 1
            ? LocaleStrings.class_chapter_details_button_new_own_set
            : LocaleStrings.class_chapter_details_button_new_set,
        class: "theme-button-md",
        icon: PlusIcon,
      },
    ];

    // Hide for now as per client - 08-09-2022
    // if (classDetails?.data?.createdby !== 1) {
    //   headerButtons.unshift({
    //     key: "import_set",
    //     name: LocaleStrings.class_chapter_details_button_import,
    //     class: "default-button-md",
    //     icon: "",
    //   });
    // }

    if (
      classDetails?.data?.createdby === 1 &&
      session?.usertype === "student"
    ) {
      headerButtons.unshift({
        key: "create_set_for_mq",
        name: LocaleStrings.class_chapter_text_new_set,
        class: "green-button-md",
        icon: "",
      });
    }
  }

  var chapterImage = classChapterDetails?.data?.chaptericon
    ? `${BASE_IMAGES_URL}/${classChapterDetails?.data?.chaptericon}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
    : classDetails?.data?.image
    ? `${BASE_IMAGES_URL}/${classDetails?.data?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
    : chapterImg;
  // console.log("classDetails :- ", classDetails);
  // console.log("classChapterDetails :- ", classChapterDetails);
  // console.log("classChapterSets :- ", classChapterSets);

  return (
    <>
      <CopyQuestions isOpened={copyOpen} setIsOpened={setCopyOpen} setid={copySetId} />
      <ContentHeader
        customData={{
          section: session?.usertype === "student" ? "myspace" : "classes",
          subsection: "classdetails/classchapterdetails",
          breadcrumbs:
            session?.usertype === "student"
              ? [
                  {
                    key: "myspace",
                    text: LocaleStrings.myspace,
                    href: "/myspace",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.classname ?? "---",
                    href: `/myspace/${classDetails?.data?.classid}`,
                  },
                  {
                    key: "classchapterdetails",
                    text: classChapterDetails?.data?.chaptername ?? "---",
                  },
                ]
              : [
                  {
                    key: "classes",
                    text: LocaleStrings.classes,
                    href: "/classes",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.classname ?? "---",
                    href: `/classes/${classDetails?.data?.classid}`,
                  },
                  {
                    key: "classchapterdetails",
                    text: classChapterDetails?.data?.chaptername ?? "---",
                  },
                ],
          button: headerButtons,
          
        }}
        datainfo={classDetails?.data ?? {}}
        handleAction={handleActionButton}
      />

      {classChapterSets?.data ? (
        <>
          <div className="content-body">
            <div className={session?.usertype === 'student' ? "hidden 2xs:block w-full h-36rem bg-theme rounded-xl": "hidden 2xs:block w-1/2 h-[24rem] bg-theme rounded-xl mx-auto"} >
              <img
                className="object-cover w-full h-full rounded-lg"
                src={chapterImage}
              />
            </div>

            {/* Search section */}
            {search !== "" || classChapterSets?.count > 0 ? (
              <div className={session?.usertype === 'student' ? "relative mb-4 2xs:mb-24": "relative mb-4 2xs:mb-24 translate-x-2/4 w-2/4"}>
                <div className={session?.usertype === 'student' ? "2xs:px-7 2xs:absolute 2xs:-top-7 w-full": "2xs:absolute 2xs:-top-7 w-[100%] mx-auto"}>
                  <div className="w-full">
                    <div className="w-full">
                      <div className="relative w-full">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                          <svg
                            className="h-6 w-6 2xs:h-8	2xs:w-8"
                            viewBox="0 0 32 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                              stroke="#1A242D"
                              strokeWidth="1.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                              stroke="#1A242D"
                              strokeWidth="1.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="search-input-field"
                          placeholder={LocaleStrings.search}
                          type="search"
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {classChapterSets?.count > 0 ? (
              <>
                <div className="flex flex-col">
                  {/* <div className="overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                      <div className="overflow-hidden border border-defaultColor sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {
                                  LocaleStrings.class_chapter_details_th_set_title
                                }
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {
                                  LocaleStrings.class_chapter_details_th_createdby
                                }
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.class_chapter_details_th_mq_set}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.class_chapter_details_th_terms}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {LocaleStrings.class_chapter_details_th_privacy}
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{renderItems()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
                  >
                    {renderItems()}
                  </ul>
                  {/* Pagination */}
                  {classChapterSets?.count > itemCount ? (
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={itemCount}
                      totalItemsCount={classChapterSets?.count}
                      onChange={paginationCallback}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div
                className={classNames(
                  classChapterSets?.count > 0 ? "" : "h-5/6",
                  "custom-item-center"
                )}
              >
                <div className="text-center">
                  <img className="inline" src={blankImg} />

                  <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                    {search === "" ? (
                      <>
                        {LocaleStrings.class_chapter_details_set_not_available}
                      </>
                    ) : (
                      <>
                        {
                          LocaleStrings.class_chapter_details_set_search_not_available
                        }
                        {" : "}
                        <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                          {search}
                        </span>
                      </>
                    )}
                  </div>

                  {search === "" ? (
                    <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                      {LocaleStrings.class_chapter_details_set_not_subtitle}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>

          <QuestionsetCreate finishCallback={additionCallback} />
          <QuestionsetImport />
          {createChapterNextExam?.show ? (
            <ChapterExamCreate
              finishCallback={additionChapterNextExamCallback}
            />
          ) : (
            ""
          )}
          {attemptSetModal?.show ? <QuestionsetAttempt /> : ""}

          {createSetFromMQModal?.show ? (
            <MqSetCreate finishCallback={additionCallback} source="sets" />
          ) : (
            ""
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classDetails: state.classDetails,
    classChapterDetails: state.classChapterDetails,
    classChapterSets: state.classChapterSets,
    createChapterNextExam: state.createChapterNextExam,
    attemptSetModal: state.attemptSetModal,
    createSetFromMQModal: state.createSetFromMQModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClassDetails,
      fetchClassChapterDetails,
      clearClassChapterSetList,
      fetchClassChapterSetList,
      searchClassChapterSetList,
      checkChapterExamDate,
      createSetModule,
      importSetModule,
      createSetsFromMQModal,
      createExamDateModal,
      clearGlobalSetList,
      fetchMyGlobalSetList,
      attemptSetQstModule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChapterDetails);
