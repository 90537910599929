import _ from "lodash";
import { INSTANCE_URL, itemCount } from "@constant";
import { getRequest, patchRequest, postRequest } from "@network";

export function PostExamData(session, classId, chapterId, date, callback) {
    var url = `${INSTANCE_URL}api/v2/addexamdate`;
    var body = {
        classid: classId,
        chapterid:chapterId,
        date:date
    }
    return (dispatch) => {
        postRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);
              
              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
    };
  }


  export function FetchExamData(session, callback) {
    var filter = encodeURI(`userid=${session?.userid}`);
    var url = `${INSTANCE_URL}api/v2/fetchexamdate?${filter}`;
    return (dispatch) => {
        getRequest(
            url,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);
                
              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
    };
  }