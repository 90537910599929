/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import {
  createSetsFromMQModal,
  addSetFromMQSet,
  selectedClassChapter,
  fetchSingleSet,
} from "@classesaction";
import {
  attempteSet,
  attemptSetQstModule,
  fetchAttemptedSetQstList,
} from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import { renderFieldText, classNames } from "@basecomponent";
import { convertDateIntoLocal, CUSTOM_DATE_TIME_FORMAT } from "@constant";
import LocaleStrings from "@language";

const MqSetCreate = (props) => {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  var {
    handleSubmit,
    reset,
    invalid,
    submitting,
    session,
    createSetFromMQModal,
    source = "",
  } = props;
  const [loading, setLoading] = useState(false);
  const [firstQuizLaunch, setFirstQuizLaunch] = useState(false);
  const [loadingFirstQuizAction, setLodaingFirstQuizAction] = useState({
    load: false,
    type: "",
  });
  const [quizeInfo, setQuizInfo] = useState({});

  const close = () => {
    props.createSetsFromMQModal({ show: false });
    reset();

    setFirstQuizLaunch(false);
    setLodaingFirstQuizAction({ load: false, type: "" });
    setQuizInfo({});
  };

  const close1 = () => {};

  const onSubmitForm = (values) => {
    values.chapterid = createSetFromMQModal?.values?.chapterid;
    values.userid = session?.userid;

    // console.log("values :- ", values);
    // console.log("createSetFromMQModal :- ", createSetFromMQModal);

    setLoading(true);
    props.addSetFromMQSet(session, values, (res) => {
      // console.log("res :- ", res);
      setLoading(false);

      if (res.success === 1) {
        toast.success(LocaleStrings.class_chapter_set_form_add_success);

        if (source === "chapters") {
          if (createSetFromMQModal?.values?.totalquestionsets > 0) {
            setTimeout(() => {
              navigate(`${createSetFromMQModal?.values?.chapterid}`);
              props.selectedClassChapter(createSetFromMQModal?.values);
              close();
            }, 1000);
          } else {
            setQuizInfo({
              setid: res?.data?.resource?.[0]?.setid,
            });
            setFirstQuizLaunch(true);
          }
        } else if (source === "sets") {
          close();
          props.finishCallback();
        } else {
          close();
        }
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  const attemptSet = (e, type) => {
    // This function will be only call from first time if 0 set in any chapter.
    e.preventDefault();
    e.stopPropagation();
    // console.log("type : ", type);

    setLodaingFirstQuizAction({ load: true, type: type });
    props.fetchSingleSet(
      session,
      {
        setid: quizeInfo?.setid,
        related:
          "questions_by_questionsetidfk,users_by_createdby,programchapters_by_chapteridfk&users_by_createdby.fields=username&programchapters_by_chapteridfk.fields=chaptername",
      },
      (response) => {
        // console.log("response :- ", response);

        // call finishCallback props to refresh the chapter list for next time use "only for chapter source"
        props?.finishCallback();

        var values = {
          useridfk: session?.userid,
          classidfk: response?.data?.[0]?.classidfk,
          chapteridfk: response?.data?.[0]?.chapteridfk,
          qestionsetidfk: quizeInfo?.setid,
          totalquestions: response?.data?.[0]?.totalquestions ?? null,
          mode: type,
        };
        // console.log("values :- ", values);

        props.attempteSet(session, values, (res) => {
          if (res?.success === 1) {
            var aval = {
              useridfk: session?.userid,
              classidfk: response?.data?.[0]?.classidfk,
              chapteridfk: response?.data?.[0]?.chapteridfk,
              questionsetidfk: quizeInfo?.setid,
              activitytype:
                type === "assessment"
                  ? "quiz_start"
                  : type === "practice" &&
                    response?.data?.[0]?.privacy === "private"
                  ? "quiz_practice_private"
                  : "quiz_practice",
              relatedid: res?.data?.resource?.[0]?.id,
            };
            props.createUserActivity(session, aval, (res) => {});
            props.attemptSetQstModule({
              show: true,
              mode: type,
              id: res?.data?.resource?.[0]?.id,
              value: response?.data?.[0],
            });
            if (type === "assessment") {
              props.fetchAttemptedSetQstList(
                session,
                res?.data?.resource?.[0]?.id
              );
            }
            close();
          }
        });
      }
    );
  };

  return (
    <Transition.Root
      show={createSetFromMQModal?.show ? true : false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close1}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                    {LocaleStrings.class_chapter_mq_set_add_form_title}
                  </Dialog.Title>
                  <p className="text-xl 2xs:text-2xl font-normal text-secondary">
                    {createSetFromMQModal?.values?.chaptername}
                  </p>
                </div>

                {firstQuizLaunch === false ? (
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="base-form mt-3 sm:mt-5">
                      <Field
                        name="setname"
                        label={LocaleStrings.class_chapter_set_form_label_title}
                        placeholder={
                          LocaleStrings.class_chapter_set_form_ph_title
                        }
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                        maxlength={50}
                      />
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="submit"
                        className="theme-button-md w-full focus:outline-none sm:col-start-2"
                        disabled={invalid || submitting || loading}
                      >
                        <svg
                          className={classNames(
                            loading ? "" : "hidden",
                            "cust-btn-spinner"
                          )}
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="btnloading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                        {LocaleStrings.button_create}
                      </button>
                      <button
                        type="button"
                        className="default-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                        onClick={() => close()}
                        ref={cancelButtonRef}
                      >
                        {LocaleStrings.button_cancel}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="theme-button-md w-full focus:outline-none sm:col-start-2"
                      onClick={
                        loadingFirstQuizAction?.load
                          ? null
                          : (e) => attemptSet(e, "practice")
                      }
                      disabled={loadingFirstQuizAction?.load}
                    >
                      <svg
                        className={classNames(
                          loadingFirstQuizAction?.load &&
                            loadingFirstQuizAction?.type === "practice"
                            ? ""
                            : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_practice}
                    </button>
                    <button
                      type="button"
                      className="theme-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                      onClick={
                        loadingFirstQuizAction?.load
                          ? null
                          : (e) => attemptSet(e, "assessment")
                      }
                      disabled={loadingFirstQuizAction?.load}
                    >
                      <svg
                        className={classNames(
                          loadingFirstQuizAction?.load &&
                            loadingFirstQuizAction?.type === "assessment"
                            ? ""
                            : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_assessment}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const validate = (values) => {
  var errors = {};
  var setname = values["setname"];

  if (!setname || setname.trim() === "") {
    errors["setname"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var init = {};

  if (state?.createSetFromMQModal?.values) {
    var currentDateTime = convertDateIntoLocal(new Date()).format(
      CUSTOM_DATE_TIME_FORMAT
    );

    init.setname = `${
      state?.createSetFromMQModal?.values?.chaptername ?? "Chapter"
    } - Quiz ${
      state?.createSetFromMQModal?.values?.totalquestionsets + 1 ?? 1
    } (${currentDateTime})`;
  }

  return {
    session: state.session,
    createSetFromMQModal: state.createSetFromMQModal,
    initialValues: init,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSetsFromMQModal,
      addSetFromMQSet,
      selectedClassChapter,
      fetchSingleSet,
      attempteSet,
      attemptSetQstModule,
      fetchAttemptedSetQstList,
      createUserActivity,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "MqSetForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(MqSetCreate)
);
