/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { showUpcomingInvoiceModal, updateSubscription } from "@settingsaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ChangePlanInfoModal = (props) => {
  const cancelButtonRef = useRef(null);
  var { session, changePlanModal } = props;
  const [loading, setLoading] = useState(false);

  const close = () => {
    props.showUpcomingInvoiceModal({ show: false });
  };

  const close1 = () => {};

  const handleSubscriptionChange = () => {
    // console.log("changePlanModal -> ", changePlanModal);

    var values = {
      res: "sub",
      subId: changePlanModal?.values?.data?.subscription,
      plan: changePlanModal?.plan,
      proration_date:
        changePlanModal?.values?.data?.subscription_proration_date,
    };
    // console.log("values :- ", values);
    setLoading(true);
    props.updateSubscription(session, values, (response) => {
      // console.log("response :- ", response);
      setLoading(false);
      if (response?.success) {
        toast.success(LocaleStrings.subscription_changed_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  return (
    <Transition.Root show={changePlanModal?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close1}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full sm:p-6">
              <div>
                <div className="flex items-start justify-between">
                  <Dialog.Title className="text-lg font-medium text-primary">
                    {LocaleStrings.sets_add_form_title}
                  </Dialog.Title>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      type="button"
                      className="bg-white rounded-md text-secondary hover:text-secondaryDark outline-none"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="mt-3 sm:mt-5">Body</div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="theme-button-md w-full focus:outline-none sm:col-start-2"
                    disabled={loading}
                    onClick={handleSubscriptionChange}
                  >
                    <svg
                      className={classNames(
                        loading ? "" : "hidden",
                        "cust-btn-spinner"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="btnloading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.button_save}
                  </button>
                  <button
                    type="button"
                    className="default-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                    onClick={() => close()}
                    ref={cancelButtonRef}
                  >
                    {LocaleStrings.button_cancel}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    changePlanModal: state.changePlanModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { showUpcomingInvoiceModal, updateSubscription },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePlanInfoModal);
