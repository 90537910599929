import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import {
  createChapterModule,
  deleteClassChapter,
  selectedClassChapter,
  createSetsFromMQModal,
} from "@classesaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  const navigate = useNavigate();
  let {
    session,
    classDetails,
    listitem,
    bgWhite,
    currentPage,
    pageDataLength,
  } = props;

  const handleChapter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("listitem :- ", listitem);

    props.selectedClassChapter(listitem);
    navigate(`${listitem.chapterid}`);
  };

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createChapterModule({ key: "edit", show: true, value: listitem });
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_delete_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_delete_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  props.deleteClassChapter(
                    session,
                    listitem.chapterid,
                    (response) => {
                      if (response.success === 0) {
                        toast.error(LocaleStrings.common_fail_message);
                      } else {
                        onClose();
                        props.pagination(pageNo);
                        toast.success(
                          LocaleStrings.class_chapter_delete_success
                        );
                      }
                    }
                  );
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const createNewSet = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("createSetFromMQModal :- ", listitem);

    props.createSetsFromMQModal({
      key: "create_set_for_mq",
      show: true,
      values: listitem,
    });
  };

  var anyMqMatch = _.filter(listitem?.questionsets_by_chapteridfk, (o) => {
    return o?.questionsets_by_setid?.creator === "mq";
  });
  // console.log("classDetails :- ", classDetails);

  return (
    <>
      <li
        className="cursor-pointer hover:shadow-xl divide-y-1 divide-gray-200 flex flex-col rounded-xl shadow-lg"
        onClick={
          session?.usertype === "student" &&
          classDetails?.data?.createdby === 1 &&
          listitem?.totalquestionsets === 0
            ? (e) => createNewSet(e)
            : (e) => handleChapter(e)
        }
      >
        <div className="min-h-0 flex-1 flex flex-col px-5 py-4 space-y-1">
          <div className="relative flex flex-row items-start">
            <p className="flex-1 text-2xl 2xs:text-3xl text-primary font-normal">
              {listitem.chaptername}
            </p>

            {session?.usertype === "teacher" &&
            (classDetails?.data?.createdby == session?.userid ||
              session?.userid == listitem?.createdby) ? (
              <div className="ml-2">
                <Menu as="div" className="relative inline-block text-left">
                  <div className="-mr-3">
                    <Menu.Button className="flex items-center text-secondary hover:text-secondaryDark bg-white rounded-md">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={(e) => editItem(e)}
                              className={classNames(
                                active ? "bg-blue-50" : "",
                                "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-secondary"
                              )}
                            >
                              <span>{LocaleStrings.button_edit}</span>
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={(e) => deleteItem(e)}
                              className={classNames(
                                active ? "bg-blue-50" : "",
                                "flex rounded-md px-4 py-2 text-2xl 2xs:text-2xl font-normal text-customred"
                              )}
                            >
                              <span>{LocaleStrings.button_delete}</span>
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {session?.usertype === "student" &&
        classDetails?.data?.createdby === 1 ? (
          <>
            {listitem?.totalquestionsets > 0 ? (
              <div className="flex-shrink-0 grid grid-cols-2 divide-x-1 divide-gray-200">
                <div
                  className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
                  onClick={(e) => createNewSet(e)}
                >
                  {LocaleStrings.class_chapter_text_new_set}
                </div>

                <div
                  className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
                  onClick={(e) => handleChapter(e)}
                >
                  {LocaleStrings.class_chapter_text_existing_set}
                </div>
              </div>
            ) : (
              <div className="flex-shrink-0">
                <div
                  className="px-6 py-5 text-xl 2xs:text-xl font-semibold text-theme text-center cursor-pointer"
                  onClick={(e) => createNewSet(e)}
                >
                  {LocaleStrings.class_chapter_text_first_set}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </li>
      {/* <tr
        className={classNames(
          bgWhite ? "bg-white" : "bg-gray-50",
          "cursor-pointer"
        )}
        onClick={handleChapter}
      >
        <td className="tabledata whitespace-nowrap">{listitem.chaptername}</td>
        <td className="tabledata">
          {listitem?.totalquestionsets ?? "0"}
        </td>
        <td className="tabledata">
          {anyMqMatch.length > 0 ? (
            <div className="flex items-center space-x-2">
              <CheckIcon className="h-7 w-7 text-theme" aria-hidden="true" />
              <span>{anyMqMatch?.length}</span>
            </div>
          ) : (
            ""
          )}
        </td>
        {session?.usertype === "teacher" ? (
          <td className="px-6 py-4 whitespace-nowrap text-right text-xl 2xs:text-2xl font-normal space-x-3">
            <span
              className="cursor-pointer text-theme hover:text-themeDark"
              onClick={(e) => editItem(e)}
            >
              {LocaleStrings.button_edit}
            </span>
            <span
              className="cursor-pointer text-theme hover:text-themeDark"
              onClick={(e) => deleteItem(e)}
            >
              {LocaleStrings.button_delete}
            </span>
          </td>
        ) : null}
      </tr> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createChapterModule,
      deleteClassChapter,
      selectedClassChapter,
      createSetsFromMQModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
