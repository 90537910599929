import moment from "moment";
import { INSTANCE_URL } from "@constant";
import { getRequest } from "@network";

export const HISTORY_RESULT = "HISTORY_RESULT";


export function fetchHistory(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/gethistory`;

  if(data.classId !== undefined){
    url += `?classid=${data.classId}`
  }else{
    url += `?userid=${data.userId}`
  }
 
  if(data.requestBy !== undefined){
    url = `${url}&requestby=${data.requestBy}`
  }
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: HISTORY_RESULT,
          payload: { data: response?.resource },
        });
        callback({
          status: 1,
          data: response?.resource,
        });
      },
      (error) => {}
    );
  };
}

export function fetchSetReponse(session,data,callback) {
  var url = `${INSTANCE_URL}api/v2/getsetresponse?setid=${data.setid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          data: response?.resource,
        });
      },
      (error) => {}
    );
  };
}