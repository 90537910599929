import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuAlt1Icon, CogIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import { logout } from "@mainaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import HeaderMenu from "./header-menu";
import defaultuser from "../../assets/image/user_1.png";

const userNavigation = [
  { key: "settings", name: LocaleStrings.ps_text_account_settings },
  { key: "logout", name: LocaleStrings.logout },
];

const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let { routes, session, customData, datainfo } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const menuCloseRef = useRef(null);
  var user = {
    name: session?.username,
    email: session?.email,
    imageUrl: session?.image
      ? `${BASE_IMAGES_URL}/${session?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
      : defaultuser,
  };

  const open = () => {
    setSidebarOpen(true);
  };

  const close = () => {
    setSidebarOpen(false);
  };

  const handleActions = (item) => {
    if (item?.key === "logout") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-alert-body">
              <div className="confirmation-title">
                {LocaleStrings.logout_confirmation_title}
              </div>
              <div className="confirmation-subtitle">
                {LocaleStrings.logout_confirmation_text}
              </div>

              <div className="custom-confirm-alert-button-group">
                <button
                  className="confirmation-action-button"
                  onClick={onClose}
                >
                  {LocaleStrings.button_no}
                </button>
                <button
                  className="confirmation-action-button"
                  onClick={() => {
                    props.logout(session);
                    navigate("/auth/login");
                    setTimeout(() => {
                      onClose();
                    }, 500);
                  }}
                >
                  {LocaleStrings.button_yes}
                </button>
              </div>
            </div>
          );
        },
      });
    } else if (item?.key === "settings") {
      navigate("/settings");
      menuCloseRef.current?.click();
    }
  };

  const renderHeader = () => {
    if (customData?.breadcrumbs) {
      var length = customData?.breadcrumbs?.length ?? 1; // Check is array hav next element

      return (
        <div>
          <div className="custom-item-vcenter space-x-3">
            <h2 className="font-semibold text-primary text-2xl sm:text-4xl sm:pb-2 sm:truncate capitalize">
              {customData?.breadcrumbs?.[length - 1]?.customText
                ? customData?.breadcrumbs?.[length - 1]?.customText
                : customData?.breadcrumbs?.[length - 1]?.text}
            </h2>
            {customData?.image ? (
              <div className="inline-block h-12 w-12 2xs:h-12 2xs:w-12">
                <img
                  className="inline-block h-full w-full"
                  src={customData?.image}
                />
              </div>
            ) : (
              ""
            )}

          </div>
          <div className="custom-item-vcenter space-x-3">
            {customData?.breadcrumbs?.[length - 1]?.subject &&(
               <h3 className="font-semibold text-primary text-xl sm:text-2xl sm:pb-2 sm:truncate capitalize">{customData?.breadcrumbs?.[length - 1]?.subject}</h3>
            )}
            
          </div>
          
          <div className="custom-item-vcenter space-x-3">
            {customData?.breadcrumbs?.[length - 1]?.lastClass &&(
               <h3 className="font-semibold text-primary text-xl sm:text-2xl sm:pb-2 sm:truncate capitalize">{customData?.breadcrumbs?.[length - 1]?.lastClass}</h3>
            )}
            
          </div>
        </div>
      );
    } else {
      return (
        <div className="custom-item-vcenter">
          <h2 className="font-semibold text-primary text-2xl sm:text-5xl sm:pb-2 sm:truncate">
            {getBrandText(location.pathname)}
          </h2>
        </div>
      );
    }
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(routes[i].path) !== -1 &&
        routes[i].layout === "/admin"
      ) {
        return routes[i].name;
      }
    }
    return "";
  };

  return (
    <>
      <div className="flex">
        <button type="button" className="text-theme lg:hidden" onClick={open}>
          <span className="sr-only">Open sidebar</span>
          <MenuAlt1Icon className="h-7 w-7" aria-hidden="true" />
        </button>

        <div className="w-full flex justify-between">
          <div className="flex-1 flex">
            <div className="hidden lg:flex lg:items-center lg:justify-between lg:border-gray-200">
              {/* <div className="flex-1 min-w-0"> */}
              <>{renderHeader()}</>
              {/* </div> */}
            </div>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {/* <button
              type="button"
              className="bg-white p-1 rounded-full text-primary hover:text-gray-500"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}

            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm">
                  <CogIcon
                    className="flex-shrink-0 ml-1 h-7 w-7 text-primary hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute z-20 right-0 mt-2 w-72 2xs:w-80 rounded-xl shadow-xl bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item key={"item.key"}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "p-4 flex items-center gap-4"
                        )}
                      >
                        <img
                          className="inline-block h-20 w-20 rounded-xl border"
                          src={user.imageUrl}
                          alt=""
                        />
                        <div className="custom-text1-primary-lg-medium">
                          <div className="break-all">
                            {LocaleStrings.ps_text_signedin}
                          </div>
                          <div className="break-all">{user.email}</div>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.key}>
                      {({ active }) => (
                        <span
                          onClick={() => handleActions(item)}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex items-center gap-2 p-4 font-medium text-lg text-primary cursor-pointer"
                          )}
                        >
                          {item.key === "logout" ? (
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5102 24.1258H16.3694C11.5594 24.1258 9.24105 22.23 8.84022 17.9833C8.79689 17.5391 9.12189 17.1383 9.57689 17.095C10.0211 17.0516 10.4219 17.3875 10.4652 17.8316C10.7794 21.2333 12.3827 22.5008 16.3802 22.5008H16.5211C20.9302 22.5008 22.4902 20.9408 22.4902 16.5316V9.46831C22.4902 5.05914 20.9302 3.49915 16.5211 3.49915H16.3802C12.3611 3.49915 10.7577 4.78831 10.4652 8.25498C10.4111 8.69914 10.0427 9.03498 9.57689 8.99165C9.12189 8.95915 8.79689 8.55831 8.82939 8.11414C9.19772 3.80248 11.5269 1.87415 16.3694 1.87415H16.5102C21.8294 1.87415 24.1044 4.14915 24.1044 9.46831V16.5316C24.1044 21.8508 21.8294 24.1258 16.5102 24.1258Z"
                                fill="#1A242D"
                              />
                              <path
                                d="M16.1198 13.8125H2.1665C1.72234 13.8125 1.354 13.4442 1.354 13C1.354 12.5558 1.72234 12.1875 2.1665 12.1875H16.1198C16.564 12.1875 16.9323 12.5558 16.9323 13C16.9323 13.4442 16.5748 13.8125 16.1198 13.8125Z"
                                fill="#1A242D"
                              />
                              <path
                                d="M13.7043 17.4417C13.4985 17.4417 13.2927 17.3658 13.1302 17.2033C12.816 16.8892 12.816 16.3692 13.1302 16.055L16.1852 13L13.1302 9.94499C12.816 9.63083 12.816 9.11083 13.1302 8.79666C13.4443 8.48249 13.9643 8.48249 14.2785 8.79666L17.9077 12.4258C18.2218 12.74 18.2218 13.26 17.9077 13.5742L14.2785 17.2033C14.116 17.3658 13.9102 17.4417 13.7043 17.4417Z"
                                fill="#1A242D"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                          {item.name}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* small device menu */}
      <HeaderMenu close={close} sidebarOpen={sidebarOpen} routes={routes} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
