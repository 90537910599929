import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import {
  clearClassMembersList,
  fetchClassMembersList,
  searchClassMembersList,
  fetchClassDetails,
} from "@classesaction";
import {
  createStudentModule,
  fetchBulkUplodedLeftUsers,
} from "@studentsaction";
import { teacherClassList, fetchAllStudentsList } from "@mainaction";
import { itemCount } from "@constant";
import Pagination from "@pagination";
import Loader from "@loader";
import StudentCreate from "@studentaddedit";
import StudentImport from "@studentimport";
import ItemList from "./item-list";
import LocaleStrings from "@language";
import blankImg from "../../../../../assets/image/blank_students.svg";

const Members = (props) => {
  const { classid } = useParams();
  var {
    search,
    session,
    classDetails,
    classMemberList,
    createStudent,
    importStudent,
    bulkUplodingLeft,
  } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var intervalUserList = null;
  var processQueue = false;

  useEffect(() => {
    props.clearClassMembersList();
    // props.fetchClassMembersList(session, classid);
    handleSearch(search);

    if (session?.usertype === "teacher" && search === "") {
      props.fetchBulkUplodedLeftUsers(session, classid);

      // console.log("called..... ", processQueue);
      intervalUserList = setInterval(() => {
        // console.log("intervalUserList :- ", intervalUserList);
        props.fetchBulkUplodedLeftUsers(session, classid);
        callFunction();

        if (bulkUplodingLeft?.count > 0) processQueue = true;
        else processQueue = false;

        if (!processQueue) {
          clearDataInterval();
        }
      }, 15000);
    }

    return () => {
      clearDataInterval();
    };
  }, [search]);

  const clearDataInterval = () => {
    clearInterval(intervalUserList);
    intervalUserList = null;
    processQueue = false;
  };

  const callFunction = () => {
    paginationCallback(pageNumber);
  };

  const callbackUploadUser = () => {
    // console.log("called 2nd out.....");
    refresh();
    /*if (!intervalUserList) {
      intervalUserList = setInterval(() => {
        // console.log("called 2nd.....");
        // console.log("this.processQueue 2nd.....", processQueue);
        props.fetchBulkUplodedLeftUsers(session, classid);
        callFunction();

        if (bulkUplodingLeft?.count > 0) processQueue = true;
        else processQueue = false;

        if (!processQueue) {
          clearDataInterval();
        }
      }, 500);
    } else {
      // console.log("called 2nd out else.....");
    }*/
  };

  const refresh = () =>{
    var search = "";
    let pageNum = 1;

    if (classMemberList?.count === 0) {
      props.clearClassMembersList();
    }

    if (search === "") {
      props.fetchClassMembersList(session, classid, pageNum);
    } else {
      props.searchClassMembersList(session, classid, search, pageNum);
    }

    setPageNumber(pageNum);
  }

  const open = () => {
    props.teacherClassList(session);
    props.fetchAllStudentsList(session);
    props.createStudentModule({ key: "create_member", show: true });
  };

  const handleSearch = (e) => {
    var search = e;
    let pageNum = 1;

    if (classMemberList?.count === 0) {
      props.clearClassMembersList();
    }

    if (search === "") {
      props.fetchClassMembersList(session, classid, pageNum);
    } else {
      props.searchClassMembersList(session, classid, search, pageNum);
    }

    setPageNumber(pageNum);
  };

  const additionCallback = () => {
    props.fetchClassDetails(session, classid);
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchClassMembersList(session, classid, pageNum);
    } else {
      props.searchClassMembersList(session, classid, search, pageNum);
    }

    setPageNumber(pageNum);
  };

  const renderItems = () => {
    return classMemberList?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          pageDataLength={classMemberList?.data?.length}
          currentPage={pageNumber}
          pagination={paginationCallback}
          classid={classid}
          refresh={refresh}
        />
      );
    });
  };
  // console.log("classMemberList :- ", classMemberList);

  return (
    <>
      <div className="pt-6 2xs:pt-10">
        {classMemberList?.data ? (
          <>
            {search !== "" || classMemberList?.count > 0 ? (
              <div className="md:flex md:items-center md:justify-between">
                <div>
                  {/* <select
                      id="sortstudent"
                      name="sortstudent"
                      className="input-field"
                      defaultValue="Option 1"
                    >
                      <option>Sort by</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                    </select> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {classMemberList?.count > 0 ? (
              <>
                <div className="flex flex-col">
                  <div className="overflow-x-auto border shadow-lg rounded-xl">
                    <div className="align-middle inline-block min-w-full">
                      <div className="overflow-hidden rounded-t-xl rounded-b-3xl">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="tableheader">
                                {LocaleStrings.class_member_th_name}
                              </th>
                              <th scope="col" className="tableheader">
                                {LocaleStrings.class_member_th_role}
                              </th>
                              {session?.usertype === "teacher" ? (
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Actions</span>
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>{renderItems()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Pagination */}
                  {classMemberList?.count > itemCount ? (
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={itemCount}
                      totalItemsCount={classMemberList?.count}
                      onChange={paginationCallback}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="custom-item-center">
                <div className="text-center">
                  <img className="inline" src={blankImg} />
                  <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                    {search === "" ? (
                      <>
                        {session?.usertype === "teacher"
                          ? LocaleStrings.class_member_not_available
                          : LocaleStrings.myspace_member_not_available}
                      </>
                    ) : (
                      <>
                        {LocaleStrings.class_member_search_not_available}
                        {" : "}
                        <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                          {search}
                        </span>
                      </>
                    )}
                  </div>

                  {search === "" ? (
                    <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                      {session?.usertype === "teacher"
                        ? LocaleStrings.class_member_not_subtitle
                        : LocaleStrings.myspace_member_not_subtitle}
                    </p>
                  ) : (
                    ""
                  )}

                  {session?.usertype === "teacher" &&
                  search === "" &&
                  (classDetails?.data?.createdby === session?.userid ||
                    classDetails?.data?.invitemember) ? (
                    <div className="mt-6">
                      <button
                        type="button"
                        className="theme-button-md"
                        onClick={open}
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Nouvel élève
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>

      {createStudent?.show ? (
        <StudentCreate
          finishCallback={additionCallback}
          source="enroll_member"
          classid={classid}
          existingmembers={
            classDetails?.data?.programmembers_by_classidfk ?? []
          }
        />
      ) : (
        ""
      )}

      {importStudent?.show ? (
        <StudentImport
          callbackUploadUser={callbackUploadUser}
          source="enroll_member"
          classid={classid}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classDetails: state.classDetails,
    classMemberList: state.classMemberList,
    createStudent: state.createStudent,
    importStudent: state.importStudent,
    bulkUplodingLeft: state.bulkMemberUplodingLeft,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearClassMembersList,
      fetchClassMembersList,
      searchClassMembersList,
      fetchClassDetails,
      createStudentModule,
      teacherClassList,
      fetchAllStudentsList,
      fetchBulkUplodedLeftUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Members);
