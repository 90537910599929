/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ReactPlayer from "react-player";
import _ from "lodash";
import {
  openVideoModal,
  clearUserVideoStatus,
  getUserVideoStatus,
  insertWathchedTime,
  updatedWathchedTime,
} from "@cognivideosaction";
import LocaleStrings from "@language";

const VideoPlay = (props) => {
  var { session, videoPlayModal, userVideoStatus } = props;
  const cancelButtonRef = useRef(null);

  const close = () => {
    props.clearUserVideoStatus();
    props.openVideoModal({ show: false });
  };

  const handleProgress = (e) => {
    // console.log("progress e :- ", parseInt(e?.playedSeconds));

    const sec = parseInt(e?.playedSeconds); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    // console.log(hours + ":" + minutes + ":" + seconds); // Return is HH : MM : SS

    var values = {
      videoidfk: videoPlayModal?.values?.id,
      useridfk: session?.userid,
      watchedduration: hours + ":" + minutes + ":" + seconds,
    };

    if (_.isEmpty(userVideoStatus)) {
      props.insertWathchedTime(session, values, (res) => {
        // console.log("res: ", res);
        props.getUserVideoStatus(session, {
          videoid: videoPlayModal?.values?.id,
        });
      });
    } else {
      // console.log("userVideoStatus :- ", userVideoStatus);
      props.getUserVideoStatus(
        session,
        {
          videoid: videoPlayModal?.values?.id,
        },
        (response) => {
          // console.log("response :- ", response);
          var str1 = userVideoStatus?.watchedduration;
          var str2 = `${hours + ":" + minutes + ":" + seconds}`;

          str1 = str1.split(":");
          str2 = str2.split(":");

          var storedtime = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
          var currentwatchedtime = parseInt(
            str2[0] * 3600 + str2[1] * 60 + str2[0]
          );
          // console.log("storedtime :- ", storedtime);
          // console.log("currentwatchedtime :- ", currentwatchedtime);

          if (currentwatchedtime > storedtime) {
            values.id = userVideoStatus.id;
            props.updatedWathchedTime(session, values, (res) => {
              // console.log("res: ", res);
            });
          }
        }
      );
    }
  };

  return (
    <Transition.Root show={videoPlayModal?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="flex items-start justify-between">
                  <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                    {videoPlayModal?.values?.name ?? ""}
                  </Dialog.Title>
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="outline-none"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon
                        className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-3 sm:mt-5 h-56 sm:h-96">
                <ReactPlayer
                  controls={true}
                  light={true}
                  volume={1}
                  muted={true}
                  playsinline={true}
                  pip={true}
                  progressInterval={10000}
                  url={videoPlayModal?.values?.url}
                  width="100%"
                  height="100%"
                  onProgress={handleProgress}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    videoPlayModal: state.videoPlayModal,
    userVideoStatus: state.userVideoStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openVideoModal,
      clearUserVideoStatus,
      getUserVideoStatus,
      insertWathchedTime,
      updatedWathchedTime,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlay);
