import { INSTANCE_URL } from "@constant";
import { getRequest } from "@network";

export const STATS_RESULT = "STATS_RESULT";
export const STATS_RANKING = "STATS_RANKING"



export function fetchStats(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/getstats?time=${data.time}`;

  if(data.start){
    url = `${url}&start=${data.start}&end=${data.end}`;
  }
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: STATS_RESULT,
          payload: { data: response?.resource },
        });
        callback({
          status: 1,
          data: response,
        });
      },
      (error) => {}
    );
  };
}

export function setRanking(data){
  return (dispatch) => {
    dispatch({
      type:STATS_RANKING,
      payload:data
    })
  }
}