import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CheckIcon } from "@heroicons/react/outline";
import Pagination from "@pagination";
import { fetchTeacherQuizInvitation } from "@dashboardaction";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, DISPLAY_BROWSER_DATE_FORMAT } from "@constant";
import LocaleStrings from "@language";
import QuizInvitationModal from "@quizinvitationmodal";
import blankImg from "../../../assets/image/blank_quiz_invitation.svg";
import { useNavigate } from "react-router-dom";
import ResultData from "../../history/components/resultData.js";
import {fetchSetReponse} from "../../history/actions/index.js"

const TeacherQuizInvitation = (props) => {
  var {
    perPageList,
    session,
    teacherQuizInvitationList,
    dashboardInviteQuizModal,
  } = props;
  var [pageNumber, setPageNumber] = useState(1);
  var [showResult, setShowResult] = useState(false);
  var [reponseData, setReponseData] = useState([]);
  const navigate = useNavigate();

  const additionCallbackQuinzInvite = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    props.fetchTeacherQuizInvitation(session, pageNum, perPageList);

    setPageNumber(pageNum);
  };

  const calculResult = (attemptedData,attemptedQuestions) => {
    let maxQuestion = attemptedData?.totalquestions;
    let result = 0;
    for(const v of attemptedQuestions){
      if(v?.answer === "correct"){
        result++;
      }
    }
    return `${result} / ${maxQuestion}`;
  }

  const openResult = (setId) => {
    props.fetchSetReponse(session, {setid:setId}, (data) =>{
      setReponseData(data.data);
      setShowResult(true);
    })
   
  }

  const closeResult = () => {
    setShowResult(false);
  }

  const renderQuizinvitation = () => {
    return _.map(teacherQuizInvitationList?.data, (item, index) => {
      return (
        <tr key={`quiz_key_${index}`} onClick={() => openResult(item.attemptedsetsidfk)}>
          <td className="tabledata whitespace-nowrap">
            {item?.questionsets_by_questionsetidfk?.setname}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {item?.classidfk ? (
              <div>
                <span className="text-xl 2xs:text-xl font-normal text-primary mr-2">
                  {item?.classprograms_by_classidfk?.classname}
                </span>
                <span className="flex-shrink-0 inline-block px-3 py-0.5 text-theme text-lg 2xs:text-lg font-normal bg-blue-100 rounded-md">
                  {item?.classprograms_by_classidfk?.programname}
                </span>
              </div>
            ) : (
              <span className="text-xl 2xs:text-3xl font-normal text-primary">
                {"-"}
              </span>
            )}
          </td>
          <td className="tabledata whitespace-nowrap">
            {item?.users_by_useridfk?.username}
          </td>
          <td className="tabledata whitespace-nowrap">
            {convertDateIntoLocal(item?.createdon).format(
              DISPLAY_BROWSER_DATE_FORMAT
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <span className="flex item-center">
              {item?.isdone ? (
                <>
                  <CheckIcon
                    className="h-5 w-5 2xs:h-6 2xs:w-6 text-theme"
                    aria-hidden="true"
                  />
                  {item.attemptedsets_by_attemptedsetsidfk !== null ? calculResult(item.attemptedsets_by_attemptedsetsidfk, item.attemptedquestion_by_attemptedsetsidfk) : ""}
                </>
              ) : (
                ""
              )}
            </span>
          </td>
          
        </tr>
      );
    });
  };

  // console.log("teacherQuizInvitationList :- ", teacherQuizInvitationList);

  return (
    <>
      { showResult && <ResultData reponseData={reponseData} closeHandle={closeResult} />}
      
      <div className="text-2xl sm:text-3xl font-semibold text-primary capitalize mb-8">
        {LocaleStrings.dashboard_text_quiz_invitation}
      </div>
      {teacherQuizInvitationList?.count > 0 ? (
        <div className="flex flex-col">
          <div className="align-middle inline-block min-w-full">
            <div className="border shadow-lg rounded-xl overflow-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="border-b-2">
                  <tr>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_quiz_name}
                    </th>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_program}
                    </th>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_student}
                    </th>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_invited_on}
                    </th>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_quiz_taken}
                    </th>
                  </tr>
                </thead>
                <tbody>{renderQuizinvitation()}</tbody>
              </table>
            </div>
          </div>
          {/* Pagination */}
          {teacherQuizInvitationList?.count > perPageList ? (
            <Pagination
              activePage={pageNumber}
              itemsCountPerPage={perPageList}
              totalItemsCount={teacherQuizInvitationList?.count}
              onChange={paginationCallback}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="custom-item-center max-w-full mx-auto border shadow-lg rounded-xl h-60 md:h-26rem p-4">
          <div className="text-center">
            <img className="inline" src={blankImg} />
            <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
              Grace a Kojyto, envoyez des quizzs à vos élèves et recevez leur résultats !
              <br />
                <button className="text-theme hover:text-themeDark" onClick={() => navigate("/classes?myclass=true")} >Mes classes</button>

            </div>
          </div>
        </div>
      )}

      {dashboardInviteQuizModal?.show ? (
        <QuizInvitationModal finishCallback={additionCallbackQuinzInvite} />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherQuizInvitationList: state.teacherQuizInvitationList,
    dashboardInviteQuizModal: state.dashboardInviteQuizModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchTeacherQuizInvitation,fetchSetReponse }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherQuizInvitation);
