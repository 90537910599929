/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState , useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import Papa from "papaparse";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";
import { importStudentModule, importStudents } from "@studentsaction";
import Pagination from "@pagination";
import ItemList from "./student-cell";
import LocaleStrings from "@language";
import { Field, reduxForm, change  } from "redux-form";
import {
  renderHiddenFieldText,
  renderSelect,
  renderFieldText,
  renderDatalistSelect,
  renderFieldTextarea,
  renderFieldCheckbox,
  classNames,
  validateEmail
} from "@basecomponent";
import { teacherClassList} from "@mainaction";

const StudentImport = (props) => {
  const itemCount = 8;
  const cancelButtonRef = useRef(null);
  const accept =
    "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  var { 
    session,
    importStudent,
    source = "",
    classid = null,
    pristine,
    invalid,
    submitting
  } = props;
  var [loading, setLoading] = useState(false);
  var [pageNumber, setPageNumber] = useState(1);
  var [importedusers, setImportedusers] = useState([]);
  var [processing, setProcessing] = useState(false);
  var [hasDataError, setHasDataError] = useState(false);
  var [paginatedData, setPaginatedData] = useState({
    page: 0,
    per_page: 0,
    total: 0,
    total_pages: 0,
    data: [],
  });
  const [classes, setClasses] = useState([]);
  const [classSelected, setClassSelected] = useState("")

  const close = () => {
    props.importStudentModule({ show: false });
    retry();
  };

  const retry = () => {
    setPageNumber(false);
    setHasDataError(false);
    setLoading(false);
    setImportedusers([]);
  };

  const handleSelectChange = (event, newValue, previousValue, name) => {
    setClassSelected(newValue)
  }

  useEffect(() => {
    const temp = [];
    props.teacherClassList(session, (res)=>{
      res?.data?.map((item) => {
          temp.push({
            value: item?.classid,
            text: `${item?.classname} - ${item?.programname}`,
          });
      });
      setClasses(temp);
    });

    return () => {

    };
  }, []);

  const importUser = () => {
    var classid = classSelected;
    let finalData = ""
    importedusers.forEach((item) => {
      finalData += item.email + ",";
    });
    finalData = finalData.replace(/\s/g,'')
    setLoading(true);
    props.importStudents(session, {email:finalData,classidfk:classid}, (response) => {
      setLoading(false);
      if (response.success == 1) {
        toast.success(LocaleStrings.student_import_success);
        //props.refresh()
        close();
        props.callbackUploadUser();
      } else {
        toast.error(LocaleStrings.student_import_error);
      }
    });
  };

  const downloadTemplate = (type) => {
    let sample = [
      {
        Email: "firstname1@mq.com",
      },
      {
        Email: "firstname2@mq.com",
      },
      {
        Email: "firstname3@mq.com",
      },
    ];

    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "sheet";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "sample.csv");
    } else {
      XLSX.writeFile(wb, "sample.xlsx");
    }
  };

  const onDropFile = (files) => {
    let file = files[0];
    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        skipEmptyLines: true,
        delimiter:",",
        complete: function (results, file) {
          if (results.errors.length === 0) {
            processCSV(results.data);
          } else {
            toast.error(LocaleStrings.csv_file_not_parsed);
          }
        }.bind(this),
        error: function (error, reason) {
          toast.error(LocaleStrings.csv_file_not_parsed);
        },
      });
    } else {
      //debugger;
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        /* Update state */
        //this.setState({ data: data, cols: make_cols(ws['!ref']) });

        if (data.length === 0) {
          toast.error(LocaleStrings.csv_file_not_parsed);
        } else {
          processXLS(data);
        }
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  };

  const processXLS = (result) => {
    processCSV(result);
  };

  const processCSV = (result) => {
    var header = result[0];
    // console.log("Result = ", result);
    console.log(header.length)
    if (header && header.length >= 1) {
      var emailheader = header[0].toString().trim();

    
      if (

        emailheader === "Email"

      ) {
        var newArray = _.drop(result, 1);
        var modifiedArr = [];
        // console.log("newArray :- ", newArray);

        if (newArray.length > 0) {
          setProcessing(true);

          newArray.map((user, id) => {
            // console.log("user :- ", user);

            if (user.length >= 1) {
              var email = user?.[0] ? user[0].toString().trim() : "";
              modifiedArr.push({
                email: email,
                rowno: id + 2,
              });
            }
          });
          // console.log("modifiedArr :- ", modifiedArr);

          // check only email as unique
          var hasduplicates =
            _.uniqBy(modifiedArr, "email").length !== modifiedArr.length;
          var groupped = _.groupBy(modifiedArr, function (n) {
            return n.email;
          }); // Grouping array by eamils
          var duplicatesArr = _.uniq(
            _.flatten(
              _.filter(groupped, function (n) {
                return n.length > 1;
              })
            )
          ); // Getting the duplicates values from groupped array whose length is > 1
          var uniqueDuplicateEmailsData = _.uniqBy(duplicatesArr, "email"); // Getting unique array from duplicates array
          let duplcateEmails = uniqueDuplicateEmailsData.map((a) => a.email); // Getting the duplicates emails
          var ErrorDataArr = [];
          modifiedArr.map((obj, id) => {
            var user = JSON.parse(JSON.stringify(obj));
            var errors = [];
            var date = moment(user?.dob, "YYYY-MM-DD", true);

            
            if (user.email == "") {
              errors.push(
                `${LocaleStrings.student_import_th_email} ${LocaleStrings.required}`
              );
            } else if (user.email && !validateEmail(user.email)) {
              errors.push(LocaleStrings.user_validation_invalid_email);
            }
            if (duplcateEmails.indexOf(user.email.trim()) >= 0) {
              errors.push(LocaleStrings.student_import_validation3);
            }
            

            if (errors.length > 0) {
              // storing errors
              user.hasErrors = errors;
              ErrorDataArr.push(user);
            }
          });
          // console.log("ErrorDataArr :- ", ErrorDataArr);

          var pageNumber = 1;
          var dataArray = ErrorDataArr.length > 0 ? ErrorDataArr : modifiedArr;
          var getData = getPaginatedItems(dataArray, pageNumber);
          // console.log("getData :- ", getData);

          setHasDataError(ErrorDataArr.length > 0);
          setImportedusers(dataArray);
          setPageNumber(pageNumber);
          setPaginatedData(getData);
        } else {
          toast.error(LocaleStrings.student_import_validation2);
        }
      } else {
        toast.error(LocaleStrings.student_import_validation1);
      }
    } else {
      toast.error(LocaleStrings.student_import_validation1);
    }
  };

  const paginationCallback = (pageNum) => {
    if (pageNumber !== pageNum) {
      var getData = getPaginatedItems(importedusers, pageNum);

      setPageNumber(pageNum);
      setPaginatedData(getData);
    }
  };

  const getPaginatedItems = (items, page) => {
    var page = page || 1,
      per_page = itemCount,
      offset = (page - 1) * per_page,
      paginatedItems = _.drop(items, offset).slice(0, per_page);

    return {
      page: page,
      per_page: per_page,
      total: items.length,
      total_pages: Math.ceil(items.length / per_page),
      data: paginatedItems,
    };
  };

  const renderItems = () => {
    return paginatedData?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
        />
      );
    });
  };

  return (
    <Transition.Root show={importStudent?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="flex items-start justify-between">
                <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                  {source === "enroll_member"
                    ? LocaleStrings.class_member_import_title
                    : LocaleStrings.student_import_title}
                </Dialog.Title>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="outline-none"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon
                      className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>

              <div className="mt-3 sm:mt-5">
                {processing ? (
                  <>
                    <div className="flex flex-col">
                      <div className="overflow-x-auto">
                        <div className="align-middle inline-block min-w-full">
                          <div className="overflow-hidden border border-defaultColor sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="tableheader">
                                    {LocaleStrings.student_import_th_rowno}
                                  </th>
                                  
                                  <th scope="col" className="tableheader">
                                    {LocaleStrings.student_import_th_email}
                                  </th>
                                  
                                </tr>
                              </thead>
                              <tbody>{renderItems()}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Pagination */}
                      <Field
                          name="classidfk"
                          label="classe"
                          placeholder="Selectionner une classe"
                          component={renderSelect}
                          mandatory="true"
                          opts={classes}
                          onChange={handleSelectChange}
                      />
                      {importedusers?.length > itemCount ? (
                        <>
                          <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={itemCount}
                            totalItemsCount={importedusers?.length}
                            onChange={paginationCallback}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="relative">
                      <Dropzone
                        onDrop={onDropFile}
                        accept={accept}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className={`cursor-pointer`}>
                            <input {...getInputProps()} />
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <div className="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-inputbox border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                  <SortAscendingIcon
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="flex custom-text1-primary-normal">
                                    <p>
                                      <span className="text-theme">
                                        Click here
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                  </div>
                                  <p className="custom-text1-secondary-xs-normal">
                                    To upload completed template
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className="mt-4 sm:mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                      <div className="text-base 2xs:text-xl font-normal text-primary capitalize">
                        {LocaleStrings.student_import_text_doenload_template}
                      </div>
                      {/* <div className=""></div> */}
                      <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                        <div
                          className="custom-item-vcenter text-base 2xs:text-xl font-normal text-theme gap-1 cursor-pointer"
                          onClick={() => downloadTemplate("csv")}
                        >
                          <span className="border-b-1 border-theme">
                            {LocaleStrings.student_import_text_sample_csv}
                          </span>
                        </div>
                        <div
                          className="custom-item-vcenter text-base 2xs:text-xl font-normal text-theme gap-1 cursor-pointer"
                          onClick={() => downloadTemplate("xls")}
                        >
                          <span className="border-b-1 border-theme">
                            {LocaleStrings.student_import_text_sample_xls}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-5">
                      <div className="text-base 2xs:text-xl font-normal text-theme">
                        {LocaleStrings.notes}
                      </div>
                      <ol className="text-sm 2xs:text-base font-normal text-secondary space-y-1">
                        <li>- {LocaleStrings.student_import_note1}</li>
                        <li>- {LocaleStrings.student_import_note2}</li>
                        <li>- {LocaleStrings.student_import_note3}</li>
                      </ol>
                    </div>
                  </div>
                )}

                <div className="mt-5 sm:mt-6 space-x-3 text-right">
                  {!hasDataError && processing ? (
                    <button
                      className="theme-button-md focus:outline-none "
                      onClick={importUser}
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_import}
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="default-button-md mt-3 focus:outline-none sm:mt-0 "
                    onClick={() => close()}
                    ref={cancelButtonRef}
                  >
                    {LocaleStrings.button_close}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    importStudent: state.importStudent,
  };
};

const validate = (values, ownProps) => {
  var errors = {};
  var classe = values["classidfk"];

  if (!classe || classe.trim() === "") {
    errors["classidfk"] = LocaleStrings.required;
  }

  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ importStudentModule, importStudents, teacherClassList }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "StudentImport",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(StudentImport)
);
