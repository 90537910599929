import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import routes from "../routes";

const Auth = (props) => {
  var { isLoggedIn, session } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && session?.username) {
      navigate(session?.userid === 1 ? "/classes" : "/dashboard" + location?.search);
    } else if (isLoggedIn && !session?.username) {
      navigate("/auth/signupuser" + location?.search);
    } else if (
      !isLoggedIn &&
      location.pathname != "/auth/signup" &&
      location.pathname != "/auth/resetpassword" &&
      location.pathname != "/auth/login"
    ) {
      navigate("/auth/login" + location?.search);
    }
  }, [isLoggedIn, session]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return <Route path={prop.path} element={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <div className="h-screen overflow-auto">
      <Routes>{getRoutes(routes)}</Routes>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn, session: state.session };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
