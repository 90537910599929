import randomstring from "randomstring";
import _ from "lodash";
import { INSTANCE_URL, BASE_IMAGES_URL, itemCount } from "@constant";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  deleteBulkRequest,
  uploadEncodedFile,
} from "@network";

export const CLASS_LIST = "CLASS_LIST";
export const MEMBER_ALL_CLASS_LIST = "MEMBER_ALL_CLASS_LIST";
export const USER_WISE_PROGRAM_LIST = "USER_WISE_PROGRAM_LIST";
export const CREATE_CLASS = "CREATE_CLASS";
export const CLASS_DETAILS = "CLASS_DETAILS";
export const CLASS_CHAPTER_LIST = "CLASS_CHAPTER_LIST";
export const CREATE_CLASS_CHAPTER = "CREATE_CLASS_CHAPTER";
export const CLASS_ALL_SETS_LIST = "CLASS_ALL_SETS_LIST";
export const CLASS_ALL_MEMBER_LIST = "CLASS_ALL_MEMBER_LIST";
export const CLASS_MEMBER_LIST = "CLASS_MEMBER_LIST";
export const CLASS_ALL_CHAPTER_LIST = "CLASS_ALL_CHAPTER_LIST";
export const CLASS_CHAPTER_DETAILS = "CLASS_CHAPTER_DETAILS";
export const CREATE_SET_FROM_MQ_MODAL = "CREATE_SET_FROM_MQ_MODAL";
export const CLASS_CHAPTER_SET_LIST = "CLASS_CHAPTER_SET_LIST";
export const CHAPTER_NEXT_EXAM = "CHAPTER_NEXT_EXAM";
export const CREATE_CHAPTER_NEXT_EXAM = "CREATE_CHAPTER_NEXT_EXAM";
export const CREATE_QUESTIONSET = "CREATE_QUESTIONSET";
export const IMPORT_QUESTIONSET = "IMPORT_QUESTIONSET";
export const QUESTION_SET_DETAILS = "QUESTION_SET_DETAILS";
export const GLOBAL_QUESTIONSET_LIST = "GLOBAL_QUESTIONSET_LIST";
export const CLASS_PROGRESS_CLASS_SNAP_DATA = "CLASS_PROGRESS_CLASS_SNAP_DATA";
export const CLASS_PROGRESS_CLASS_ACTIVITY = "CLASS_PROGRESS_CLASS_ACTIVITY";
export const CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA =
  "CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA";
export const CLASS_PROGRESS_CHAPTER_PROGRESS_DATA =
  "CLASS_PROGRESS_CHAPTER_PROGRESS_DATA";
export const CLASS_PRGRESS_SET_LIST = "CLASS_PRGRESS_SET_LIST";
export const CLASS_PROGRESS_CLASS_SET_DATA = "CLASS_PROGRESS_CLASS_SET_DATA";
export const TEACHER_COURSE = "TEACHER_COURSE";

export function clearClassList() {
  return (dispatch) => {
    dispatch({
      type: CLASS_LIST,
      payload: {},
    });
  };
}


export function fetchCourse(
  session,
  search,
  data,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchcourse?include_count=true&useridfk=${
    data?.userid
  }&questionsets=${data?.questionsets ?? false}&classmembers=${
    data?.classmembers ?? false
  }&classchapters=${data?.classchapters ?? false}&${offSetQuery}`;

  if(search !== ""){
    url = url + `&search=${search}`;
  }

  if(data.sort && data.sort !== ""){
    url = url + `&sort=${data.sort}`;
  }

  if(data.myclass !== undefined){
    url = url + `&myclass=${data.myclass}`;
  }

  if(data.subject !== undefined){
    url = url + `&myclass=${data.subject}`;
  }
  
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEACHER_COURSE,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearCourse() {
  return (dispatch) => {
    dispatch({
      type: TEACHER_COURSE,
      payload: {},
    });
  };
}


export function fetchClassList(
  session,
  data = {},
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  // var perPage = itemCount;
  // if (countPerPage > 0) perPage = countPerPage;

  // var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  // var offSetQuery = `limit=${perPage}&offset=${offset}`;
  // var order = `order=createdon%20DESC`;
  // var filter = encodeURI(`(createdby=${session?.userid})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk,programmembers_by_classidfk,users_invited_by_classidfk&users_invited_by_classidfk.fields=isadded&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  // return (dispatch) => {
  //   getRequest(
  //     url,
  //     session,
  //     dispatch,
  //     (response) => {
  //       dispatch({
  //         type: CLASS_LIST,
  //         payload: { data: response.resource, count: response.meta.count },
  //       });
  //       callback({
  //         status: 1,
  //         data: response.resource,
  //         count: response.meta.count,
  //       });
  //     },
  //     (error) => {}
  //   );
  // };

  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&useridfk=${
    data?.userid
  }&questionsets=${data?.questionsets ?? false}&classmembers=${
    data?.classmembers ?? false
  }&classchapters=${data?.classchapters ?? false}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function sortClassList(
  session,
  sort = "",
  data = {},
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  // var perPage = itemCount;
  // if (countPerPage > 0) perPage = countPerPage;
  // var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  // var offSetQuery = `limit=${perPage}&offset=${offset}`;
  // var order = `order=createdon%20DESC`;
  // var filter = encodeURI(`(createdby=${session?.userid})AND(level=${sort})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk,programmembers_by_classidfk,users_invited_by_classidfk&users_invited_by_classidfk.fields=isadded&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;
  // return (dispatch) => {
  //   getRequest(
  //     url,
  //     session,
  //     dispatch,
  //     (response) => {
  //       dispatch({
  //         type: CLASS_LIST,
  //         payload: { data: response.resource, count: response.meta.count },
  //       });
  //       callback({
  //         status: 1,
  //         data: response.resource,
  //         count: response.meta.count,
  //       });
  //     },
  //     (error) => {}
  //   );
  // };

  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchcourse?include_count=true&sort=${sort}&useridfk=${
    data?.userid
  }&classmembers=${data?.classmembers ?? false}&classchapters=${
    data?.classchapters ?? false
  }&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchClassList(
  session,
  search = "",
  data = {},
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  // var perPage = itemCount;
  // if (countPerPage > 0) perPage = countPerPage;
  // var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  // var offSetQuery = `limit=${perPage}&offset=${offset}`;
  // var order = `order=createdon%20DESC`;
  // let searchQuery = `(classname like %${search}%)OR(programname like %${search}%)`;
  // var filter = encodeURI(`(createdby=${session?.userid})AND(${searchQuery})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk,programmembers_by_classidfk,users_invited_by_classidfk&users_invited_by_classidfk.fields=isadded&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;
  // return (dispatch) => {
  //   getRequest(
  //     url,
  //     session,
  //     dispatch,
  //     (response) => {
  //       dispatch({
  //         type: CLASS_LIST,
  //         payload: { data: response.resource, count: response.meta.count },
  //       });
  //       callback({
  //         status: 1,
  //         data: response.resource,
  //         count: response.meta.count,
  //       });
  //     },
  //     (error) => {}
  //   );
  // };

  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&search=${search}&useridfk=${
    data?.userid
  }&classmembers=${data?.classmembers ?? false}&classchapters=${
    data?.classchapters ?? false
  }&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchMemberAllClass(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/fetchmemberclass?include_count=true&useridfk=${
    data?.userid
  }&questionsets=${data?.questionsets ?? false}&classmembers=${
    data?.classmembers ?? false
  }&classchapters=${data?.classchapters ?? false}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: MEMBER_ALL_CLASS_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchUserProgramsList(session, callback) {
  var filter = encodeURI(`createdby=1`);
  var order = `order=programname%20ASC`;
  var group = `group=programname`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?fields=classid,programname&include_count=true&filter=(${filter})&${group}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_WISE_PROGRAM_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function createClassModule(obj) {
  return { type: CREATE_CLASS, payload: obj };
}

export function addClass(session, values, callback) {
  var image = values?.image ?? "";
  values.image = null;

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms`;
  var body = { resource: [{ ...values, createdby: session?.userid }] };
  // console.log("body post :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        var filename = `classes/${
          response?.resource?.[0]?.classid
        }/${randomstring.generate()}.jpg`;
        _uploadImage(session, image, filename, dispatch, (image) => {
          // console.log("image :- ", image);

          if (image.success == 1) {
            var body = {
              resource: [
                {
                  classid: response?.resource?.[0]?.classid,
                  image: image?.data?.path ?? null,
                },
              ],
            };
            // console.log("body patch:- ", body);

            patchRequest(
              url,
              body,
              session,
              dispatch,
              (response) => {
                callback({ success: 1, data: response });
              },
              (error) => {
                callback({ success: 0, message: error });
              }
            );
          } else {
            callback({ success: 3, message: image?.message });
          }
        });
      },
      (error) => {
        // console.log("error :- ", error);
        if (error?.code == 1000) {
          callback({ success: 2, message: error });
        } else {
          callback({ success: 0, message: error });
        }
      }
    );
  };
}

export function updateClass(session, values, callback) {
  var image = values?.image ?? "";

  if (image === "removed") {
    values.image = "";
    image = "";
  }
  var filename = `classes/${values?.classid}/${randomstring.generate()}.jpg`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms`;

  return (dispatch) => {
    _uploadImage(session, image, filename, dispatch, (image) => {
      // console.log("image :- ", image);

      if (image.success == 1) {
        values = {
          ...values,
          ...(image?.data?.path
            ? {
                image: image?.data?.path,
              }
            : {}),
        };
        var body = { resource: [{ ...values }] };
        // console.log("body :- ", body);

        patchRequest(
          url,
          body,
          session,
          dispatch,
          (response) => {
            callback({ success: 1, data: response });
          },
          (error) => {
            // console.log("error :- ", error);
            if (error?.code == 1000) {
              callback({ success: 2, message: error });
            } else {
              callback({ success: 0, message: error });
            }
          }
        );
      } else {
        callback({ success: 3, message: image?.message });
      }
    });
  };
}

export function selectedClass(obj) {
  return (dispatch) => {
    dispatch({
      type: CLASS_DETAILS,
      payload: { data: {} },
    });
    dispatch({
      type: CLASS_DETAILS,
      payload: { data: obj },
    });
  };
}

export function fetchClassDetails(session, classid, callback) {
  var filter = encodeURI(`(classid=${classid})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?related=programchapters_by_classidfk,programmembers_by_classidfk&filter=(${filter})`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/classprograms?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_DETAILS,
          payload: { data: response.resource[0] },
        });
        callback({
          status: 1,
          data: response.resource[0],
        });
      },
      (error) => {}
    );
  };
}

export function clearClassChapterList() {
  return (dispatch) => {
    dispatch({
      type: CLASS_CHAPTER_LIST,
      payload: {},
    });
  };
}

export function fetchClassChapterList(
  session,
  classid = null,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(classidfk=${classid})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?related=questionsets_by_chapteridfk&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_CHAPTER_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchClassChapterList(
  session,
  classid = null,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(chaptername like %${search}%)`;
  var filter = encodeURI(`(classidfk=${classid})AND(${searchQuery})`);
  // var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?related=questionsets_by_chapteridfk&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_CHAPTER_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function createChapterModule(obj) {
  return { type: CREATE_CLASS_CHAPTER, payload: obj };
}

export function addClassChapter(session, values, callback) {
  var image = values?.chaptericon ?? "";

  if (image === "removed") {
    values.chaptericon = "";
    image = "";
  }
  var filename = `classes/${
    values.classidfk
  }/chapter/${randomstring.generate()}.jpg`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters`;

  return (dispatch) => {
    _uploadImage(session, image, filename, dispatch, (image) => {
      // console.log("image :- ", image);

      if (image.success == 1) {
        values = {
          ...values,
          ...(image?.data?.path
            ? {
                chaptericon: image?.data?.path,
              }
            : {}),
        };
        var body = { resource: [{ ...values }] };
        // console.log("body :- ", body);

        postRequest(
          url,
          body,
          session,
          dispatch,
          (response) => {
            callback({ success: 1, data: response });
          },
          (error) => {
            if (error?.code === 1000) {
              callback({ success: 2, message: error });
            } else {
              callback({ success: 0, message: error });
            }
          }
        );
      } else {
        callback({ success: 2, message: image?.message });
      }
    });
  };
}

export function updateClassChapter(session, values, callback) {
  var image = values?.chaptericon ?? "";

  if (image === "removed") {
    values.chaptericon = "";
    image = "";
  }
  var filename = `classes/${
    values.classidfk
  }/chapter/${randomstring.generate()}.jpg`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters`;

  return (dispatch) => {
    _uploadImage(session, image, filename, dispatch, (image) => {
      // console.log("image :- ", image);

      if (image.success == 1) {
        values = {
          ...values,
          ...(image?.data?.path
            ? {
                chaptericon: image?.data?.path,
              }
            : {}),
        };
        var body = { resource: [{ ...values }] };
        // console.log("body :- ", body);

        patchRequest(
          url,
          body,
          session,
          dispatch,
          (response) => {
            callback({ success: 1, data: response });
          },
          (error) => {
            if (error?.code === 1000) {
              callback({ success: 2, message: error });
            } else {
              callback({ success: 0, message: error });
            }
          }
        );
      } else {
        callback({ success: 2, message: image?.message });
      }
    });
  };
}

export function deleteClassChapter(session, chapterid, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters/${chapterid}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function _uploadImage(session, imagedata, filename, dispatch, callback) {
  if (imagedata.search(";base64,") > 0) {
    imagedata = imagedata.replace(/^data:image\/[a-z]+;base64,/, "");
    var imageJson = {
      resource: [
        {
          name: filename,
          type: "file",
          is_base64: true,
          content: imagedata,
        },
      ],
    };

    uploadEncodedFile(
      `${BASE_IMAGES_URL}?check_exist=false`,
      session,
      imageJson,
      dispatch,
      (data) => {
        if (data?.error) {
          callback({ success: 0, message: data?.error?.message });
        } else {
          callback({ success: 1, data: data });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  } else {
    callback({ success: 1 });
  }
}

export function clearClassAllSets() {
  return (dispatch) => {
    dispatch({
      type: CLASS_ALL_SETS_LIST,
      payload: {},
    });
  };
}

export function fetchClassAllSets(session, classid = null, callback) {
  var filter = encodeURI(`classidfk=${classid}`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?include_count=true&related=classprograms_by_classidfk&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_ALL_SETS_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchSingleSet(session, data, callback) {
  var filter = encodeURI(`(setid=${data?.setid})`);
  var related = data?.related ?? "";
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=${related}&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          success: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassAllMembers() {
  return (dispatch) => {
    dispatch({
      type: CLASS_ALL_MEMBER_LIST,
      payload: {},
    });
  };
}

export function fetchClassAllMembers(session, classid = null, callback) {
  var url = `${INSTANCE_URL}api/v2/fetchclassmember?include_count=true&classidfk=${classid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_ALL_MEMBER_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassMembersList() {
  return (dispatch) => {
    dispatch({
      type: CLASS_MEMBER_LIST,
      payload: {},
    });
  };
}

export function fetchClassMembersList(
  session,
  classid = null,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchclassmember?include_count=true&classidfk=${classid}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_MEMBER_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchClassMembersList(
  session,
  classid = null,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var url = `${INSTANCE_URL}api/v2/fetchclassmember?include_count=true&classidfk=${classid}&search=${search}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_MEMBER_LIST,
          payload: { data: response.resource, count: response.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.count,
        });
      },
      (error) => {}
    );
  };
}

export function enrollClassMember(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programmembers`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        callback({ success: 1, response: data });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function emailToEnrollClassMember(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/userenrollemail`;
  var body = values;

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        callback({ success: 1, response: data });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateClassMember(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programmembers`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function removeClassMember(session, memberid, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programmembers/${memberid}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function unlinkClassMemberSetsWithClass(
  session,
  values,
  filters,
  callback
) {
  var filter = encodeURI(
    `(classidfk=${filters?.classid})AND(createdby=${filters?.memberid})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?filter=(${filter})`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function clearClassAllChapters() {
  return (dispatch) => {
    dispatch({
      type: CLASS_ALL_CHAPTER_LIST,
      payload: {},
    });
  };
}

export function fetchClassAllChapters(session, classid, callback) {
  var filter = encodeURI(`(classidfk=${classid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_ALL_CHAPTER_LIST,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function selectedClassChapter(obj) {
  return (dispatch) => {
    dispatch({
      type: CLASS_CHAPTER_DETAILS,
      payload: { data: {} },
    });
    dispatch({
      type: CLASS_CHAPTER_DETAILS,
      payload: { data: obj },
    });
  };
}

export function fetchClassChapterDetails(session, chapterid, callback) {
  var filter = encodeURI(`(chapterid=${chapterid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/programchapters?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_CHAPTER_DETAILS,
          payload: { data: response.resource[0] },
        });
        callback({
          status: 1,
          data: response.resource[0],
        });
      },
      (error) => {}
    );
  };
}

export function createSetsFromMQModal(obj) {
  return { type: CREATE_SET_FROM_MQ_MODAL, payload: obj };
}

export function addSetFromMQSet(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/generatequestionset`;
  var body = { ...values };
  // console.log("body post :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function clearClassChapterSetList() {
  return (dispatch) => {
    dispatch({
      type: CLASS_CHAPTER_SET_LIST,
      payload: {},
    });
  };
}

export function fetchClassChapterSetList(
  session,
  data = {},
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;

  var filter1 = `(classidfk=${data?.classid})AND(chapteridfk=${data?.chapterid})`;
  if (session?.usertype === "student") {
    filter1 = `${filter1}AND((creator=teacher)OR(createdby=${session?.userid}))`;
  } else if(session?.usertype === "teacher" && session?.userid !== 1){
    filter1 = `${filter1}AND((creator=teacher)OR(creator=mq))`;
  } else if (session?.userid === 1) {
    filter1 = `${filter1}AND((creator=mq)OR(createdby=${session?.userid}))`;
  }
  var filter = encodeURI(`${filter1}`);

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?include_count=true&related=questions_by_questionsetidfk,users_by_createdby,programchapters_by_chapteridfk&users_by_createdby.fields=username&programchapters_by_chapteridfk.fields=chaptername&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_CHAPTER_SET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchClassChapterSetList(
  session,
  data = {},
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(setname like %${search}%)`;

  var filter1 = `(classidfk=${data?.classid})AND(chapteridfk=${data?.chapterid})AND(${searchQuery})`;
  if (session?.usertype === "student") {
    filter1 = `${filter1}AND((creator=teacher)OR(createdby=${session?.userid}))`;
  }
  var filter = encodeURI(`${filter1}`);

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?include_count=true&related=questions_by_questionsetidfk,users_by_createdby,programchapters_by_chapteridfk&users_by_createdby.fields=username&programchapters_by_chapteridfk.fields=chaptername&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_CHAPTER_SET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function checkChapterExamDate(session, data, callback) {
  var filter = encodeURI(
    `(useridfk=${data.userid})AND(classidfk=${data?.classid})AND(chapteridfk=${data?.chapterid})AND(examdate>=${data.today})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userexamdates?filter=(${filter})`;

  return (dispatch) => {
    dispatch({
      type: CHAPTER_NEXT_EXAM,
      payload: {},
    });
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: CHAPTER_NEXT_EXAM,
          payload: { data: response.resource },
        });
        callback({
          status: 1,
          data: response.resource,
        });
      },
      (error) => {}
    );
  };
}

export function createExamDateModal(obj) {
  return { type: CREATE_CHAPTER_NEXT_EXAM, payload: obj };
}

export function addChapterNextExam(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/userexamdates`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function createSetModule(obj) {
  return { type: CREATE_QUESTIONSET, payload: obj };
}

export function importSetModule(obj) {
  return { type: IMPORT_QUESTIONSET, payload: obj };
}

export function addQusetionSet(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets`;
  // console.log("post values :- ", values);

  return (dispatch) => {
    _handleMultipleImageUpload(
      session,
      values?.questions_by_questionsetidfk,
      values?.classidfk,
      dispatch,
      (image) => {
        // console.log("image :- ", image);

        if (image.success == 1) {
          values.questions_by_questionsetidfk = image.data;

          var body = { resource: [{ ...values }] };
          // console.log("body :- ", body);

          postRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);

              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function updateQusetionSet(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets`;
  // console.log("post values :- ", values);

  return (dispatch) => {
    _handleMultipleImageUpload(
      session,
      values?.questions_by_questionsetidfk,
      values?.classidfk,
      dispatch,
      (image) => {
        // console.log("image :- ", image);

        if (image.success == 1) {
          values.questions_by_questionsetidfk = image.data;

          var body = { resource: [{ ...values }] };
          // console.log("body :- ", body);

          patchRequest(
            url,
            body,
            session,
            dispatch,
            (response) => {
              // console.log("response :- ", response);

              callback({ success: 1, data: response });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 2, message: image?.message });
        }
      }
    );
  };
}

export function _handleMultipleImageUpload(
  session,
  uploadFiles,
  classid = null,
  dispatch,
  callback
) {
  var promiseArray = [];
  _.map(uploadFiles, (item, index) => {
    // console.log("question item :- ", item);
    promiseArray.push(
      new Promise((resolve, reject) => {
        var file = item?.image ? item.image : "";
        var n = file.search(";base64,");

        if (n > 0) {
          var content = file.split(";base64,")[1];
          var filename = classid
            ? `classes/${classid}/chapter/questionset/${randomstring.generate()}.jpg`
            : `users/${
                session?.userid
              }/generalsets/${randomstring.generate()}.jpg`;

          var imageJson = {
            resource: [
              {
                name: filename,
                type: "file",
                is_base64: true,
                content: content,
              },
            ],
          };
          uploadEncodedFile(
            `${BASE_IMAGES_URL}?check_exist=false`,
            session,
            imageJson,
            dispatch,
            (data) => {
              uploadFiles[index].image = data.name;
              resolve();
            },
            (error) => {
              // callback({success:2, message:error});
            }
          );
        } else if (file == "" || file == "removed") {
          uploadFiles[index].image = null;
          resolve();
        } else {
          uploadFiles[index].image = file;
          resolve();
        }
      })
    );
  });

  Promise.all(promiseArray).then(() => {
    callback({ success: 1, data: uploadFiles });
  });
}

export function deleteBulkQuestions(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questions`;
  var body = { resource: values };

  return (dispatch) => {
    deleteBulkRequest(
      url,
      session,
      body,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function selectedQuestionSet(obj) {
  return (dispatch) => {
    dispatch({
      type: QUESTION_SET_DETAILS,
      payload: { data: {} },
    });
    dispatch({
      type: QUESTION_SET_DETAILS,
      payload: { data: obj },
    });
  };
}

export function fetchSingleSetDetails(session, data = {}, callback) {
  var filter = encodeURI(`(setid=${data?.setid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,users_by_createdby&users_by_createdby.fields=username&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTION_SET_DETAILS,
          payload: { data: response?.resource?.[0] ?? {} },
        });
        callback({ status: 1, data: response?.resource?.[0] ?? {} });
      },
      (error) => {}
    );
  };
}

export function deleteQuestionSet(session, setid, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets/${setid}`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
//doit inviter les membres de la classe a passer le test ou juste notifier
export function notifyClassAboutSet(session, setid, callback) {
  var url = `${INSTANCE_URL}api/v2/notifyclassaboutset?setid=${setid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function clearGlobalSetList() {
  return (dispatch) => {
    dispatch({
      type: GLOBAL_QUESTIONSET_LIST,
      payload: {},
    });
  };
}

export function fetchMyGlobalSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(createdby=${session?.userid})AND(creator != mq)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchMyGlobalSetList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(setname like %${search}%)`;
  var filter = encodeURI(
    `(createdby=${session?.userid})AND(creator != mq)AND(${searchQuery})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchOthersGlobalSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(
    `(createdby != ${session?.userid})AND(privacy=global)AND(creator != mq)`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchOthersGlobalSetList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(setname like %${search}%)`;
  var filter = encodeURI(
    `(createdby != ${session?.userid})AND(privacy=global)AND(creator != mq)AND(${searchQuery})`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchMQGlobalSetList(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  var filter = encodeURI(`(creator=mq)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function searchMQGlobalSetList(
  session,
  search = "",
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=createdon%20DESC`;
  let searchQuery = `(setname like %${search}%)`;
  var filter = encodeURI(`(creator=mq)AND(${searchQuery})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?related=questions_by_questionsetidfk,classprograms_by_classidfk,programchapters_by_chapteridfk&classprograms_by_classidfk.fields=classname,programname&programchapters_by_chapteridfk.fields=chaptername&include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: GLOBAL_QUESTIONSET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassBasicProgress() {
  return (dispatch) => {
    dispatch({
      type: CLASS_PROGRESS_CLASS_SNAP_DATA,
      payload: {},
    });
    dispatch({
      type: CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA,
      payload: {},
    });
    dispatch({
      type: CLASS_PROGRESS_CHAPTER_PROGRESS_DATA,
      payload: {},
    });
  };
}

export function fetchClassBasicProgress(session, classid = null, callback) {
  var url = `${INSTANCE_URL}api/v2/classbasicprogress?classid=${classid}`;
  if (session?.usertype === "student") {
    url = url + `&userid=${session?.userid}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: CLASS_PROGRESS_CLASS_SNAP_DATA,
          payload: { data: response.classsnap },
        });
        dispatch({
          type: CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA,
          payload: { data: response.chapteranalysis },
        });
        dispatch({
          type: CLASS_PROGRESS_CHAPTER_PROGRESS_DATA,
          payload: { data: response.chapterprogress },
        });
        callback({
          status: 1,
          data: response,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassActivities() {
  return (dispatch) => {
    dispatch({
      type: CLASS_PROGRESS_CLASS_ACTIVITY,
      payload: {},
    });
  };
}

export function fetchClassActivities(session, data, callback) {
  var filter = encodeURI(
    `(classidfk=${data.classid})AND(createdon>=${
      data.today
    } 00:00:00)AND(createdon<=${data.today} 23:59:59)${
      session?.usertype === "student"
        ? "AND(useridfk=" + session?.userid + ")"
        : ""
    }`
  );
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/useractivities?include_count=true&related=programchapters_by_chapteridfk,questionsets_by_questionsetidfk,users_by_useridfk&programchapters_by_chapteridfk.fields=chaptername&questionsets_by_questionsetidfk.fields=setname,privacy&users_by_useridfk.fields=username&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_PROGRESS_CLASS_ACTIVITY,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassProgressSets() {
  return (dispatch) => {
    dispatch({
      type: CLASS_PRGRESS_SET_LIST,
      payload: {},
    });
  };
}

export function fetchClassProgressSets(session, data = {}, callback) {
  var filter1 = `(classidfk=${data?.classid})`;
  if (data?.chapterid) {
    filter1 = `${filter1}AND(chapteridfk=${data?.chapterid})`;
  }
  if (session?.usertype === "student") {
    filter1 = `${filter1}AND((creator=teacher)OR(createdby=${session?.userid}))`;
  }
  var filter = encodeURI(`${filter1}`);

  var order = `order=createdon%20DESC`;
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/questionsets?include_count=true&&filter=(${filter})&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CLASS_PRGRESS_SET_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function clearClassSetProgress() {
  return (dispatch) => {
    dispatch({
      type: CLASS_PROGRESS_CLASS_SET_DATA,
      payload: {},
    });
  };
}

export function fetchClassSetProgress(session, data, callback) {
  var url = `${INSTANCE_URL}api/v2/classsetprogress?classid=${data?.classid}&setid=${data?.setid}`;
  if (session?.usertype === "student") {
    url = url + `&userid=${session?.userid}`;
  }
  if (data?.startdate && data?.enddate) {
    url = url + `&startdate=${data?.startdate}&enddate=${data?.enddate}`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        dispatch({
          type: CLASS_PROGRESS_CLASS_SET_DATA,
          payload: { data: response.userdata, classdata: response.classdata },
        });

        callback({
          status: 1,
          data: response,
        });
      },
      (error) => {}
    );
  };
}

export function notifyUserAboutSet(session, data = {}, callback) {
  var url = `${INSTANCE_URL}api/v2/notifyuseraboutset?setid=${data?.setid}&userid=${data?.userid}&status=${data?.status}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}


export function getChapterForLevel(session,data,cb){
  var url = `${INSTANCE_URL}api/v2/fetchquizzlevel?level=${data.level}&matiere=${data.matiere}`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        cb({ success: 1, data: response });
      },
      (error) => {
        cb({ success: 0, message: error });
      }
    );
  };
}