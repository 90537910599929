import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { classNames } from "@basecomponent";
import logo from "../../assets/theme/logo.png";

const SideBar = (props) => {
  let { routes, session } = props;
  const location = useLocation();

  // creates the links that appear in the left menu
  const createLinks = (str) => {
    var routesNew = _.filter(routes, function (list) {
      return (
        list.display &&
        list.layout === "/admin" &&
        list.position === str &&
        (list.section === "both" || list.section === session?.usertype)
      );
    }); // custom check is login not display in sidebar

    // checkking mq teacher or student hide side menu
    var hideSideMenu = [];
    if (session?.mqstudent === true) {
      hideSideMenu = ["messages"];
    }
    if (session?.userid === 1) {
      hideSideMenu = [
        "dashboard",
        "students",
        "questionsets",
        "reports",
        "messages",
      ];
    }

    _.forEach(hideSideMenu, (obj) => {
      routesNew = _.filter(routesNew, (o) => {
        return o.key !== obj;
      });
    });
    // -------------------------------- //

    return routesNew.map((item, key) => {
      var selected = _.includes(location.pathname, item.path);
      if(item?.search && location.search !== item?.search) selected = false
      return (
        <a
          key={item.name}
          href={`#${item.path}${item?.search ? item.search : ''}`}
          className={classNames(
            selected
              ? "bg-theme text-white"
              : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300",
            "text-lg font-normal group flex items-center px-2.5 py-4 rounded-xl space-x-3"
          )}
          aria-current={selected ? "page" : undefined}
        >
          <img
            className="flex-shrink-0 h-6 w-6"
            src={selected ? item.selected : item.icon}
          />

          {/* <item.icon
            className={classNames(
              selected ? "text-white" : "text-secondary group-hover:text-white",
              "flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          /> */}

          <div >{item.name}</div>
        </a>
      );
    });
  };

  return (
    <>
      {/* Static sidebar for desktop */}

      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow bg-sidebar overflow-y-auto">
        <div className="flex items-center justify-center flex-shrink-0 px-4 py-12">
          <a href={session?.userid === 1 ? "/#/classes" : "/#/dashboard"}>
            <img className="block h-16 w-auto" src={logo} alt="Workflow" />
          </a>
        </div>
        <nav
          className="mt-3 px-7 flex-1 flex flex-col overflow-y-auto"
          aria-label="Sidebar"
        >
          <div className="space-y-2">{createLinks("main")}</div>
          {session?.userid === 1 ? (
            ""
          ) : (
            <>
              <div className="py-6 text-base font-semibold">Raccourcis</div>
              <div className="space-y-2">{createLinks("second")}</div>
            </>
          )}
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn, session: state.session };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
