import { connect } from "react-redux";
import {useEffect, useState, useCallback} from "react"
import { bindActionCreators } from "redux";

import { XIcon } from "@heroicons/react/outline";
import _ from "lodash";

const ResultData = (props) => {
    
    const { session, reponseData } = props;
    
    const close = () => {
        reponseData = []
    }
   

    useEffect( () => {

    }, []);

    return (
        <>
            {/* Result box */}
            {reponseData?.length !== 0 &&

                <div className="z-50 mx-auto sm:w-3/4 md:w-3/4 lg:w-2/4 fixed h-50 inset-0 flex items-center overflow-y-hidden">
                    <div className="xs:h-5/6 md:h-4/6 lg:h-4/6 sm:h-full w-full inset-0 bg-default shadow-2xl rounded bg-opacity-95 transition-opacity pt-20 relative overflow-y-hidden">
                        <div className="flex items-center">
                            <button
                                onClick={props.closeHandle}
                                type="button"
                                className="outline-none absolute right-0 top-0 mt-4 mr-4 focus:outline-none"
                            >
                                <span className="sr-only">Close panel</span>
                                <XIcon
                                className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                                aria-hidden="true"
                                />
                            </button>
                        </div>

                        <div className="overflow-y-scroll h-full scroll-y-auto">
                            {_.map(reponseData, (value, index) => {
                                return (
                                    value.answer == "correct" ? (
                                        <div className="m-2 mx-10">
                                            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                                                <span>
                                                    <svg viewBox="0 0 24 24" className="inline-block text-green-300 w-10 h-10 sm:w-5 sm:h-5 mr-3">
                                                        <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                                                    </svg>
                                                    {value.question}
                                                </span>
                                            </div>
                                            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                                                <ul>
                                                    <li>Réponse attendue: {value.anwser2}</li>
                                                    <li>Réponse donnée: {value.response}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    ): (
                                        <div className="m-2 mx-10">
                                            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                                                <span className="inline-block">
                                                    <svg className="inline-block bg-red-700 rounded-full text-red-300 p-1 w-10 h-10 sm:w-5 sm:h-5 mr-3" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="121.31px" height="122.876px" viewBox="0 0 121.31 122.876" enable-background="new 0 0 121.31 122.876"><g><path fill="currentColor" d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"/></g></svg>
                                                    {value.question}
                                                </span>
                                            </div>
                                            <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                                <ul>
                                                    <li>Réponse attendue: {value.anwser2}</li>
                                                    <li>Réponse donnée: {value.response}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ResultData);
  