import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import {
  fetchUserActivePlan,
  fetchLoginUserDetails,
  updateBasicUserData,
  updateUserLevel,
  cancelSubscription,
} from "@settingsaction";
import { fetchStudentAllClass } from "@studentsaction";
import { convertDateIntoLocal, DISPLAY_BROWSER_DATE_FORMAT } from "@constant";
import ContentHeader from "@contentheader";
import LocaleStrings from "@language";
import settingsimg from "../../assets/image/headerimages/settings.svg";
import planImg from "../../assets/image/setting_plan.png";
import myClassImg from "../../assets/image/setting_my_class.png";
import contactImg from "../../assets/image/setting_contact_details.png";
import passwordImg from "../../assets/image/setting_update_password.png";
import enveloppeImg from "../../assets/image/enveloppe.png";
import { MAIN_URL, SCHOOL_SUBJECT } from "@constant";

const Settings = (props) => {
  const navigate = useNavigate();
  var {
    session,
    userActivePlan,
    userDetails,
    studentAllClassList,
    globalLevels,
  } = props;
  const [subCancelLoading, setSubCancelLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [currentSubject, setCurrentSubject] = useState("");

  useEffect(() => {
    console.log("passe dans use effect");
    props.fetchUserActivePlan(session, (res) => {});
    props.fetchLoginUserDetails(session,(res) =>{
      console.log(res.data);
      if(res.data.subject !== null){
        setCurrentSubject(res.data.subject);
      }
    });

    if (session?.usertype === "student") {
      props.fetchLoginUserDetails(session,);
      //setCurrentSubject(userDetails?.data?.subject);
      console.log(userDetails)
      props.fetchStudentAllClass(session, {
        userid: session?.userid,
      });
    }
  }, []);

  const cancelPlan = () => {
    // console.log("userActivePlan ->", userActivePlan);

    var values = {
      subId: userActivePlan?.data?.[0]?.subscriptionid,
    };
    // console.log("values :- ", values);

    setSubCancelLoading(true);
    props.cancelSubscription(session, values, (response) => {
      // console.log("response :  ", response);
      setSubCancelLoading(false);
      if (response?.success) {
        toast.success(LocaleStrings.subscription_cancel_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
  
    document.body.removeChild(textArea);
  }
  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleSetting = (section) => {
    if (section === "contact") {
      navigate("/settings/profile");
    } else if (section === "password") {
      navigate("/settings/password");
    } else if (section === "plan") {
      navigate("/settings/plans");
    } else if (section === "invoice") {
      navigate("/settings/invoices");
    }
  };

  const handleClass = (e) => {
    var val = e.target.value;
    setCurrentClass(val);
    var values = { userid: userDetails?.data?.userid, classidfk: val };

    props.updateBasicUserData(session, values, (res) => {
      // console.log("res :- ", res);
      if (res.success == 1) {
        props.fetchLoginUserDetails(session, (res) => {
          setCurrentClass("");
        });
      }
    });
  };

  const handleSubject = (e) => {
    //make a while loop to get all the subjects
    const val = e.target.value;
    var values = {
      userid: userDetails?.data?.userid,
      subject: val,
    };

    props.updateBasicUserData(session, values, (res) => {
      // console.log("res :- ", res);
      if (res.success == 1) {
        props.fetchLoginUserDetails(session, (res) => {
          //setCurrentClass("");
          if(res.data.subject !== null){
            setCurrentSubject(res.data.subject);
          }
        });
      }
    });
    
  };

  const handleLevel = (e) => {
    var val = e.target.value;
    // console.log("val :- ", val);
    setCurrentLevel(val);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {
                LocaleStrings.settings_text_user_default_level_update_confirm_title
              }
            </div>
            <div className="confirmation-subtitle">
              {
                LocaleStrings.settings_text_user_default_level_update_confirm_subtext
              }
            </div>

            <div className="custom-confirm-alert-button-group">
              <button
                className="confirmation-action-button"
                onClick={() => {
                  setCurrentLevel(userDetails?.data?.level);
                  onClose();
                }}
              >
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  var values = {
                    userid: userDetails?.data?.userid,
                    level: val,
                  };
                  props.updateUserLevel(session, values, (res) => {
                    // console.log("res :- ", res);
                    onClose();
                    if (res.success == 1) {
                      props.fetchLoginUserDetails(session, (res) => {
                        setCurrentLevel("");
                      });
                    } else if (res.success == 2) {
                      setCurrentLevel(userDetails?.data?.level);
                      toast.error(LocaleStrings.common_fail_message);
                    } else {
                      setCurrentLevel(userDetails?.data?.level);
                      toast.error(LocaleStrings.common_fail_message);
                    }
                  });
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  var classes = [];
  _.map(studentAllClassList?.data, (obj) => {
    classes.push({
      value: obj?.classid,
      text: obj?.classname + " - " + obj?.programname,
    });
  });

  var levels = [];
  if (globalLevels?.data) {
    var levelData = globalLevels?.data?.value.split(",");

    levelData?.map((item) => {
      levels.push({ value: item, label: item });
    });
  }
  // console.log("userActivePlan :- ", userActivePlan);
  // console.log("userDetails :- ", userDetails);

  var selectedClass = userDetails?.data?.classidfk;
  if (currentClass !== "") {
    selectedClass = currentClass;
  }

  var selectedLevel = userDetails?.data?.level;
  if (currentLevel !== "") {
    selectedLevel = currentLevel;
  }

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "own",
          image: settingsimg,
          breadcrumbs: [{ key: "settings", text: LocaleStrings.settings }],
          button: [],
        }}
      />

      <div className="content-body h-full">
        <div className="text-2xl 2xs:text-4xl font-normal text-primary mb-8">
          {LocaleStrings.settings_text_your_plan}
        </div>

        <div className="space-y-5 2xs:space-y-6">
          <div className="py-6 px-5 2xs:py-7 2xs:px-8 bg-indigo-50 border rounded-3xl">
            <div className="sm:flex sm:items-center sm:justify-between space-y-3 md:space-y-0">
              <div className="flex-1 min-w-0">
                {userActivePlan?.data ? (
                  <>
                    {userActivePlan?.data?.length > 0 ? (
                      <div className="flex items-center space-x-3 2xs:space-x-7">
                        <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                          <img className="inline h-full w-full" src={planImg} />
                        </div>
                        <div className="space-y-1">
                          <div className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
                            {userActivePlan?.data?.[0]?.plan === "monthly_s" ||
                            userActivePlan?.data?.[0]?.plan === "monthly_t"
                              ? LocaleStrings.monthly
                              : userActivePlan?.data?.[0]?.plan ===
                                  "annualy_s" ||
                                userActivePlan?.data?.[0]?.plan === "annualy_t"
                              ? LocaleStrings.yearly
                              : LocaleStrings.trial}
                          </div>
                          <div>
                            <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                              {LocaleStrings.expires}{" "}
                              {convertDateIntoLocal(
                                userActivePlan?.data?.[0]?.planexpirydate
                              ).format(DISPLAY_BROWSER_DATE_FORMAT)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-customred text-2xl 2xs:text-3xl font-normal capitalize">
                        {LocaleStrings.subscription_not_available}
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="2xs:flex space-y-3 2xs:space-y-0 2xs:space-x-3">
                {userActivePlan?.data?.[0]?.plan !== "trial" &&
                !userActivePlan?.data?.[0]?.istrial
                  ? "" // button will come under this
                  : // <div>
                    //   <button
                    //     type="button"
                    //     className="theme-button-lg w-full 2xs:w-auto"
                    //     onClick={() => cancelPlan()}
                    //   >
                    //     {LocaleStrings.button_cancel_subscription}
                    //   </button>
                    // </div>
                    ""}
                <div>
                  <button
                    type="button"
                    className="default-button-md w-full 2xs:w-auto"
                    onClick={() => handleSetting("plan")}
                  >
                    {LocaleStrings.button_change_plan}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="theme-button-md w-full 2xs:w-auto"
                    onClick={() => handleSetting("invoice")}
                  >
                    {LocaleStrings.button_invoices}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* {session?.usertype === "student" ? (
            <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
              <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
                <div className="flex-1">
                  <div className="flex items-center space-x-3 2xs:space-x-7">
                    <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                      <img
                        className="inline-block h-full w-full"
                        src={myClassImg}
                      />
                    </div>
                    <div className="space-y-1">
                      <div className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
                        {LocaleStrings.settings_text_user_default_class}
                      </div>
                      <div>
                        <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                          {
                            LocaleStrings.settings_text_user_default_class_subtext
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:ml-4">
                  <select
                    id="classidfk"
                    name="classidfk"
                    className="input-field"
                    placeholder={
                      LocaleStrings.settings_text_user_default_class_subtext
                    }
                    value={selectedClass}
                    onChange={handleClass}
                  >
                    <option value="">
                      {LocaleStrings.settings_text_user_default_class_subtext}
                    </option>
                    {_.map(classes, (obj, index) => {
                      return (
                        <option key={`class_key_${index}`} value={obj?.value}>
                          {obj?.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}

          {session?.usertype === "student" ? (
            <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
              <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
                <div className="flex-1">
                  <div className="flex items-center space-x-3 2xs:space-x-7">
                    <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                      <img
                        className="inline-block h-full w-full"
                        src={myClassImg}
                      />
                    </div>
                    <div className="space-y-1">
                      <div className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
                        {LocaleStrings.settings_text_user_default_level}
                      </div>
                      <div>
                        <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                          {
                            LocaleStrings.settings_text_user_default_level_subtext
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:ml-4">
                  <select
                    id="level"
                    name="level"
                    className="input-field 2xs:w-56"
                    placeholder={
                      LocaleStrings.settings_text_user_default_level_subtext
                    }
                    value={selectedLevel}
                    onChange={handleLevel}
                  >
                    <option value="">
                      {LocaleStrings.settings_text_user_default_level_subtext}
                    </option>
                    {_.map(levels, (obj, index) => {
                      return (
                        <option key={`class_key_${index}`} value={obj?.value}>
                          {obj?.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
            <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
              <div className="flex-1">
                <div className="flex items-center space-x-3 2xs:space-x-7">
                  <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                    <img
                      className="inline-block h-full w-full"
                      src={contactImg}
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
                      {LocaleStrings.settings_text_contact_details}
                    </div>
                    <div>
                      <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                        {LocaleStrings.settings_text_contact_details_subtext}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:ml-4">
                <button
                  type="button"
                  className="theme-button-md"
                  onClick={() => handleSetting("contact")}
                >
                  {LocaleStrings.button_edit}
                </button>
              </div>
            </div>
          </div>

          <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
            <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
              <div className="flex-1">
                <div className="flex items-center space-x-3 2xs:space-x-7">
                  <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                    <img
                      className="inline-block h-full w-full"
                      src={passwordImg}
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="text-2xl 2xs:text-2xl font-normal text-primary capitalize">
                      {LocaleStrings.settings_text_update_password}
                    </div>
                    <div>
                      <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                        {LocaleStrings.settings_text_update_password_subtext}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:ml-4">
                <button
                  type="button"
                  className="theme-button-md"
                  onClick={() => handleSetting("password")}
                >
                  {LocaleStrings.button_update}
                </button>
              </div>
            </div>
          </div>

          <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
              <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
                <div className="flex-1">
                  <div className="flex items-center space-x-3 2xs:space-x-7">
                    <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                      <img
                        className="inline-block h-full w-full"
                        src={myClassImg}
                      />
                    </div>
                    <div className="space-y-1">
                      <div className="text-2xl 2xs:text-2xl font-semibold text-primary capitalize">
                        {"Matière enseignée"}
                      </div>
                      <div>
                        <span className="text-2xl 2xs:text-xl font-normal text-secondary capitalize">
                          Sélectionnez votre matière
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:ml-4">
                  <select
                    id="level"
                    name="level"
                    className="input-field 2xs:w-56"
                    placeholder={
                      "Sélectionnez votre matière"
                    }
                    value={currentSubject}
                    onChange={handleSubject}
                  >
                    <option value="">
                      {"Sélectionnez votre matière"}
                    </option>
                    {SCHOOL_SUBJECT.map(data => {
                      return (
                        <option key={`class_key_${data}`} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/*
          <div className="py-6 px-5 2xs:py-7 2xs:px-8 border rounded-3xl">
            <div className="2xs:flex items-center justify-between space-y-3 2xs:space-y-0">
              <div className="flex-1">
                <div className="flex items-center space-x-3 2xs:space-x-7">
                  <div className="flex-none h-16 w-16 2xs:h-18 2xs:w-18">
                    <img
                      className="inline-block h-full w-full"
                      src={enveloppeImg}
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="text-2xl 2xs:text-2xl font-normal text-primary capitalize">
                      Lien d'invitation
                    </div>
                    <div>
                      <span className="text-2xl 2xs:text-xl font-normal text-secondary">
                        {MAIN_URL + "#/auth/signup?invitedby=" + userDetails?.data?.userid}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:ml-4">
                <button
                  type="button"
                  className="theme-button-md"
                  onClick={() => copyTextToClipboard(MAIN_URL + "#/auth/signup?invitedby=" + userDetails?.data?.userid)}
                >
                  Copier
                </button>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userActivePlan: state.userActivePlan,
    userDetails: state.userDetails,
    studentAllClassList: state.studentAllClassList,
    globalLevels: state.globalLevels,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserActivePlan,
      fetchLoginUserDetails,
      updateBasicUserData,
      updateUserLevel,
      cancelSubscription,
      fetchStudentAllClass,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
