import React, { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import { bindActionCreators } from "redux";
import LocaleStrings from "@language";

const CardInfo = (props) => {

    const close = (resp = false) =>{
        props.setIsOpen(false)
        if(props?.executeCb == true){
            props.callback(resp)
        }
    }

    return (
        <>
            <Transition.Root
            show={props.isOpen}
            as={Fragment}
            onClose={close}
            >
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
            >
                <div className="relative flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                        <div className="text-center">
                            <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                                {props.title}
                            </Dialog.Title>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className="outline-none absolute right-0 top-0 mt-4 mr-4 focus:outline-none"
                                    onClick={() => close(false)}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                    className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                                    aria-hidden="true"
                                    />
                                </button>
                            </div>

                            <div className="mt-4">
                                {props.text}
                            </div>

                            <div className="flex-shrink-0 px-4 py-4 flex justify-end gap-3">
                                <button
                                    type="button"
                                    className="default-button-md"
                                    onClick={() => close(false)}
                                >
                                    Annuler
                                </button>

                                <button
                                    type="button"
                                    className="default-button-md"
                                    onClick={() => close(true)}
                                >
                                    Continuer
                                </button>
                               
                            </div>
                        
                        </div>
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
        
        </>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};
  
const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);