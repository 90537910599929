/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useParams } from "react-router-dom";
import _ from "lodash";
import toast from "react-hot-toast";
import { BASE_IMAGES_URL } from "@constant";
import { Dialog, Transition } from "@headlessui/react";
import {
  createChapterModule,
  addClassChapter,
  updateClassChapter,
} from "@classesaction";
import {
  renderFieldText,
  renderHiddenFieldText,
  classNames,
} from "@basecomponent";
import ImageCropper from "@imagecropper";
import LocaleStrings from "@language";

const ChapterCreate = (props) => {
  const { classid } = useParams();
  const cancelButtonRef = useRef(null);
  var {
    handleSubmit,
    reset,
    pristine,
    invalid,
    submitting,
    session,
    createChapter,
    initialValues,
    toEdit,
    classDetails,
  } = props;
  var [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const close = () => {
    props.createChapterModule({ show: false });
    reset();
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);

    values.classidfk = classid;

    setLoading(true);
    if (toEdit) {
      delete values["questionsets_by_chapteridfk"]; // Removing unwanted elememts which is not required to update
      props.updateClassChapter(session, values, (res) => {
        // console.log("res :- ", res);
        setLoading(false);

        if (res.success == 1) {
          toast.success(LocaleStrings.class_chapter_form_update_success);
          props.finishCallback();
          close();
        } else if (res.success == 2) {
          toast.error(LocaleStrings.class_chapter_form_duplicate_error);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      values.createdby = session?.userid;
      props.addClassChapter(session, values, (res) => {
        // console.log("res :- ", res);
        setLoading(false);

        if (res.success == 1) {
          toast.success(LocaleStrings.class_chapter_form_add_success);
          props.finishCallback();
          close();
        } else if (res.success == 2) {
          toast.error(LocaleStrings.class_chapter_form_duplicate_error);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  const onProfileImageSave = (img) => {
    // console.log("img :- ", img);
    props.autofill("chaptericon", img == "" ? "removed" : img);
  };

  const onProfileImageChange = (bool) => {};

  if (initialValues?.chaptericon)
    imagePreviewUrl = `${BASE_IMAGES_URL}/${initialValues?.chaptericon}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;

  return (
    <Transition.Root show={createChapter?.show ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                    {LocaleStrings.class_chapter_form_title}
                  </Dialog.Title>
                  <p className="text-xl 2xs:text-2xl font-normal text-secondary">
                    {classDetails?.data?.classname} -{" "}
                    {classDetails?.data?.programname}
                  </p>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="base-form mt-3 sm:mt-5">
                    <Field
                      name="chaptername"
                      label={LocaleStrings.class_chapter_form_label_chaptername}
                      placeholder={
                        LocaleStrings.class_chapter_form_ph_chaptername
                      }
                      type="text"
                      component={renderFieldText}
                      mandatory="true"
                      maxlength={50}
                    />
                    <div className="space-y-1">
                      <label className="control-label-top col-span-12">
                        {LocaleStrings.class_chapter_form_label_image}
                      </label>
                      <ImageCropper
                        displaySize={{ width: 480, height: 270 }} // For image display style
                        requiredSize={{ width: 0, height: 0 }} // For image size required validation
                        cropperSize={{ width: 480, height: 270 }} // Cropper display size. Note its add 50px for padding
                        onImageSave={onProfileImageSave}
                        onImageChange={onProfileImageChange}
                        imagepath={imagePreviewUrl}
                        displayText={true}
                        displayImgText="1920 * 1080"
                        imageType="jpg"
                        className="drop-zone-area-custom-image"
                      />
                      <Field
                        name="chaptericon"
                        type="text"
                        component={renderHiddenFieldText}
                      />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="submit"
                      className="theme-button-md w-full focus:outline-none sm:col-start-2"
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_save}
                    </button>
                    <button
                      type="button"
                      className="default-button-md mt-3 w-full focus:outline-none sm:mt-0 sm:col-start-1"
                      onClick={() => close()}
                      ref={cancelButtonRef}
                    >
                      {LocaleStrings.button_cancel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const validate = (values) => {
  var errors = {};
  var chaptername = values["chaptername"];

  if (!chaptername || chaptername.trim() === "") {
    errors["chaptername"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var init = {};
  var toEdit = !_.isEmpty(state?.createChapter?.value);

  if (toEdit) {
    init = state?.createChapter?.value;
  }

  return {
    session: state.session,
    createChapter: state.createChapter,
    initialValues: init,
    toEdit,
    classDetails: state.classDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createChapterModule, addClassChapter, updateClassChapter },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ChapterForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ChapterCreate)
);
