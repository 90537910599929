import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { bindActionCreators } from "redux";

import _ from "lodash";
import { useEffect, useState } from "react";
import { fetchStats, setRanking } from "./actions";
import { Field, reduxForm} from "redux-form";
import React from "react";

import {renderSelect,} from "@basecomponent";

//4.18

const Stats = (props) => {

  let {
    setRanking,
    ranking
  } = props;

    const listOfTime = ["day", "week", "month", "custom"]
    const [time, setTime] = useState("day")
    const [statistic, setStatistic] = useState({})
    const selectTimeValue = [];
    const ref = React.createRef();
    const startDate = new Date();
    const endDate = new Date();
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [prevDateRange,setprevDateRange] = useState([new Date(), new Date()])
    const [actualCat, setActualCat] = useState("user")
    const [refreshState,setRefreshState] = useState(1)

    const [updateNow, setUpdateNow] = useState(true)

    //const [Ranking, SetRanking] = useState([])

    const rankingShowChange = (key) =>{
      ranking[key].show = !ranking[key].show
      setRanking([...ranking])
      //useForceUpdate()
      setUpdateNow(!updateNow)
    }

    //set select time value with listOfTime
    listOfTime.map((item, index) => {
        selectTimeValue.push({value: item, text: item})
    })

    const handledateFilter = (update) => {       
        setprevDateRange(dateRange)
        setDateRange([update[0], update[1]])
    }

    function refreshRanking(){
      setRanking([
        {
          name:"classe et matière",
          show: false, 
          catName:["Niveau","Matière", "Nombre de quizz"],
          catProperties: ["level", "programname", "counter"],
          ordered:true,
          orderedBy:"counter",
          cat:"course",
          data:statistic.subjectRanking
        },
        {
          name:"utilisateur cours",
          show: false, 
          catName:["username","Niveau","total","all"],
          catProperties: ["username","level", "counter","string_result"],
          ordered:true,
          orderedBy:"total",
          cat:"course",
          data:statistic.userSubjectRankingOrdered
        }
      ])
      //this.forceUpdate()
    }

    function changeCat(catname){
      setActualCat(catname)
      setUpdateNow(!updateNow)
    }

    function fixTabSpace(open){
      if(open == false){
        return "mt-6 max-h-[32em] min-h-0"
      }else{
        return "mt-6 max-h-[32em] h-4/6 min-h-0"
      }
    }

    refreshRanking()

    useEffect(() => {
        if(time !== "custom"){
            props.fetchStats(props.session, {time}, (response) => {
                setStatistic(response.data);
            });
        }else{
            if(dateRange[0] === null || dateRange[1] === null){
                return;
            }
            let start = new Date(dateRange[0])
            start = new Date(start.setDate(dateRange[0].getDate() + 1))
            start = start.toISOString().split("T")[0]  + " 00:00:00"
            
            let end = new Date(dateRange[1])
            end = new Date(end.setDate(dateRange[1].getDate() + 1))
            end = end.toISOString().split("T")[0]  + " 23:59:00"
            props.fetchStats(props.session, {time, start , end }, (response) => {
                setStatistic(response.data);
            });
        }
        
    }, [time,dateRange]);



    //trier par count ordre decroissant
    function sortCount(){
        let sorted = _.orderBy(statistic.quizzPerUser, o => +o.count, ["desc"])
        return sorted.map((item, index) => {
            return (
                <tr key={index}>
                    <td className="tabledata">{item.email}</td>
                    <td className="tabledata">{item.username}</td>
                    <td className="tabledata">{item.count}</td>
                </tr>
            )
        })
    }

/*
    function sortSubjectRanking(element){
      if(element.ordered == true){
        let sorted = _.orderBy(element.data, o => +o["counter"], ["desc"])
        return sorted.map((item, index) => {
          <tr></tr>
          return (
            element.catProperties.map((catPropertiesName, index2) => {
              <tr key={index}>
                  <td className="tabledata">{item[catPropertiesName]}</td>
              </tr>
            })
          )
            
          
        })
      }
    }*/

    

    function renderAndSort(element,k){
      if(element.ordered == true){
        let sorted = _.orderBy(element.data, o => +o["counter"], ["desc"])
        return sorted.map((item, index) => {
          return (
              <tr key={k + "ff" + index}>
                {element.catProperties.map((catPropertiesName, index2) =>
                  <td key={k + "ff" + index2} className="tabledata">{item[catPropertiesName]}</td>
                )}
              </tr>
          )
        })
      }
    }

    function rankingShow(){
      return (
        
        ranking.map((rank,key) =>
          <>
            {actualCat === rank.cat &&
             <div className={fixTabSpace(rank.show)} key={key}>
                <div className="h-full mt-[3em]" >
                    <h2 onClick={() => rankingShowChange(key)} className="text-lg 2xs:text-4xl font-semibold text-primary">{rank.name} <span className="text-base">{rank.show == false ? "►" : "▼"}</span></h2>
                    <div className="min-h-min h-fit max-h-full overflow-y-auto border shadow-lg rounded-xl">
                      {rank.show == true &&
                        <div className="h-full align-middle inline-block min-w-full">
                            <div className="rounded-xl table-wrp block max-h-full ">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 bg-white border-b sticky top-0">
                                        <tr>
                                          {rank.catName.map((element,index) =>
                                            <th key={"th" + index} scope="col" className="tableheader">{element}</th>
                                          )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                          {renderAndSort(rank,key)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </>
        )
      )

    }


    return(
        <>
            <nav className="bg-white dark:bg-gray-900 w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <div className="mx-auto justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                  <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    <li key={1} onClick={() => changeCat("user")}>
                      <p className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Utilisateur</p>
                    </li>
                    <li key={2} onClick={() => changeCat("course")}>
                      <p className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Cours</p>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="content-body pt-8 h-screen">

                <Field
                    name="classidfk"
                    label="date"
                    placeholder="Selectionner une date"
                    component={renderSelect}
                    mandatory="true"
                    opts={selectTimeValue}
                    onChange={(e) => setTime(e.target.value)}
                />

              { actualCat == "user" &&
                <div>
                  <p>connnection {statistic.loginCount}</p>
                  <p>utilisateur créer {statistic.created}</p>
                  <p>total des quizz effectuer {statistic.totalQuizz}</p>
                </div>
              }
                <DatePicker
                    placeholderText={
                    "date"
                    }
                    className=""
                    selectsRange
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={(update) => handledateFilter(update)}
                    isClearable={true}
                    maxDate={new Date()}
                    calendarStartDay={1}
                    dateFormat={`yyyy-MM-dd`}
                    locale="fr"
                    customInput={
                    <ExampleCustomInput
                        ref={ref}
                        placeholderText={
                        "date"
                        }
                    />
                    }
                />
                { actualCat == "user" &&
                  <div className="h-fit min-h-min h-4/6 overflow-auto border shadow-lg rounded-xl">
                    <div className="h-full align-middle inline-block min-w-full">
                        <div className="rounded-xl table-wrp block max-h-full overflow-y-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50 bg-white border-b sticky top-0">
                                    <tr>
                                        <th scope="col" className="tableheader">email</th>
                                        <th scope="col" className="tableheader">nom</th>
                                        <th scope="col" className="tableheader">nombre de quizz</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {sortCount()}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                }

                {rankingShow()}

                

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        ranking: state.AllRanking
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators(
    {
        fetchStats,
        setRanking
    },
    dispatch
);

const validate = () =>{
    const errors = {};
    return errors;
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      validate,
      form: "stats",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(Stats)
  );
  

  const ExampleCustomInput = React.forwardRef(
    ({ value, placeholderText, onClick }, ref) => {
      // console.log("placeholderText :- ", placeholderText);
      // console.log("value :- ", value);
      var splitVal = value.split(" - ");
      if(splitVal?.length > 1){
       

      }
      // console.log("splitVal :- ", splitVal);
  
      return (
        <div
          className="block w-full max-w-full h-14 2xs:h-14"
          onClick={onClick}
          ref={ref}
        >
          <span className="inline-flex w-full max-w-full h-full rounded-lg shadow-lg text-lg 2xs:text-xl font-normal text-secondary placeholder-default border border-gray-200">
            {splitVal?.length > 1 ? (
              <span className="flex-1 inline-flex items-center px-6 py-2 border-r">
                {splitVal[0]} to {splitVal[1]}
              </span>
            ) : (
              <span className="flex-1 inline-flex items-center px-6 py-2 border-r">
                {placeholderText}
              </span>
            )}
  
            <button type="button" className="inline-flex items-center px-6 py-2">
              {splitVal?.length > 1 ? (
                <span> - </span>
              ) : (
                <svg
                  className="h-6 w-6 text-secondary"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"
                    fill="#292D32"
                  />
                  <path
                    d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"
                    fill="#292D32"
                  />
                  <path
                    d="M8.5 14.5001C8.37 14.5001 8.24 14.4701 8.12 14.4201C7.99 14.3701 7.89 14.3001 7.79 14.2101C7.61 14.0201 7.5 13.7701 7.5 13.5001C7.5 13.3701 7.53 13.2401 7.58 13.1201C7.63 13.0001 7.7 12.8901 7.79 12.7901C7.89 12.7001 7.99 12.6301 8.12 12.5801C8.48 12.4301 8.93 12.5101 9.21 12.7901C9.39 12.9801 9.5 13.2401 9.5 13.5001C9.5 13.5601 9.49 13.6301 9.48 13.7001C9.47 13.7601 9.45 13.8201 9.42 13.8801C9.4 13.9401 9.37 14.0001 9.33 14.0601C9.3 14.1101 9.25 14.1601 9.21 14.2101C9.02 14.3901 8.76 14.5001 8.5 14.5001Z"
                    fill="#292D32"
                  />
                  <path
                    d="M12 14.4999C11.87 14.4999 11.74 14.4699 11.62 14.4199C11.49 14.3699 11.39 14.2999 11.29 14.2099C11.11 14.0199 11 13.7699 11 13.4999C11 13.3699 11.03 13.2399 11.08 13.1199C11.13 12.9999 11.2 12.8899 11.29 12.7899C11.39 12.6999 11.49 12.6299 11.62 12.5799C11.98 12.4199 12.43 12.5099 12.71 12.7899C12.89 12.9799 13 13.2399 13 13.4999C13 13.5599 12.99 13.6299 12.98 13.6999C12.97 13.7599 12.95 13.8199 12.92 13.8799C12.9 13.9399 12.87 13.9999 12.83 14.0599C12.8 14.1099 12.75 14.1599 12.71 14.2099C12.52 14.3899 12.26 14.4999 12 14.4999Z"
                    fill="#292D32"
                  />
                  <path
                    d="M15.5 14.4999C15.37 14.4999 15.24 14.4699 15.12 14.4199C14.99 14.3699 14.89 14.2999 14.79 14.2099C14.75 14.1599 14.71 14.1099 14.67 14.0599C14.63 13.9999 14.6 13.9399 14.58 13.8799C14.55 13.8199 14.53 13.7599 14.52 13.6999C14.51 13.6299 14.5 13.5599 14.5 13.4999C14.5 13.2399 14.61 12.9799 14.79 12.7899C14.89 12.6999 14.99 12.6299 15.12 12.5799C15.49 12.4199 15.93 12.5099 16.21 12.7899C16.39 12.9799 16.5 13.2399 16.5 13.4999C16.5 13.5599 16.49 13.6299 16.48 13.6999C16.47 13.7599 16.45 13.8199 16.42 13.8799C16.4 13.9399 16.37 13.9999 16.33 14.0599C16.3 14.1099 16.25 14.1599 16.21 14.2099C16.02 14.3899 15.76 14.4999 15.5 14.4999Z"
                    fill="#292D32"
                  />
                  <path
                    d="M8.5 17.9999C8.37 17.9999 8.24 17.97 8.12 17.92C8 17.87 7.89 17.7999 7.79 17.7099C7.61 17.5199 7.5 17.2599 7.5 16.9999C7.5 16.8699 7.53 16.7399 7.58 16.6199C7.63 16.4899 7.7 16.38 7.79 16.29C8.16 15.92 8.84 15.92 9.21 16.29C9.39 16.48 9.5 16.7399 9.5 16.9999C9.5 17.2599 9.39 17.5199 9.21 17.7099C9.02 17.8899 8.76 17.9999 8.5 17.9999Z"
                    fill="#292D32"
                  />
                  <path
                    d="M12 17.9999C11.74 17.9999 11.48 17.8899 11.29 17.7099C11.11 17.5199 11 17.2599 11 16.9999C11 16.8699 11.03 16.7399 11.08 16.6199C11.13 16.4899 11.2 16.38 11.29 16.29C11.66 15.92 12.34 15.92 12.71 16.29C12.8 16.38 12.87 16.4899 12.92 16.6199C12.97 16.7399 13 16.8699 13 16.9999C13 17.2599 12.89 17.5199 12.71 17.7099C12.52 17.8899 12.26 17.9999 12 17.9999Z"
                    fill="#292D32"
                  />
                  <path
                    d="M15.5 17.9999C15.24 17.9999 14.98 17.8899 14.79 17.7099C14.7 17.6199 14.63 17.5099 14.58 17.3799C14.53 17.2599 14.5 17.1299 14.5 16.9999C14.5 16.8699 14.53 16.7399 14.58 16.6199C14.63 16.4899 14.7 16.3799 14.79 16.2899C15.02 16.0599 15.37 15.9499 15.69 16.0199C15.76 16.0299 15.82 16.0499 15.88 16.0799C15.94 16.0999 16 16.1299 16.06 16.1699C16.11 16.1999 16.16 16.2499 16.21 16.2899C16.39 16.4799 16.5 16.7399 16.5 16.9999C16.5 17.2599 16.39 17.5199 16.21 17.7099C16.02 17.8899 15.76 17.9999 15.5 17.9999Z"
                    fill="#292D32"
                  />
                  <path
                    d="M20.5 9.83984H3.5C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984Z"
                    fill="#292D32"
                  />
                  <path
                    d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
                    fill="#292D32"
                  />
                </svg>
              )}
            </button>
          </span>
        </div>
      );
    }
  );
  