import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon, CalendarIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { teacherClassList, fetchAllStudentsList } from "@mainaction";
import History from "@history";
import {
  fetchUserProgramsList,
  fetchClassDetails,
  createChapterModule,
} from "@classesaction";
import { createStudentModule, importStudentModule } from "@studentsaction";
import {
  fetchClassTeachers,
  fetchClassUpcomingExams,
  manageUpcominExam,
} from "@myspaceaction";
import {
  convertDateIntoLocal,
  LOCAL_TO_GMT_TIME,
  BASE_IMAGES_URL,
} from "@constant";
import { classNames } from "@basecomponent";
import ContentHeader from "@contentheader";
import Loader from "@loader";
import Chapters from "./chapters";
import Members from "./members";
import Progress from "./progress";
import Settings from "./settings";
import UpcomingExams from "@myspaceupcomingexams";
import LocaleStrings from "@language";
import classImg from "../../../../assets/image/defaultclassimg.png";

const ClassDetails = (props) => {
  const { classid } = useParams();
  var {
    session,
    classDetails,
    bulkUplodingLeft,
    classTeachers,
    studentUpcomingExams,
    manageUpcomingExam,
  } = props;
  var tabsArr = [
    {
      key: "chapters",
      name: LocaleStrings.class_details_tab_title1,
      current: true,
    },
  ];

  const [currentTab, setCurrentTab] = useState("chapters");
  const [tabs, setTabs] = useState(tabsArr);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.createChapterModule({ show: false });
    props.createStudentModule({ show: false });
    props.importStudentModule({ show: false });
    props.manageUpcominExam({ show: false });

    var tabsArr = [
      {
        key: "chapters",
        name: LocaleStrings.class_details_tab_title1,
        current: true,
      },
    ];

    if (session?.usertype === "student") {
      props.fetchClassDetails(session, classid, (res) => {
        // console.log("res :- ", res);

        if (res?.data?.createdby == 1) {
          tabsArr.push({
            key: "progress",
            name: LocaleStrings.class_details_tab_title3,
            current: false,
          });
        } else {
          tabsArr.push({
            key: "members",
            name: LocaleStrings.class_details_tab_title2,
            current: false,
          });
          tabsArr.push({
            key: "progress",
            name: LocaleStrings.class_details_tab_title3,
            current: false,
          });
        }
        setTabs(tabsArr);

        props.fetchClassTeachers(session, {
          classid: classid,
          creator: res?.data?.createdby,
        });
      });
      props.fetchClassUpcomingExams(session, {
        userid: session?.userid,
        classid: classid,
        today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
      });
    } else if (session?.usertype === "teacher") {
      props.fetchClassDetails(session, classid, (res) => {
        // console.log("res :- ", res);

        if (res?.data?.createdby == session?.userid && session?.userid == 1) {
          tabsArr.push({
            key: "classsettings",
            name: LocaleStrings.class_details_tab_title4,
            current: false,
          });
        } else if(res?.data?.createdby == session?.userid) {
          tabsArr.push({
            key: "members",
            name: LocaleStrings.class_details_tab_title2,
            current: false,
          });

          tabsArr.push({
            key: "results",
            name: "Résultats",
            current: false,
          });
          tabsArr.push({
            key: "progress",
            name: LocaleStrings.class_details_tab_title3,
            current: false,
          });

          tabsArr.push({
            key: "classsettings",
            name: LocaleStrings.class_details_tab_title4,
            current: false,
          });
        }
        setTabs(tabsArr);
      });
      props.fetchUserProgramsList(session);
    }
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);

    if (obj.key === "create_chapter") {
      // console.log("Create Chapter");
      props.createChapterModule({ ...obj });
    } else if (obj.key === "create_member") {
      // console.log("Create Member");
      props.teacherClassList(session);
      props.fetchAllStudentsList(session);
      props.createStudentModule({ ...obj });
    } else if (obj.key === "import_member") {
      // console.log("Invite Member");
      props.importStudentModule({ ...obj });
    } else if (obj.key === "upcoming_exam") {
      // console.log("Upcoming Exam");
      props.manageUpcominExam({ ...obj });
    }
  };

  const handleSearch = (e) => {
    var search = e.target.value;

    setSearch(search);
  };

  const handleUpcomingExams = () => {
    props.manageUpcominExam({ key: "upcoming_exam", show: true });
  };

  const handleTabs = (tab) => {
    tabs.map((obj) => {
      if (obj.key === tab.key) {
        obj.current = true;
      } else {
        obj.current = false;
      }
    });

    setCurrentTab(tab.key);
    setTabs([...tabs]);
    setSearch("");
  };

  const additionClassUpcomingExamCallback = () => {
    props.fetchClassUpcomingExams(session, {
      userid: session?.userid,
      classid: classid,
      today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
    });
  };

  var headerButtons =
    currentTab === "chapters" && classDetails?.data?.createdby === session?.userid
      ? [
          {
            key: "create_chapter",
            name: LocaleStrings.class_details_button_new_chapter,
            class: "theme-button-md",
            icon: PlusIcon,
          },
        ]
      : currentTab === "members" &&
        (classDetails?.data?.createdby === session?.userid ||
          classDetails?.data?.invitemember)
      ? [
          {
            key: "import_member",
            name:
              bulkUplodingLeft.count > 0
                ? `${bulkUplodingLeft.count} ${LocaleStrings.class_member_text_member_left}`
                : LocaleStrings.class_details_button_import_member,
            class: "default-button-md",
            icon: "",
            disabled: bulkUplodingLeft.count > 0 ? true : false,
          },
          {
            key: "create_member",
            name: "Nouvel élève",
            class: "theme-button-md",
            icon: PlusIcon,
          },
        ]
      : [];
  if (session?.usertype === "student") {
    headerButtons = [];
    // console.log("studentUpcomingExams :- ", studentUpcomingExams);
    var first4 = studentUpcomingExams?.data?.slice(0, 4);
    var exams = [];
    _.map(first4, (item) => {
      exams.push({
        section: "list",
        name: item?.programchapters_by_chapteridfk?.chaptername ?? "--",
        date: convertDateIntoLocal(item?.examdate).format("MMM DD, YYYY"),
      });
    });

    if (exams?.length > 0) {
      exams.push({
        section: "manage",
        name: LocaleStrings.myspace_upcoming_exam_text_manage_exam,
      });
    }
  }

  var classImage = classDetails?.data?.image
    ? `${BASE_IMAGES_URL}/${classDetails?.data?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
    : classImg;
  // console.log("exams :- ", exams);
  // console.log("tabs :- ", tabs);
  // console.log("classDetails :- ", classDetails);

  return (
    <>
      <ContentHeader
        customData={{
          section: session?.usertype === "student" ? "myspace" : "classes",
          subsection: "classdetails",
          breadcrumbs:
            session?.usertype === "student"
              ? [
                  {
                    key: "myspace",
                    text: LocaleStrings.myspace,
                    href: "/myspace",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.programname ?? "---",
                  },
                ]
              : [
                  {
                    key: "classes",
                    text: LocaleStrings.classes,
                    href: "/classes",
                  },
                  {
                    key: "classdetails",
                    text: classDetails?.data?.createdby == session.userid ? classDetails?.data?.classname + " : Tableau de bord de votre classe" : classDetails?.data?.classname + " - Sélectionnez votre chapitre",
                  },
                  
                  
                ],
          button: headerButtons,
          ...(session?.usertype === "student" &&
          classDetails?.data?.createdby != 1
            ? { classTeachers: classTeachers?.data }
            : {}),
        }}
        datainfo={classDetails?.data ?? {}}
        handleAction={handleActionButton}
      />

      {classDetails?.data ? (
        <div className="content-body">
          <div className={session?.usertype === 'student' ? "hidden 2xs:block w-full h-36rem bg-theme rounded-xl": "hidden 2xs:block w-1/2 h-[24rem] bg-theme rounded-xl mx-auto"}>
            
            <img
              className="object-cover w-full h-full rounded-lg"
              src={classImage}
            />
          </div>

          {/* Search section */}
          {currentTab === "chapters" || currentTab === "members" ? (
            <div className={session?.usertype === 'student' ? "relative mb-4 2xs:mb-24": "relative mb-4 2xs:mb-24 translate-x-2/4 w-2/4"}>
              <div className={session?.usertype === 'student' ? "2xs:px-7 2xs:absolute 2xs:-top-7 w-full": "2xs:absolute 2xs:-top-7 w-[100%] mx-auto"}>
                <div className="w-full">
                  <div className="w-full">
                    <div className="relative w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                        <svg
                          className="h-6 w-6 2xs:h-8	2xs:w-8"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                            stroke="#1A242D"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                            stroke="#1A242D"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="search-input-field"
                        placeholder={LocaleStrings.search}
                        type="search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>

                  {exams?.length > 0 ? (
                    <div className="flex items-center space-x-4">
                      <div className="hidden 2xs:flex items-center h-14 2xs:h-16 shadow-md bg-white border border-defaultColor rounded-lg py-2.5 px-4 2xs:px-6 text-base 2xs:text-2xl font-normal text-primary">
                        {LocaleStrings.class_details_text_upcoming_exam}
                      </div>
                      <Menu as="span" className="-ml-px relative block">
                        <Menu.Button className="flex items-center h-14 2xs:h-16 shadow-md bg-white border border-defaultColor rounded-lg py-2.5 px-4 2xs:px-6 cursor-pointer text-base 2xs:text-2xl font-normal text-primary">
                          <span className="hidden 2xs:block mr-5">
                            {moment().format("MMM DD, YYYY")}
                          </span>
                          <ChevronDownIcon
                            className="hidden 2xs:block h-6 w-6"
                            aria-hidden="true"
                          />
                          <span className="block 2xs:hidden">
                            <CalendarIcon
                              className="h-6 w-6 text-customgreen"
                              aria-hidden="true"
                            />
                          </span>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1  divide-y">
                              {exams.map((item, index) => (
                                <Menu.Item key={`list_${index}`}>
                                  {({ active }) => (
                                    <span
                                      className={classNames("block px-4 py-2 ")}
                                    >
                                      {item?.section === "list" ? (
                                        <>
                                          <div className="text-xl 2xs:text-2xl font-normal text-primary">
                                            {item.name}
                                          </div>
                                          <div className="text-xl 2xs:text-2xl font-normal text-secondary">
                                            {item.date}
                                          </div>
                                        </>
                                      ) : (
                                        <span
                                          className="flex items-center cursor-pointer text-xl 2xs:text-2xl font-normal text-secondary space-x-2"
                                          onClick={handleUpcomingExams}
                                        >
                                          <svg
                                            className="h-6 w-6"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M22 2L13.8 10.2"
                                              stroke="#292D32"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M13 6.17004V11H17.83"
                                              stroke="#292D32"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                                              stroke="#292D32"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>

                                          <span>{item.name}</span>
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-4 2xs:mb-24"></div>
          )}

          <div>
            <div className="block">
              <div className="flex space-x-3 2xs:space-x-5">
                {tabs.map((tab) =>
                  tab?.key ? (
                    <div
                      key={`tab_${tab.key}`}
                      className={classNames(
                        tab.current
                          ? "bg-white 2xs:bg-theme text-theme 2xs:text-white"
                          : "bg-white text-secondary cursor-pointer",
                        "text-base sm:text-xl font-normal py-3 px-3 2xs:px-6 border border-transparent rounded-lg shadow-md inline-flex justify-center items-center focus:outline-none"
                      )}
                      onClick={() => handleTabs(tab)}
                    >
                      {tab.name}
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </div>

          {currentTab === "chapters" ? (
            <Chapters search={search} classDetails={classDetails} />
          ) : currentTab === "members" ? (
            <Members search={search} />
          ) : currentTab === "progress" ? (
            <Progress />
          ) : currentTab === "classsettings" ? (
            <Settings />
          ) : currentTab === "results" ? (
            <History isComponent={true} classId={Number(classid)} />
          ) : (
            ""
          )}
          {manageUpcomingExam?.show ? (
            <UpcomingExams
              classDetails={classDetails}
              finishCallback={additionClassUpcomingExamCallback}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classDetails: state.classDetails,
    bulkUplodingLeft: state.bulkMemberUplodingLeft,
    classTeachers: state.classTeachers,
    studentUpcomingExams: state.studentUpcomingExams,
    manageUpcomingExam: state.manageUpcomingExam,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserProgramsList,
      fetchClassDetails,
      createChapterModule,
      teacherClassList,
      createStudentModule,
      importStudentModule,
      fetchAllStudentsList,
      fetchClassTeachers,
      fetchClassUpcomingExams,
      manageUpcominExam,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetails);
