import { connect } from "react-redux";
import {useEffect, useState, useCallback} from "react"
import { bindActionCreators } from "redux";
import ContentHeader from "@contentheader";
import classesimg from "../../assets/image/headerimages/classes.svg";
import { PlusIcon } from "@heroicons/react/outline";
import {FetchExamData} from './actions/index.js'
import AddExamDate from "./components/addExamDate.js";
import DayCell from "./components/dayScheduleCell"

const TeacherExamDate = (props) => {
    //console.log(props.session)
    var { session } = props;
    const cellNumber = 15;
    const cells =[];
    const [page, setPage] = useState(0);
    var [addExamDateOpen, setAddExamDateOpen] = useState(false);
    var [allExamData,setExamDate] = useState([]);
    const AddDateBtn = (obj) => {
        //props.FetchExamData(session,)
        setAddExamDateOpen(true);
        
    }

    
    /*const createCells = ( pageNumber =0) => {
        cells.length = 0;
        var dte = new Date();
        dte.setDate(dte.getDate() + pageNumber*cellNumber);
        for (let i=0; i<cellNumber; i++){
            var t = false
            if(i == 0){
                t = true
            }
            var cellDate = new Date(dte);
            cells.push(<DayCell 
                info={{
                date: cellDate,
                test:t
            }}
        />)
          dte.setDate(dte.getDate() + 1);
        }
    }*/

    function pageUp(){
        setPage(page+1);
        //createCells(page);
    }

    function pageDown(){
        setPage(page-1);
        //createCells(page);
    }

      //createCells(page);
    
    useEffect(() =>{
       /* FetchExamData(props.session,(data) =>{
            console.log(data)
        })*/
        props.FetchExamData(props.session,(data) =>{
            setExamDate(data.data)
        })
    },[])
 

    
    return (
        <>
           <AddExamDate isOpened={addExamDateOpen} setIsOpened={setAddExamDateOpen} />
            <ContentHeader
                customData={{
                section: "classes",
                subsection: "own",
                image: classesimg,
                breadcrumbs: [{ key: "classes", text: "Examens" }],
                button: [
                    {
                    key: "create",
                    name: "Ajouter une date d'examen",
                    class: "theme-button-md",
                    icon: PlusIcon,
                    },
                ],
                }}
                handleAction={AddDateBtn}
            />
           
            <div className="content-body h-full">
                <section className="flex flex-row flex-wrap gap-2">
                    {allExamData.map((data) => {return <section key={cells.indexOf(data)} className="flex flex-col justify-center border-2 border-gray-700 rounded-lg gap-1 p-1 m-2 w-72">{
                        <DayCell 
                            info={data}
                        />
                    }</section>})}
                    {cells.map((cell) => {return <section key={cells.indexOf(cell)} className="flex flex-col justify-center border-2 border-gray-700 rounded-lg gap-1 p-1 m-2 w-72">{cell}</section>})}
                </section>
                <div className="self-center"><button className="justify-center m-2 p-1 border-2 border-black" onClick={pageDown}>←</button> <button className="justify-center m-2 p-1 border-2 border-black" onClick={pageUp}>→</button></div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({FetchExamData},
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TeacherExamDate);
