import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import {
    getChapterForLevel,
  } from "@classesaction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";

import classImg from "../../../../../assets/image/defaultclassimg.png";
import blankImg from "../../../../../assets/image/blank_classes.svg";
import {
    fetchLoginUserDetails,
  } from "@settingsaction";
  

const ChapterCarousel = (props) => {
  const navigate = useNavigate();
  var { session, videoPlayModal, classDetails } = props;
  const slider = React.useRef(null);
  const [chapterList,SetChapterList] = useState({})
  const [Init,SetInit] = useState(false);

    const navigateChapter = (item) => {

        navigate(`/classes/${item.classidfk}/${item.chapterid}`);

    };

    useEffect(()=>{

      if(Init == false){
          SetInit(true);
          props.getChapterForLevel(session,{level:classDetails.data.level, matiere: classDetails.data.programname}, function(res){
              SetChapterList(res.data)
              
          })
      }

    },[chapterList])

  const renderCourese = () => {
    return _.map(chapterList, (item, index) => {

        var classImage = item?.image
        ? `${BASE_IMAGES_URL}/${item?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : classImg;
      return (
        <div
          key={`course_key_${index}`}
          onClick={() => navigateChapter(item)}
          className="bg-white border shadow-lg rounded-xl cursor-pointer hover:shadow-xl"
        >
          <div className="w-full bg-theme rounded-tl-xl rounded-tr-xl">
            <div className="h-48">
              <img
                className="object-cover rounded-tl-xl rounded-tr-xl h-full w-full"
                src={classImage}
              />
            </div>
          </div>
          <div className="px-4 py-5">
            <div>
              <div className="text-2xl 2xs:text-3xl text-primary font-normal h-20">
                {item?.chaptername}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const next = () => slider.current.slickNext();
  const previous = () => slider.current.slickPrev();

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
        {chapterList.length > 0 & session.usertype == "teacher" && classDetails.data.createdby !== 1 &&
            <>
                <div className="text-2xl 2xs:text-3xl font-semibold text-primary capitalize mb-8">
                Choisissez un chapitre et envoyez un quizz à vos élèves
                </div>
            
                <Slider {...settings} className="custom-silck" ref={slider}>
                {renderCourese()}
                </Slider>
                <div className="mt-8 text-center space-x-3">
                <button type="button" className="" onClick={previous}>
                    <ChevronLeftIcon className="h-6 w-5" />
                </button>
                <button type="button" className="" onClick={next}>
                    <ChevronRightIcon className="h-6 w-5" />
                </button>
                </div>
        
            </>
        }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({getChapterForLevel, fetchLoginUserDetails}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChapterCarousel);
