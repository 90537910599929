import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStudentModule, selectedStudent,deleteInvit } from "@studentsaction";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const ItemList = (props) => {
  const navigate = useNavigate();
  let { listitem, bgWhite, session } = props;

  const handleCLasses = () => {
    // console.log("listitem :- ", listitem);

    props.selectedStudent(listitem);
    navigate(`${listitem?.userid}`);
  };

  const editItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.createStudentModule({ key: "edit", show: true, value: listitem });
  };

  const deleteInvite = (e,email) => {
    e.preventDefault();
    e.stopPropagation();
    props.deleteInvit(session,()=>{
      props.refresh();
    },email)
    //props.createStudentModule({ key: "edit", show: true, value: listitem });
  };

  return (
    <>
      <tr
        className={classNames(
          bgWhite ? "bg-white" : "bg-gray-50",
          "cursor-pointer"
        )}
        onClick={handleCLasses}
      >
        <td className="tabledata whitespace-nowrap">{listitem.username === undefined ? "En attente d'inscription" : listitem.username }</td>
        <td className="tabledata whitespace-nowrap">{listitem.email || listitem.mail}</td>
        <td className="tabledata whitespace-nowrap">
          {listitem?.classidfk || listitem?.classid
            ? `${listitem?.classname} - ${listitem?.programname}`
            : "En attente d'inscription"}
        </td>
        <td className="tabledata">
          <span
            className="text-theme cursor-pointer hover:text-themeDark"
            onClick={(e) => {listitem.username === undefined ? deleteInvite(e,listitem.mail) : editItem(e)}}
          >
            {listitem.username === undefined ? "Supprimer l'invitation" : LocaleStrings.button_edit}
          </span>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createStudentModule, selectedStudent , deleteInvit}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
