import {
    HISTORY_RESULT,
  
} from "../actions/index.js";

export var history = (state = {}, action) => {
    if (action.type === HISTORY_RESULT) {
        return action.payload;
    }
    return state;
};

