import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { fetchDashboardQuizTaken } from "@dashboardaction";
import LocaleStrings from "@language";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TeacherLineChart = (props) => {
  var { session, dashboardQuizTakenData } = props;
  const [period, setPeriod] = useState("weekly");

  const handleChartData = (e) => {
    var val = e.target.value;
    props.fetchDashboardQuizTaken(session, val);

    setPeriod(val);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      // xAxes: {
      //   grid: {
      //     display: true,
      //   },
      //   stacked: true,
      //   ticks: {
      //     display: true,
      //     stepSize: 1,
      //     padding: 10,
      //   },
      // },
      yAxes: {
        grid: {
          display: true,
        },
        min: 0,
        ticks: {
          display: true,
          stepSize: 1,
          padding: 10,
        },
      },
    },
  };
  var labels = [];
  var assess = [];
  var pract = [];
  // const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  if (dashboardQuizTakenData?.data) {
    labels = Object.keys(dashboardQuizTakenData?.data);

    labels.map((item) => {
      assess.push(dashboardQuizTakenData?.data?.[item]?.assessment ?? 0);
      pract.push(dashboardQuizTakenData?.data?.[item]?.parctice ?? 0);
    });
  }

  const data = {
    labels,
    datasets: [
      {
        label: LocaleStrings.practice,
        data: pract,
        fill: true,
        borderColor: "rgb(0, 152, 241)",
        backgroundColor: "rgb(0, 152, 241, 0.5)",
      },
      {
        label: LocaleStrings.assessment,
        data: assess,
        fill: true,
        borderColor: "rgb(209, 213, 219)",
        backgroundColor: "rgba(209, 213, 219, 0.5)",
      },
    ],
  };

  // console.log("dashboardQuizTakenData :- ", dashboardQuizTakenData);
  
  return (
    <>
      <div className="sm:flex items-center justify-between mb-8">
        <span className="text-2xl sm:text-3xl font-semibold text-primary capitalize">
          {LocaleStrings.dashboard_text_quiz_taken}
        </span>
        <div>
          <select
            name="period"
            className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
            value={period}
            onChange={handleChartData}
          >
            <option value="weekly">{LocaleStrings.weekly}</option>
            <option value="monthly">{LocaleStrings.monthly}</option>
          </select>
        </div>
      </div>
      <div className="max-w-full mx-auto border border-defaultColor rounded mt-2">
        <div className="h-48 sm:h-96 md:h-30rem overflow-auto">
          <Line options={options} data={data} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardQuizTakenData: state.dashboardQuizTakenData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDashboardQuizTaken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeacherLineChart);
