import {
  STUDENT_LIST,
  CREATE_STUDENT,
  IMPORT_STUDENT,
  USER_BULK_UPLOAD_LEFT,
  MEMBER_BULK_UPLOAD_LEFT,
  STUDENT_DETAILS,
  STUDENT_ACHIEVEMENTS_DETAILS,
  STUDENT_CLASS_LIST,
  STUDENT_ALL_CLASS_LIST,
  STUDENT_QUIZ_TAKEN_LIST,
  STUDENT_VIEWED_VIDEO_LIST,
  STUDENT_EXAM_LIST,
  STUDENT_PROGRESS_STUDENT_SNAP_DATA,
  STUDENT_PROGRESS_STUDENT_ACTIVITY,
  STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA,
  STUDENT_COURSE
} from "@studentsaction";

export var studentList = (state = {}, action) => {
  if (action.type === STUDENT_LIST) {
    return action.payload;
  }
  return state;
};

export var createStudent = (state = {}, action) => {
  if (action.type === CREATE_STUDENT) {
    return action.payload;
  }
  return state;
};

export var importStudent = (state = {}, action) => {
  if (action.type === IMPORT_STUDENT) {
    return action.payload;
  }

  return state;
};

export var bulkUserUplodingLeft = (state = {}, action) => {
  if (action.type === USER_BULK_UPLOAD_LEFT) {
    return action.payload;
  }
  return state;
};

export var bulkMemberUplodingLeft = (state = {}, action) => {
  if (action.type === MEMBER_BULK_UPLOAD_LEFT) {
    return action.payload;
  }
  return state;
};

export var studentDetails = (state = {}, action) => {
  if (action.type === STUDENT_DETAILS) {
    return action.payload;
  }
  return state;
};

export var studentAchievements = (state = {}, action) => {
  if (action.type === STUDENT_ACHIEVEMENTS_DETAILS) {
    return action.payload;
  }
  return state;
};

export var studentClassList = (state = {}, action) => {
  if (action.type === STUDENT_CLASS_LIST) {
    return action.payload;
  }
  return state;
};

export var studentAllClassList = (state = {}, action) => {
  if (action.type === STUDENT_ALL_CLASS_LIST) {
    return action.payload;
  }
  return state;
};

export var studentQuizTakenList = (state = {}, action) => {
  if (action.type === STUDENT_QUIZ_TAKEN_LIST) {
    return action.payload;
  }
  return state;
};

export var studentViewedVideoList = (state = {}, action) => {
  if (action.type === STUDENT_VIEWED_VIDEO_LIST) {
    return action.payload;
  }
  return state;
};

export var studentExamList = (state = {}, action) => {
  if (action.type === STUDENT_EXAM_LIST) {
    return action.payload;
  }
  return state;
};

export var studentProgressStudentSnap = (state = {}, action) => {
  if (action.type === STUDENT_PROGRESS_STUDENT_SNAP_DATA) {
    return action.payload;
  }
  return state;
};

export var studentActivities = (state = {}, action) => {
  if (action.type === STUDENT_PROGRESS_STUDENT_ACTIVITY) {
    return action.payload;
  }
  return state;
};

export var studentClassSetProgress = (state = {}, action) => {
  if (action.type === STUDENT_PROGRESS_STUDENT_CLASS_SET_DATA) {
    return action.payload;
  }
  return state;
};

export var studentAllCourse = (state = {}, action) => {
  if (action.type === STUDENT_COURSE) {
    return action.payload;
  }
  return state;
};
