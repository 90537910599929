import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import ContentHeader from "@contentheader";
import LocaleStrings from "@language";
import blankImg from "../../../../assets/image/success_placeholder.svg";

const PlanSuccess = (props) => {
  const navigate = useNavigate();

  const handlePlanPage = () => {
    navigate(`/dashboard`);
  };

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "plans",
          breadcrumbs: [
            {
              key: "settings",
              text: LocaleStrings.settings,
              href: "/settings",
            },
            {
              key: "plans",
              href: "/settings/plans",
              text: LocaleStrings.settings_text_plans,
            },
            {
              key: "success",
              text: LocaleStrings.settings_text_plans_success,
            },
          ],
        }}
      />

      <div className="content-body h-5/6">
        <div className="flex items-center justify-center h-full">
          <div className="text-center">
            <img className="inline mb-2" src={blankImg} />
            <h2 className="text-2xl font-normal text-primary">
              {LocaleStrings.subscription_success_page_message}
            </h2>

            <button className="theme-button-lg mt-5" onClick={handlePlanPage}>
                Accéder à mon espace
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanSuccess);
