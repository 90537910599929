import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, LOCAL_TO_GMT_TIME } from "@constant";
import LocaleStrings from "@language";

const DayCell = (props) => {
  const navigate = useNavigate();
  var { session, info } = props;

  return (
    <>
        <h1 className="text-lg font-bold inline uppercase">{info.classname} - {convertDateIntoLocal(info?.examdate).format('DD MM YYYY').toString()}</h1>
        <h1 className="text-lg font-bold inline">{info.chaptername}</h1>
        
        {session.usertype == "teacher" ?
            <>
                <p className="border-2 rounded-lg border-green-400 bg-green-400">Taux de révision: 90%</p>
            </>
        :
            <>
             <a href="#/myspace/1739325461570030">
                <div>
                    <p className="border-2 rounded-lg border-green-400 bg-green-400">Test exercice fait</p>
                </div>
            </a>
            <a href="#/myspace/1739325461570030">
                <div>
                    <p className="border-2 rounded-lg border-red-400 bg-orange-400">Test exercice non fait</p>
                
                </div>
            </a>
            </>
        }
    </>

          
         
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DayCell);