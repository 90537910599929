import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import {
  attemptSetQstModule,
  clearAttemptedSetQstList,
  attemptedSetFinished,
  attempteSet,
  fetchAttemptedSetQstList,
  setUserAchivement,
} from "@questionsetsaction";
import { createUserActivity } from "@mainaction";
import ItemList from "./attempt-question-list";
import Loader from "@loader";
import LocaleStrings from "@language";
import resultImg from "../../../assets/image/result_cup.svg";

const AttemptSet = (props) => {
  const navigate = useNavigate();
  var { session, attemptSetModal, attemptedSetData, attemptedSetQuestionList } =
    props;
  var [pageNumber, setPageNumber] = useState(1);
  var [finishLoader, setFinishLoader] = useState(false);
  var [earnedPoint, setEarnedPoint] = useState(0);

  // console.log("attemptSetModal :- ", attemptSetModal);
  // console.log("attemptedSetData :- ", attemptedSetData);
  // console.log("attemptedSetQuestionList :- ", attemptedSetQuestionList);

  const close = () => {
    if (attemptedSetData?.show || attemptSetModal?.mode === "practice") {
      // Set is finished
      props.attemptSetQstModule({ show: false });
      props.clearAttemptedSetQstList();
      props.attemptedSetFinished({ show: false });
    } else {
      // Only for assesment confirm that it will not add points to user
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-alert-body">
              <div className="confirmation-title">
                {LocaleStrings.setsattempt_abort_quiz_confirm_title}
              </div>
              <div className="confirmation-subtitle">
                {LocaleStrings.setsattempt_abort_quiz_confirm_text}
              </div>

              <div className="custom-confirm-alert-button-group">
                <button
                  className="confirmation-action-button"
                  onClick={onClose}
                >
                  {LocaleStrings.button_no}
                </button>
                <button
                  className="confirmation-action-button"
                  onClick={() => {
                    onClose();
                    // Fetching latest attemted question for set finish activity
                    props.fetchAttemptedSetQstList(
                      session,
                      attemptSetModal?.id,
                      (response) => {
                        // console.log("response :- ", response);

                        // Set assessment abort
                        var quizFinalActivity =
                          response?.count === 0
                            ? "quiz_abort"
                            : attemptSetModal?.value?.totalquestions ===
                              response?.count
                            ? "quiz_finish"
                            : "quiz_initial_finish";

                        var aval = {
                          useridfk: session?.userid,
                          classidfk: attemptSetModal?.value?.classidfk,
                          chapteridfk: attemptSetModal?.value?.chapteridfk,
                          questionsetidfk: attemptSetModal?.value?.setid,
                          activitytype: quizFinalActivity,
                          relatedid: attemptSetModal?.id,
                        };
                        props.createUserActivity(session, aval, (res) => {});
                        props.attemptSetQstModule({ show: false });
                        props.clearAttemptedSetQstList();
                        props.attemptedSetFinished({ show: false });
                      }
                    );
                  }}
                >
                  {LocaleStrings.button_yes}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const close1 = () => {};

  const handlePagination = (pageNum) => {
    setPageNumber(pageNum);
  };

  const handleRetake = () => {
    var values = {
      useridfk: session?.userid,
      classidfk: attemptSetModal?.value?.classidfk,
      chapteridfk: attemptSetModal?.value?.chapteridfk,
      qestionsetidfk: attemptSetModal?.value?.setid,
      totalquestions: attemptSetModal?.value?.totalquestions ?? null,
      mode: attemptSetModal?.mode,
    };
    // console.log("values :- ", values);

    props.attempteSet(session, values, (res) => {
      // console.log("res :- ", res);
      if (res?.success === 1) {
        setPageNumber(1);
        var aval = {
          useridfk: session?.userid,
          classidfk: attemptSetModal?.value?.classidfk,
          chapteridfk: attemptSetModal?.value?.chapteridfk,
          questionsetidfk: attemptSetModal?.value?.setid,
          // activitytype: "quiz_start",
          activitytype:
            attemptSetModal?.mode === "assessment"
              ? "quiz_start"
              : attemptSetModal?.mode === "practice" &&
                attemptSetModal?.value?.privacy === "private"
              ? "quiz_practice_private"
              : "quiz_practice",

          relatedid: res?.data?.resource?.[0]?.id,
        };
        props.createUserActivity(session, aval, (res) => {});

        props.attemptedSetFinished({ show: false });
        props.attemptSetQstModule({
          show: true,
          mode: attemptSetModal?.mode,
          id: res?.data?.resource?.[0]?.id,
          value: attemptSetModal?.value,
        });
        props.clearAttemptedSetQstList();

        if (attemptSetModal?.mode === "assessment") {
          props.fetchAttemptedSetQstList(session, res?.data?.resource?.[0]?.id);
        }
      }
    });
  };

  const handleDashboard = () => {
    props.attemptSetQstModule({ show: false });
    props.attemptedSetFinished({ show: false });
    props.clearAttemptedSetQstList();
    navigate(`/dashboard`);
  };

  const handleSetFinish = () => {
    setFinishLoader(true);
    // Fetching latest attemted question for set finish activity
    props.fetchAttemptedSetQstList(
      session,
      attemptSetModal?.id,
      (response) => {
        // console.log("response :- ", response);

        // Set assessment abort
        var quizFinalActivity =
          response?.count === 0
            ? "quiz_abort"
            : attemptSetModal?.value?.totalquestions === response?.count
            ? "quiz_finish"
            : "quiz_initial_finish";

        var aval = {
          useridfk: session?.userid,
          classidfk: attemptSetModal?.value?.classidfk,
          chapteridfk: attemptSetModal?.value?.chapteridfk,
          questionsetidfk: attemptSetModal?.value?.setid,
          activitytype: quizFinalActivity,
          relatedid: attemptSetModal?.id,
        };

        if (attemptSetModal?.mode === "assessment") {
          props.createUserActivity(session, aval, (res) => {});
        }
        props.setUserAchivement(
          session,
          { userid: session?.userid, attemptedid: attemptSetModal?.id },
          (res) => {
            setEarnedPoint(res?.data?.earnedpoints);
            setFinishLoader(false);
          }
        );
      }
    );

    if (props?.callfrom === "dashboard") {
      props.callbackAction();
    }
  };

  const renderItems = () => {
    var showItem =
      attemptSetModal?.value?.questions_by_questionsetidfk[pageNumber - 1];
    // console.log("showItem :- ", showItem);

    return (
      <ItemList
        attemptSetModal={attemptSetModal}
        listitem={showItem}
        currentPage={pageNumber}
        setDetails={attemptSetModal?.value}
        pageDataLength={attemptSetModal?.value?.totalquestions}
        pagination={handlePagination}
        callbacksetfinish={handleSetFinish}
      />
    );
  };

  var correctAnswer = _.filter(attemptedSetQuestionList?.data, (obj) => {
    return obj.answer === "correct";
  });

  return (
    <>
      <Transition.Root
        show={attemptSetModal?.show ? true : false}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={close1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-50 rounded text-left overflow-hidden shadow-xl transform transition-all w-full h-screen sm:align-middle">
                <div className="h-full flex flex-col">
                  <header className="flex items-center justify-between px-4 py-4 border-b-1 border-defaultColor bg-white">
                    {attemptedSetData?.show ? (
                      <>
                        <p className="text-2xl 2xs:text-3xl font-normal text-primary capitalize">
                          {LocaleStrings.setsattempt_text_quiz_result}
                        </p>
                        <div className="p-2">
                          <button
                            className="theme-icon-button-xs"
                            onClick={close}
                          >
                            <XIcon
                              className="h-4 w-4 2xs:h-6 2xs:w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* <div className="mr-4 md:mr-0">
                          <button className="default-button-xs">
                            <ArrowLeftIcon className="h-4 w-3" />
                          </button>
                        </div> */}

                        <div className="hidden md:block">
                          <p className="text-base 2xs:text-xl font-normal text-primary">
                            {LocaleStrings.term} {pageNumber} /{" "}
                            {attemptSetModal?.value?.totalquestions}
                          </p>
                        </div>
                        <div className="w-full md:w-74%">
                          <h4 className="sr-only">Status</h4>
                          <div className="bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-2 bg-theme rounded-full"
                              style={{
                                width: `${
                                  (pageNumber /
                                    attemptSetModal?.value?.totalquestions) *
                                  100
                                }%`,
                              }}
                            />
                          </div>
                        </div>

                        <div className="hidden md:block">
                          <button className="theme-button-md" onClick={close}>
                            {LocaleStrings.button_end_quiz}
                          </button>
                        </div>
                        <div className="block md:hidden p-2">
                          <button
                            className="theme-icon-button-xs"
                            onClick={close}
                          >
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </>
                    )}
                  </header>

                  {attemptedSetData?.show ? (
                    <div className="max-w-lg mx-auto p-4 h-full w-full">
                      {finishLoader === false ? (
                        <div className="text-center">
                          <div className="text-xl 2xs:text-3xl font-normal text-primary mt-16">
                            {attemptSetModal?.mode === "assessment"
                              ? LocaleStrings.setsattempt_text_completed_quiz
                              : LocaleStrings.setsattempt_text_practice_quiz}{" "}
                            <span className="font-semibold">
                              {attemptSetModal?.value?.setname}
                            </span>
                          </div>

                          <img className="mt-24 inline" src={resultImg} />

                          {attemptSetModal?.mode === "assessment" ? (
                            <>
                              <h1 className="text-base 2xs:text-2xl font-semibold text-primary mt-5">
                                {correctAnswer.length}{" "}
                                {LocaleStrings.setsattempt_text_correct_ans}
                              </h1>
                              <h1 className="text-base 2xs:text-2xl font-semibold text-primary mt-2">
                                {LocaleStrings.setsattempt_text_earned_point}
                                {": "}
                                {earnedPoint}
                              </h1>
                              <p className="text-base 2xs:text-2xl font-normal text-secondary mt-2">
                                Vous avez manqué{" "}
                                {attemptSetModal?.value?.totalquestions -
                                  (attemptedSetQuestionList?.count ?? 0)}{" "}
                                question sur{" "}
                                {attemptSetModal?.value?.totalquestions}.
                                {/* You missed{" "}
                                {attemptSetModal?.value?.totalquestions -
                                  (attemptedSetQuestionList?.count ?? 0)}{" "}
                                out of {attemptSetModal?.value?.totalquestions}{" "}
                                questions. */}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="text-base 2xs:text-2xl font-normal text-secondary mt-2">
                                {
                                  LocaleStrings.setsattempt_congrats_text_practice_quiz_completed
                                }
                                <br/>
                                <span className="font-semibold">Nombre de points gagnés: 10</span>
                              </p>
                            </>
                          )}

                          <button
                            className="theme-button-md w-full mt-5"
                            onClick={handleRetake}
                          >
                            {LocaleStrings.button_retake_quiz}
                          </button>
                          <p
                            className="text-xl 2xs:text-2xl font-normal text-theme mt-5 cursor-pointer hover:underline"
                            onClick={handleDashboard}
                          >
                            {LocaleStrings.setsattempt_text_goto_dashboard}
                          </p>
                        </div>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col h-full overflow-y-auto">
                      <div className="flex flex-1 items-center justify-center p-4">
                        <div>
                          <div className="relative mb-5">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex items-center justify-between">
                              <span className="pr-1 bg-gray-50 text-xl 2xs:text-3xl font-normal text-primary capitalize">
                                {attemptSetModal?.mode === "assessment"
                                  ? LocaleStrings.assessment
                                  : LocaleStrings.practice1}
                              </span>
                              <button
                                type="button"
                                className="cursor-text inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-base 2xs:text-2xl font-normal text-secondary rounded-full bg-white"
                              >
                                <span>
                                  {attemptSetModal?.mode === "assessment"
                                    ? LocaleStrings.assessment
                                    : LocaleStrings.practice1}
                                </span>
                              </button>
                            </div>
                          </div>

                          {renderItems()}
                        </div>
                      </div>
                    </div>
                  )}

                  {attemptedSetData?.show ? (
                    ""
                  ) : (
                    <footer className="flex-shrink-0 px-4 lg:px-60 py-4 flex justify-around items-center border-t-1 border-defaultColor bg-white">
                      {/* <button
                        className="default-button-md"
                        onClick={() =>
                          pageNumber == 1
                            ? null
                            : handlePagination(pageNumber - 1)
                        }
                        disabled={pageNumber == 1 ? true : false}
                      >
                        {LocaleStrings.button_previous}
                      </button> */}
                      <p className="text-base 2xs:text-xl font-normal text-primary">
                        {LocaleStrings.term} {pageNumber} /{" "}
                        {attemptSetModal?.value?.totalquestions}
                      </p>
                      {/* <button
                        className="default-button-md"
                        onClick={() =>
                          pageNumber * 1 >=
                          attemptSetModal?.value?.totalquestions
                            ? null
                            : handlePagination(pageNumber + 1)
                        }
                        disabled={
                          pageNumber * 1 >=
                          attemptSetModal?.value?.totalquestions
                            ? true
                            : false
                        }
                      >
                        {LocaleStrings.button_next}
                      </button> */}
                    </footer>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    attemptSetModal: state.attemptSetModal,
    attemptedSetData: state.attemptedSetData,
    attemptedSetQuestionList: state.attemptedSetQuestionList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      attemptSetQstModule,
      clearAttemptedSetQstList,
      attemptedSetFinished,
      attempteSet,
      fetchAttemptedSetQstList,
      createUserActivity,
      setUserAchivement,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AttemptSet);
