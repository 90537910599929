import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { loadState, reset } from "./localStorage";
import {
  ACTION_LOGIN,
  ACTION_LOGOUT,
  SESSION_TOKEN,
  UPDATE_USER_NAME,
  MQ_TEACHER_CLASS_LISTS,
  TEACHER_CLASS_LISTS,
  TEACHER_STUDENT_LISTS,
  ALL_STUDENT_LISTS,
  ALL_CLASS_WITH_CHAPTER_LIST,
  ALL_CLASS_WITH_CHAPTER_AND_SET_LIST,
  USER_QUIZ_LISTS,
  GLOBAL_LEVELS,
} from "@mainaction";
import { APP_API_KEY } from "@constant";
import {
  dashboardData,
  dashboardQuizTakenData,
  dashboardLeaderboardData,
  studentCustomOwnAchivement,
  studentOwnAchivement,
  studentOwnAchivementModal,
  userUpcomingExams,
  teacherQuizInvitationList,
  quizInvitationList,
  userAllActivities,
  dashboardQuizModal,
  dashboardInviteQuizModal,
} from "@dashboardreducer";
import {
  classList,
  allClassList,
  userClassProgramList,
  createClass,
  classDetails,
  createChapter,
  classChapterList,
  classAllSets,
  classAllMembers,
  classMemberList,
  classAllChapters,
  classChapterDetails,
  createSetFromMQModal,
  classChapterSets,
  chapterNextExam,
  createChapterNextExam,
  createQuestionSet,
  importQuestionSet,
  questionSetDetails,
  globalQuestionSetDetails,
  classProgressClassSnap,
  classActivities,
  classPrgressChapterAnalysis,
  classPrgressChapterProgress,
  classPrgressSetsList,
  classPrgressSetData,
} from "@classesreducer";
import {
  studentList,
  createStudent,
  importStudent,
  bulkUserUplodingLeft,
  bulkMemberUplodingLeft,
  studentDetails,
  studentAchievements,
  studentClassList,
  studentAllCourse,
  studentAllClassList,
  studentQuizTakenList,
  studentViewedVideoList,
  studentExamList,
  studentProgressStudentSnap,
  studentActivities,
  studentClassSetProgress,
} from "@studentsreducer";
import {
  classTeachers,
  studentUpcomingExams,
  manageUpcomingExam,
} from "@myspacereducer";
import {
  videoList,
  videoPlayModal,
  userVideoStatus,
} from "@cognivideosreducer";
import {
  mainSetList,
  createSetModal,
  importTermsModal,
  importedSetTerms,
  attemptSetModal,
  attemptedSetQuestionList,
  attemptedSetData,
} from "@questionsetsreducer";
import {
  teacherAllMembers,
  teacherAllChapters,
  teacherAllSets,
} from "@reportsreducer";
import {
  chatThreadList,
  displayChats,
  selectedChatThread,
  chatData,
} from "@messagesreducer";
import {
  userDetails,
  userPlanList,
  userActivePlan,
  changePlanModal,
} from "@settingsreducer";

import {
  history,
} from "@historyreducer";

import {
  AllRanking
} from "@statsreducer"

var loginReducer = (state = true, action) => {
  // need to change it to false
  state = loadState();

  if (state == undefined) {
    state = {
      isLoggedIn: false,
    };
  }

  if (action.type === ACTION_LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

var tokensReducer = (state = {}, action) => {
  state = loadState();

  if (state == undefined || state.session == undefined) {
    state = {
      session: {
        // apiKey: "",
        // sessionToken: "",
        // userid: "",
        // udfid: "",
        // username: "",
        // email: "",
        // usertype: "",
        // mqstudent: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.apiKey = APP_API_KEY;
      state.session.sessionToken = action.payload.session_token;
      state.session.userid = action.payload.userid;
      state.session.udfid = action.payload.dfid;
      state.session.username = action.payload.username;
      state.session.email = action.payload.email;
      state.session.usertype = action.payload.usertype;
      state.session.image = action.payload.image;
      state.session.mqstudent = action.payload.mqstudent;
    }
  }

  if (action.type === UPDATE_USER_NAME) {
    state.session.username = action.payload.username;
  }

  if (action.type === ACTION_LOGOUT) {
    // state.session.apiKey = "";
    // state.session.sessionToken = "";
    // state.session.userid = "";
    // state.session.udfid = "";
    // state.session.username = "";
    // state.session.email = "";
    // state.session.usertype = "";
    // state.session.image = "";
    // state.session.mqstudent = "";
    state.session = {};
    reset();
  }

  return state.session;
};

var mqteacherClasses = (state = {}, action) => {
  if (action.type === MQ_TEACHER_CLASS_LISTS) {
    return action.payload;
  }
  return state;
};

var teacherClasses = (state = {}, action) => {
  if (action.type === TEACHER_CLASS_LISTS) {
    return action.payload;
  }
  return state;
};

var teacherStudents = (state = {}, action) => {
  if (action.type === TEACHER_STUDENT_LISTS) {
    return action.payload;
  }
  return state;
};

var allStudents = (state = {}, action) => {
  if (action.type === ALL_STUDENT_LISTS) {
    return action.payload;
  }
  return state;
};

var allClassAndItsChapter = (state = {}, action) => {
  if (action.type === ALL_CLASS_WITH_CHAPTER_LIST) {
    return action.payload;
  }
  return state;
};

var allClassAndChapterANdItsSets = (state = {}, action) => {
  if (action.type === ALL_CLASS_WITH_CHAPTER_AND_SET_LIST) {
    return action.payload;
  }
  return state;
};

var userAllQuizLists = (state = {}, action) => {
  if (action.type === USER_QUIZ_LISTS) {
    return action.payload;
  }
  return state;
};

var globalLevels = (state = {}, action) => {
  if (action.type === GLOBAL_LEVELS) {
    return action.payload;
  }
  return state;
};

const appReducer = combineReducers({
  form: formReducer,
  isLoggedIn: loginReducer,
  session: tokensReducer,
  mqteacherClasses,
  teacherClasses,
  teacherStudents,
  allStudents,
  allClassAndItsChapter,
  allClassAndChapterANdItsSets,
  userAllQuizLists,
  globalLevels,
  AllRanking,
  // Dashboard
  dashboardData,
  dashboardQuizTakenData,
  dashboardLeaderboardData,
  studentCustomOwnAchivement,
  studentOwnAchivement,
  studentOwnAchivementModal,
  userUpcomingExams,
  teacherQuizInvitationList,
  quizInvitationList,
  userAllActivities,
  dashboardQuizModal,
  dashboardInviteQuizModal,

  // Classes
  classList,
  allClassList,
  userClassProgramList,
  createClass,
  classDetails,
  createChapter,
  classChapterList,
  classAllSets,
  classAllMembers,
  classMemberList,
  classAllChapters,
  classChapterDetails,
  createSetFromMQModal,
  classChapterSets,
  chapterNextExam,
  createChapterNextExam,
  createQuestionSet,
  importQuestionSet,
  questionSetDetails,
  globalQuestionSetDetails,
  classProgressClassSnap,
  classActivities,
  classPrgressChapterAnalysis,
  classPrgressChapterProgress,
  classPrgressSetsList,
  classPrgressSetData,

  // Students
  studentList,
  createStudent,
  importStudent,
  bulkUserUplodingLeft,
  bulkMemberUplodingLeft,
  studentDetails,
  studentAchievements,
  studentClassList,
  studentAllCourse,
  studentAllClassList,
  studentQuizTakenList,
  studentViewedVideoList,
  studentExamList,
  studentProgressStudentSnap,
  studentActivities,
  studentClassSetProgress,

  // Myspace
  classTeachers,
  studentUpcomingExams,
  manageUpcomingExam,

  // Videos
  videoList,
  videoPlayModal,
  userVideoStatus,

  // Question Set
  mainSetList,
  createSetModal,
  importTermsModal,
  importedSetTerms,
  attemptSetModal,
  attemptedSetQuestionList,
  attemptedSetData,

  // Reports
  teacherAllMembers,
  teacherAllChapters,
  teacherAllSets,

  // Messages
  chatThreadList,
  displayChats,
  selectedChatThread,
  chatData,

  // Settings
  userDetails,
  userPlanList,
  userActivePlan,
  changePlanModal,

  //history
  history,
});

const rootReducer = (state, action) => {
  if (action.type === "ACTION_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
