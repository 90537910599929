import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { BASE_IMAGES_URL } from "@constant";
import Loader from "@loader";
import LocaleStrings from "@language";
import SetGraph from "./set-graph";

const SetsData = (props) => {
  var { session, teacherAllSets } = props;

  const renderList = (data) => {
    return data?.map((item, index) => {
      return (
        <li key={`qst_key_${index}`} className="py-2 xs:py-4 px-5 sm:px-6 ">
          <div className="flex items-center justify-between">
            {item?.image ? (
              <div>
                <div className="mr-5  h-14 w-14 sm:h-16 sm:w-16 rounded-xl border-1">
                  <img
                    className="h-full w-full object-contain inline-block rounded-lg"
                    src={`${BASE_IMAGES_URL}/${item?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex items-center justify-between w-full">
              <div className="flex-1 text-xl sm:text-xl font-semibold text-secondary">
                {item?.question ?? ""}
              </div>
              <div className="ml-4 text-xl sm:text-2xl font-semibold text-primary">
                {item?.total ?? 0}{" "}
                {item?.total > 1 ? LocaleStrings.times : LocaleStrings.time}
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  // console.log("teacherAllSets :- ", teacherAllSets);

  return (
    <>
      <div className="grid grid-cols-12 sm:gap-6">
        <div className="col-span-12 md:col-span-6 h-full">
          <div className="border rounded-lg shadow-lg h-full">
            <div className="mx-5 sm:mx-6 my-3 sm:my-5">
              <div className="text-xl 2xs:text-2xl font-semibold text-primary capitalize">
                {LocaleStrings.report_text_top_correct_ans_title}
              </div>
            </div>
            <div className="h-90vh overflow-auto">
              {teacherAllSets?.data ? (
                <>
                  {teacherAllSets?.data?.mostcorrect?.length > 0 ? (
                    <ul role="list" className="overflow-y-auto h-full">
                      {renderList(teacherAllSets?.data?.mostcorrect)}
                    </ul>
                  ) : (
                    <div className="custom-item-center h-full">
                      <div className="text-center">
                        <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                          {LocaleStrings.report_text_top_correct_ans_no_list}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 h-full">
          <div className="border rounded-lg shadow-lg h-full">
            <div className="mx-5 sm:mx-6 my-3 sm:my-5">
              <div className="text-xl 2xs:text-2xl font-semibold text-primary capitalize">
                {LocaleStrings.report_text_top_incorrect_ans_title}
              </div>
            </div>
            <div className="h-90vh overflow-auto">
              {teacherAllSets?.data ? (
                <>
                  {teacherAllSets?.data?.mostincorrect?.length > 0 ? (
                    <ul role="list" className="overflow-y-auto h-full">
                      {renderList(teacherAllSets?.data?.mostincorrect)}
                    </ul>
                  ) : (
                    <div className="custom-item-center h-full">
                      <div className="text-center">
                        <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                          {LocaleStrings.report_text_top_incorrect_ans_no_list}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <SetGraph /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    teacherAllSets: state.teacherAllSets,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SetsData);
