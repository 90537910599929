import LocalizedStrings from "react-localization";

const LocaleStrings = new LocalizedStrings({
  fr: {
    // Menu
    dashboard: "Tableau de bord",
    classes: "Mes classes",
    students: "Élèves",
    myspace: "Mon espace",
    videos: "Les Vidéos Kojyto",
    questionsets: "Quizz",
    reports: "Synthèses",
    message: "Messages",
    settings: "Paramètres",
    logout: "Deconnection",

    // Common
    common_fail_message: "Une erreur est apparue !!",
    common_plan_expired: "Ton abonnement a expiré, renouvelle ton abonnement !",
    common_trial_expired:
      "Ta période d’essai a expiré, Choisis une formule d’abonnement pour continuer à profiter des services de Koyto !",
    welcome_back: "Bienvenue !",
    search: "Recherche",
    copy: "Copie",
    copied: "Copié",
    members: "Membres",
    admin: "Admin",
    guest: "Invité",
    owner: "Propriétaire",
    you: "Toi",
    invalid: "Invalide",
    not_assigned: "Non enregistré",
    notes: "Notes",
    term: "Question",
    by: "par",
    general: "General",
    today: "Aujourd’hui",
    sets: "quizz",
    times_practiced: "séances de travail",
    quiz: "Quizz",
    minutes: "minutes",
    program: "Matière",
    congratulate: "Féliciter",
    expires: "Expires",
    practice: "Réviser",
    practice1: "Entrainement",
    assessment: "S’évaluer",
    weekly: "Hebdomadaire",
    monthly: "Mensuel",
    yearly: "Annuel",
    this_week: "Cette semaine",
    this_month: "Ce mois",
    good_morning: "Bonjour",
    age: "age",
    courses: "Matières",
    continue: "continue",
    points: "points",
    class: "classe",
    student: "élève",
    month: "mois",
    year: "ans",
    years: "années",
    trial: "essai",
    google: "google",
    facebook: "facebook",
    twitter: "twitter",
    connect: "Connection",
    disconnect: "Déconnection",
    download_template: "Download template",
    teacher: "Enseignant",
    exam_on: "Examen",
    groups: "groupes",
    step: "étape",
    correct_answers: "Réponses correctes",
    incorrect_answers: "Réponses incorrectes",
    time: "temps",
    times: "fois",
    january: "janvier",
    february: "février",
    march: "mars",
    april: "avril",
    may: "mai",
    june: "juin",
    july: "juillet",
    august: "août",
    september: "septembre",
    october: "octobre",
    november: "novembre",
    december: "décembre",

    // Common Validations
    required: "Champ requis",
    invalid_email: "emai invalide",
    invalid_phone: "téléphone invalide",
    invalid_date: "date invalide",
    invalid_url: "url invalide",
    invalid_fb_url: "lien facebook invalide",
    invalid_linkedin_url: "lien linkedin invalide",
    invalid_twitter_url: "lien twitter invalide",
    required_terms_condition: "Valider les Conditions Générales d’Utilisation!",
    invalid_profile_image_size:
      "Image is smaller than recommended image, upload another image.",
    csv_file_not_parsed: "Could not parse file.",

    // Buttons
    button_login: "Se connecter",
    button_send: "Envoyer",
    button_reset_password: "Réinitialiser le mode de passe",
    button_signup: "S’inscrire",
    button_logout: "Déconnection",
    button_create: "Créer",
    button_save: "Enregistrer",
    button_close: "Fermer",
    button_cancel: "Annuler",
    button_edit: "Modifier/Ajouter",
    button_delete: "Effacer",
    button_back: "Retour",
    button_yes: "Oui",
    button_no: "Non",
    button_previous: "Précédent",
    button_next: "Suivant",
    button_finish: "Terminer",
    button_remove: "Déplacer/Retirer",
    button_download: "Télécharger",
    button_import: "Importer",
    button_send_invite: "Envoyer une invitation",
    button_enroll_member: "Inscrire un membre",
    button_options: "Options",
    button_crop: "Rogner",
    button_make_admin: "Nommer “Admin”",
    button_remove_admin: "Retirer statut “Admin”",
    button_edit_profile: "Modifier le profil",
    button_assessment: "S’évaluer",
    button_practice: "Réviser",
    button_invite: "Inviter",
    button_notify_class: "Envoyer aux élèves",
    button_end_quiz: "Finir le quizz",
    button_skip: "Passer/Ignorer",
    button_correct: "Réponse correcte",
    button_incorrect: "Réponse fausse",
    button_retake_quiz: "Refaire le quizz",
    button_change_plan: "Changer d’abonnement",
    button_invoices: "Factures",
    button_update: "Mise à jour",
    button_invite_set: "Envoyer un quizz",
    button_new_quiz: "Créer un quizz",
    button_view_quiz: "Voir le quizz",
    button_view_all: "Voir tout",
    button_report: "Synthèse",
    button_practice_quiz: "Reviser",
    button_take_quiz: "S'évaluer",
    button_buy: "Acheter",
    button_pay: "Payer",
    button_go_to_plan: "Choisir la formule",
    button_cancel_subscription: "Annuler l'abonnement",

    // Login & Signup
    facebook_signin: "Se connecter avec Facebook",
    twitter_signin: "Se connecter avec Twitter",
    google_signin: "Se connecter avec Google",

    login_common_text_or_login_with: "Ou continuer avec",

    login_title_login: "Connectez-vous à votre compte",
    login_form_label_email: "Email",
    login_form_ph_email: "Entrez votre adresse e-mail",
    login_form_label_password: "Mot de passe",
    login_form_ph_password: "Entrez votre mot de passe",
    login_form_label_rememberme: "Se souvenir de moi",
    login_text_forgot_password: "Mot de passe oublié",
    login_text_dont_have_account: "Vous n'avez pas de compte",
    login_text_go_to_login: "Aller à",
    login_error_text_email_send_success: "Email envoyé avec succès !",
    login_error_text_invalid_email_password:
      "Adresse électronique ou mot de passe non valide",
    login_error_text_social_account_not_connected:
      "Désolé... Vous n'êtes pas encore inscrit, créez d'abord un compte !",
    logout_confirmation_title: "Déconnexion",
    logout_confirmation_text: "Voulez-vous vraiment vous déconnecter ?",

    login_forgot_pass_title_login: "Mot de passe oublié",
    login_reset_pass_title_login: "Réinitialiser le mot de passe",
    login_reset_pass_form_label_email: "Email",
    login_reset_pass_form_ph_email: "Entrez votre adresse e-mail",
    login_reset_pass_form_label_code: "Code de confirmation",
    login_reset_pass_form_ph_code: "Entrez le code de confirmation",
    login_reset_pass_form_label_password: "Mot de passe",
    login_reset_pass_form_ph_password: "Entrer un nouveau mot de passe",
    login_reset_pass_form_label_confirm_pass: "Confirmer le mot de passe",
    login_reset_pass_form_ph_confirm_pass: "Répéter le nouveau mot de passe",
    login_reset_pass_form_error_text_invalid_credential:
      "Informations d'identification non valides",

    signup_title_signup: "Créer un nouveau compte",
    signup_form_label_usertype: "Je suis un",
    signup_form_usertype_option1: "Enseignant",
    signup_form_usertype_option2: "Etudiant",

    signup_form_label_dob: "Date de naissance",
    signup_form_ph_dob: "Sélectionnez votre date de naissance",
    signup_form_label_email: "Addresse e-mail",
    signup_form_ph_email: "Entrez votre adresse e-mail",
    signup_form_label_password: "Mot de passe",
    signup_form_ph_password: "Entrez votre mot de passe",
    signup_form_validation_min_password:
      "Le mot de passe doit être composé d’au-moins 6 caractères !",
    signup_error_registration_failed: "Votre inscription a échoué, réessayez !",
    signup_error_check_terms_conditions:
      "Veuillez valider les conditions générales d'utilisation",
    signup_text_already_an_account: "Vous avez déjà un compte ?",

    signup_user_info_screen_title1:
      "Bienvenue, commençons par quelques informations rapides sur vous.",
    signup_user_info_screen_title2: "Maintenant, créons votre première classe !",
    signup_user_form_label_username: "Comment vos élèves vous appellent-ils ?",
    signup_user_form_ph_fname: "Entrez votre prénom",
    signup_user_form_ph_lname: "Entrez votre nom de famille",
    signup_user_stu_form_label_username:
      "Comment ton professeur t'appelle-t-il ?",
    signup_user_stu_form_ph_username: "Entrez votre nom d'utilisateur",
    signup_user_teach_form_label_programname: "Entrer la matière",
    signup_user_teach_form_ph_programname: "Ajouter le nom de la matière",
    signup_user_form_success: "Détails enregistrés avec succès !",
    signup_user_button_logout: "Je sauvegarderai plus tard. Déconnexion.",

    // Profile & settings
    ps_text_account_settings: "Paramètres du compte",
    ps_text_signedin: "Connecté en tant que",

    // Dashboard
    dashboard_text_quiz_taken: "Quizz effectué",
    dashboard_text_video_watched: "Vidéos regardées",
    dashboard_text_total_classes: "Total des classes",
    dashboard_text_total_members: "Total des membres",
    dashboard_text_quiz_invitation: "Invitation à passer un quizz",
    dashboard_text_no_quiz_invitation_sent:
      "Aucune invitation à passer un quizz.",
    dashboard_text_upcoming_exams: "Examens à venir",
    dashboard_text_no_upcoming_exams:
      "Aucun calendrier d'examens à venir n'a été précisé.",
    dashboard_th_chapter_name: "Nom du chapitre",
    dashboard_th_deadline: "Date limite",
    dashboard_th_exam_date: "Date de l'examen",
    dashboard_text_quiz_overview: "Vue d’ensemble",
    dashboard_text_quiz_mark_scored: "Note obtenue",
    dashboard_text_my_activity: "Mon activité",
    dashboard_text_no_activity: "Aucune activité disponible",
    dashboard_text_last_quiz_taken: "Dernier quizz suivi",
    dashboard_text_top_program: "Matière favorite",
    dashboard_text_least_program: "Matière la moins travaillée",
    dashboard_th_quiz_name: "Nom du quizz",
    dashboard_th_program: "Matière",
    dashboard_th_student: "Étudiant",
    dashboard_th_invited_on: "Invité le",
    dashboard_th_quiz_taken: "Quizz fait",
    dashboard_th_leaderboard: "Top classement",
    dashboard_th_user_name: "Nom",
    dashboard_th_points: "Points",
    dashboard_th_trophy: "Trophées",
    dashboard_th_badges: "Badges",
    dashboard_th_reward: "Récompenses",
    dashboard_th_date: "Date",
    dashboard_th_gold_medal: "Médailles d'or",
    dashboard_th_silver_medal: "Médailles d'argent",
    dashboard_th_rank: "Rang",
    dashboard_th_global_rank: "Rang global",
    dashboard_th_quiz_teacher: "Professeur",
    dashboard_text_no_leaderboard_available: "Aucune donnée disponible.",
    dashboard_text_no_course_available:
      "Vous ne vous êtes pas encore inscrit à une matière",
    dashboard_label_program: "Sélectionnez une matière",
    dashboard_ph_program: "Sélectionnez votre matière",
    dashboard_label_chapter: "Sélectionnez un chapitre",
    dashboard_ph_chapter: "Sélectionnez votre chapitre",
    dashboard_label_set: "Sélectionnez un quizz",
    dashboard_ph_set: "Sélectionnez votre quizz",
    dashboard_label_student: "Sélectionnez un étudiant",
    dashboard_ph_student: "Sélectionnez votre étudiant",
    dashboard_invitation_to_student_for_quiz_success:
      "Invitation envoyée avec succès !",
    dashboard_invitation_to_student_for_quiz_error_no_student:
      "Aucun étudiant n'est inscrit à ce cours !",
    dashboard_text_all_reward_list: "Liste de toutes les récompenses",
    dashboard_text_no_award_available:
      "Bienvenue sur Kojyto ! Travailler n’a jamais été aussi amusant et efficace ! ",
    // Classes

    class_new_button: "Nouvelle classe",
    class_search_not_available:
      "Aucune classe disponible pour les données recherchées",
    class_not_available: "Vous n'avez pas encore créé de classe",
    class_not_available_subtitle: "Voulez-vous en créer une maintenant ?",
    class_text_chapters_completed: "chapitres terminés",
    class_text_invitations_sent: "invitations envoyées",
    class_add_form_title: "Créer une nouvelle classe",
    class_edit_form_title: "Mise à jour de la classe",
    class_form_label_image: "Image de la matière",
    class_form_label_level: "Classe",
    class_form_ph_level: "Choisir le niveau de la classe",
    class_form_label_classname: "Nom de la classe",
    class_form_ph_classname: "Entrer un nom de classe",
    class_form_label_programname: "Choisir ou créer une matière",
    class_form_ph_programname: "Sélectionner ou ajouter un nom de matière",
    class_form_label_description: "Description de la classe (facultatif)",
    class_form_ph_description: "Écrire la description de la classe",
    class_form_label_setquestion: "Autorisation d’accéder aux questions",
    class_form_sublabel_setquestion:
      "Autoriser les membres de la classe à ajouter ou supprimer un ensemble de questions",
    class_form_label_invitemember: "Inviter des membres",
    class_form_sublabel_invitemember:
      "Autoriser les membres de la classe à inviter de nouveaux membres",
    class_form_error_invalid_level: "Niveau invalide",
    class_form_error_duplicate: "Dupliquer la classe et le nom de la matière !",
    class_form_add_success: "Classe ajoutée avec succès !",
    class_form_update_success: "Classe mise à jour avec succès !",

    class_details_button_new_chapter: "Nouveau chapitre",
    class_details_button_import_member: "Importer un fichier d’élèves",
    class_details_button_create_member: "Nouveau membre",
    class_details_tab_title1: "Chapitres",
    class_details_tab_title2: "Mes élèves",
    class_details_tab_title3: "Progrès",
    class_details_tab_title4: "Paramètres de la classe",
    class_details_text_teacher_name: "Nom du professeur",
    class_details_text_upcoming_exam: "Examens à venir",

    class_chapter_search_not_available:
      "Aucun chapitre disponible pour les données recherchées",
    class_chapter_not_available: "Vous n'avez pas encore ajouté de chapitre",
    class_chapter_not_subtitle: "Voulez-vous en créer un maintenant ?",
    class_chapter_text_first_set: "Lancer un quizz",
    class_chapter_text_new_set: "Générer un nouveau quiz",
    class_chapter_text_existing_set: "Quizz existant",
    class_chapter_th_name: "Matière - Chapitre",
    class_chapter_th_qst_set: "Quizz",
    class_chapter_th_mq_set: "Quizz Kojyto",
    class_chapter_form_title: "Ajouter un nouveau chapitre",
    class_chapter_form_label_chaptername: "Nom du chapitre",
    class_chapter_form_ph_chaptername: "Entrer le nom du chapitre",
    class_chapter_form_label_image: "Image du chapitre",
    class_chapter_form_duplicate_error: "Le chapitre existe déjà!",
    class_chapter_form_add_success: "Chapitre ajouté avec succès !",
    class_chapter_form_update_success: "Chapitre mis à jour avec succès !",
    class_chapter_delete_title: "Supprimer le chapitre",
    class_chapter_delete_text:
      "Cela supprimera aussi les sections liées au chapitre. Continuer ?",
    class_chapter_delete_success: "Chapitre supprimé avec succès !",

    class_chapter_details_set_not_available:
      "Vous n'avez pas encore ajouté de quizz",
    class_chapter_details_set_not_subtitle:
      "Voulez-vous en créer un maintenant ?",
    class_chapter_details_set_search_not_available:
      "Aucun quizz disponible pour les données recherchées",
    class_chapter_details_button_new_own_set: "Créer mon propre quiz",
    class_chapter_details_button_new_set: "Mon Quizz",
    class_chapter_details_button_import: "Importer de la bibliothèque",
    class_chapter_details_th_set_title: "Titre du quizz",
    class_chapter_details_th_createdby: "Créé par",
    class_chapter_details_th_mq_set: "Quizz Kojyto",
    class_chapter_details_th_terms: "Questions",
    class_chapter_details_th_privacy: "Privacy",
    class_chapter_details_text_my_set: "Mon quizz",

    class_chapter_set_add_form_text_create_terms: "Créer des questions",
    class_chapter_set_add_form_text_import: "Importer depuis Excel",
    class_chapter_set_add_form_text_import_terms: "Importer les questions",
    class_chapter_set_add_form_import_validation1:
      "Le format du fichier CSV/xls n'est pas bien formaté, veuillez vous référer au modèle",
    class_chapter_set_add_form_import_validation2:
      "Le fichier CSV/xls est vide, veuillez ajouter des données.",
    class_chapter_set_add_form_import_th_rowno: "1",
    class_chapter_set_add_form_import_th_question: "Question",
    class_chapter_set_add_form_import_th_mode: "Mode",
    class_chapter_set_add_form_import_th_opt1: "Option1",
    class_chapter_set_add_form_import_th_opt2: "Option2",
    class_chapter_set_add_form_import_th_opt3: "Option3",
    class_chapter_set_add_form_import_th_opt4: "Option4",
    class_chapter_set_add_form_import_th_ans: "Réponse",
    class_chapter_set_add_form_import_th_diff: "Difficulté",
    class_chapter_set_add_form_import_th_skill: "Compétence",
    class_chapter_set_add_form_import_th_duration: "Durée",
    class_chapter_set_add_form_import_th_competence: "Compétence",
    class_chapter_set_add_form_import_validation_ans_not_match:
      "La réponse ne correspond pas aux options données",
    class_chapter_set_add_form_import_validation_invalid_mode: "Mode invalide",
    class_chapter_set_add_form_import_validation_invalid_diff:
      "Niveau de difficulté invalide",
    class_chapter_set_add_form_import_validation_invalid_skill:
      "Compétence invalide",
    class_chapter_set_add_form_import_validation_invalid_duration:
      "Entrez la durée en chiffres seulement",
    class_chapter_set_add_form_import_note1:
      "Les modes par défaut sont Q&R ou QCM",
    class_chapter_set_add_form_import_note2:
      "Les difficultés par défaut sont facile, Intermédiaire, Difficile",
    class_chapter_set_add_form_import_note3:
      "Les compétences par défaut sont générales, mémoire",
    class_chapter_set_add_form_import_note4:
      "Les erreurs dans les informations seront ignorées (non créées)",
    class_chapter_set_add_form_import_note5:
      "Pour les questions en mode Q&R, les options sont facultatives",
    class_chapter_set_add_form_import_note6:
      "Pour les questions en mode QCM, l'option 1 et l'option 2 sont obligatoires",
    class_chapter_set_add_form_btn_new_term: "Ajouter une nouvelle question",
    class_chapter_set_add_form_btn_add_mcq: "Ajouter une QCM",
    class_chapter_set_add_form_btn_remove_mcq: "Retirer une QCM",
    class_chapter_set_add_form_text_term: "Q&R",
    class_chapter_set_add_form_text_mcq: "QCM",
    class_chapter_mq_set_add_form_title: "Lancer un quizz",
    class_chapter_set_add_form_title: "Ajouter un nouvel ensemble",
    class_chapter_set_edit_form_title: "Mettre à jour",
    class_chapter_set_form_label_createdfor: "Créé pour",
    class_chapter_set_form_createdfor_option1: "Matière",
    class_chapter_set_form_createdfor_option2: "Générale",
    class_chapter_set_form_label_privacy: "Accès",
    class_chapter_set_form_privacy_option1: "Privé",
    class_chapter_set_form_privacy_option2: "Public",
    class_chapter_set_form_label_level: "Classe",
    class_chapter_set_form_ph_level: "Choisir un niveau de classe",
    class_chapter_set_form_label_title: "Titre du quizz",
    class_chapter_set_form_ph_title: "Entrer le titre d’un quizz",
    class_chapter_set_form_label_term: "Question",
    class_chapter_set_form_ph_term: "Entrer une question",
    class_chapter_set_form_label_definition: "Définition",

    class_chapter_set_form_ph_definition: "Entrez la définition",
    class_chapter_set_form_label_option1: "Option 1",
    class_chapter_set_form_ph_option1: "Entrer option 1",
    class_chapter_set_form_label_option2: "Option 2",
    class_chapter_set_form_ph_option2: "Entrer option 2",
    class_chapter_set_form_label_option3: "Option 3",
    class_chapter_set_form_ph_option3: "Entrer option 3",
    class_chapter_set_form_label_option4: "Option 4",
    class_chapter_set_form_ph_option4: "Entrer option 4",
    class_chapter_set_form_label_difficulty_option1: "Difficile",
    class_chapter_set_form_label_difficulty_option2: "Intermédiaire",
    class_chapter_set_form_label_difficulty_option3: "Facile",
    class_chapter_set_form_label_skill_option1: "Mémoire",
    class_chapter_set_form_label_skill_option2: "Générale",
    class_chapter_set_form_ph_duration: "Entrez la durée (en min)",
    class_chapter_set_form_label_notify_class: "Notifier toute la classe",
    class_chapter_set_form_sublabel_notify_class:
      "Envoyer une invitation aux membres de la classe pour ce quizz",
    class_chapter_set_form_validation_correct_ans_select:
      "Sélectionnez la réponse correcte parmi les propositions",
    class_chapter_set_form_add_success: "Quizz ajouté avec succés !",
    class_chapter_set_form_update_success: "Quizz mis à jour avec succés !",
    class_chapter_set_form_update_note1:
      "L'ajout ou la suppression d'une question peut affecter le résultat déjà obtenu pour cet ensemble",
    class_chapter_set_form_question_delete_title: "Supprimer la question",
    class_chapter_set_form_question_delete_text:
      "Ceci supprimera la question pour les quizz de tous les étudiants. Continuer ?",

    class_chapter_set_import_not_available_mylibrary:
      "Vous n'avez pas encore créé de quizz",
    class_chapter_set_import_not_available_otherslibrary:
      "Aucun quizz n'a été ajouté par un autre membre.",
    class_chapter_set_import_not_available_mqlibrary:
      "Kojyto n'a pas encore ajouté de jeu de questions",
    class_chapter_set_import_title: "Importer un quizz",
    class_chapter_set_import_subtitle: "Importer depuis la bibliothèque",
    class_chapter_set_import_label_import_from: "Importer de",
    class_chapter_set_import_label_import_from_option1: "Mon espace",
    class_chapter_set_import_label_import_from_option2: "Tous les espaces",
    class_chapter_set_import_label_import_from_option3: "La base Kojyto",
    class_chapter_set_import_th_set_title: "Titre du quizz",
    class_chapter_set_import_th_set_class: "Classe-Chapitre",
    class_chapter_set_import_th_set_term: "Question",
    class_chapter_set_import_th_set_privacy: "Accès",
    class_chapter_set_import_text_imported_from: "Quizz importé de",
    class_chapter_set_import_clone_add_success: "Quizz importé avec succès !",

    class_chapter_exam_date_form_title: "Définir une nouvelle date d'examen",
    class_chapter_xam_date_form_label_date: "Choisir une date d’examen",
    class_chapter_xam_date_form_ph_date: "DD/MM/YYYY",
    class_chapter_xam_date_form_add_success:
      "Date du prochain examen enregistrée avec succès !",

    class_chapter_set_details_button_edit: "Modifier le quizz",
    class_chapter_set_details_button_delete: "Supprimer le quizz",
    class_chapter_set_details_qst_view_ans_text: "Voir la réponse",
    class_chapter_set_details_qst_view_qst_text: "Voir la question",
    class_chapter_set_details_qst_button_delete: "Supprimer la question",
    class_chapter_set_delete_title: "Supprimer le quizz",
    class_chapter_set_delete_text:
      "Ceci supprimera toutes les questions liées, les autres données et les résultats des étudiants. Continuer ?",
    class_chapter_set_delete_success: "Quizz supprimé avec succès !",
    class_chapter_set_notify_title: "Notifier tous les élèves de la classe",
    class_chapter_set_notify_text:
      "Souhaitez-vous envoyer un e-mail de notification à propos de cette série à tous les élèves de la classe. Continuer ?",
    class_chapter_set_notify_success:
      "Notification envoyée à tous les élèves avec succès !",
    class_chapter_set_qst_delete_success: "Question supprimée avec succès !",

    class_member_import_title: "Importer un membre",
    class_member_add_form_title: "Ajouter un nouveau membre",
    class_member_edit_form_title: "Mettre à jour les membres",
    class_member_search_not_available:
      "Aucun membre disponible pour les données recherchées",
    class_member_not_available: "Vous n'avez pas encore ajouté de membre",
    class_member_not_subtitle: "Voulez-vous en créer un maintenant ?",
    class_member_new_button: "Nouveau chapitre",
    class_member_th_name: "Nom",
    class_member_th_role: "Role",
    class_member_remove_title: "Retirer un membre",
    class_member_remove_text:
      "L'utilisateur n'aura plus accès à cette classe. Continue ?",

    class_member_remove_success: "Membre supprimé avec succès",
    class_member_make_admin_title: "Nommer Admin",
    class_member_make_admin_text:
      "Nommer cet utilisateur comme administrateur. Continuer ?",
    class_member_make_admin_success: "Nommé admin avec succès !",
    class_member_remove_admin_title: "Retirer le statut Admin",
    class_member_remove_admin_text:
      "Supprimer cet utilisateur de la liste des admin. Continuer ?",
    class_member_remove_admin_success: "Suppression de l'admin réussie !",
    class_member_enroll_success: "Étudiant inscrit avec succès !",
    class_member_text_member_left: "Membres restants",

    class_progress_title_class_details: "Aperçu de la classe",
    class_progress_title_class_activity: "Activités de la classe",
    class_progress_title_chapter_analysis: "Analyse du chapitre",
    class_progress_title_chapter_progress: "Progression dans le chapitre",
    class_progress_text_notify_class: "Informer la classe de passer le quizz",
    class_progress_text_set_attempted: "quizz(s) tenté(s)",
    class_progress_text_set_completed: "quizz(s) terminé(s)",
    class_progress_text_set_initial_completed:
      "quizz(s) initial(aux) terminé(s)",
    class_progress_text_set_aborted: "Quizz(s) abandonné(s)",
    class_progress_text_set_practiced: "Quizz(s) pratiqué(s)",
    class_progress_text_chapter_in_class: "chapitre(s) en classe",
    class_progress_text_set_in_class: "Quizz(s) en classe",
    class_progress_text_more_practiced: "quizz(s) suivi(s) en plus depuis hier",
    class_progress_text_less_practiced:
      "quizz(s) suivi(s) en moins depuis hier",
    class_progress_text_more_completed:
      "quizz(s) achevé(s) en plus depuis hier",
    class_progress_text_less_completed:
      "quizz(s) achevé(s) en moins depuis hier",
    class_progress_text_no_class_activity_for_date:
      "Aucune activité disponible pour la date sélectionnée",
    class_progress_text_practic_private_set: "Suivi un quizz dans le chapitre",
    class_progress_text_practiced_set: "pratiqué",
    class_progress_text_started_set: "a commencé le quizz",
    class_progress_text_completed_set: "a fini le quizz",
    class_progress_text_aborted_set: "quizz interrompu",
    class_progress_text_added_exam: "Examen ajouté dans le chapitre",
    class_progress_text_try_set_again: "Demande de réessayer",
    class_progress_text_remind_for_qize: "Rappeler de passer le quiz",
    class_progress_set_user_reminder_title: "Notifier à l'élève",
    class_progress_set_user_reminder_text:
      "Souhaitez-vous envoyer un e-mail de notification sur ce quizz à cet élève. Continuer ?",
    class_progress_user_remind_send_success:
      "Notification envoyée à l'étudiant avec succès !",

    class_progress_text_popular_chapter: "Chapitre le plus populaire",
    class_progress_sub_text_popular_chapter:
      "Nombre maximum de quizzs terminés en moyenne dans un chapitre",
    class_progress_text_neglected_chapter: "Chapitre le plus négligé",
    class_progress_sub_text_neglected_chapter:
      "Quizzs les moins suivis dans un chapitre en moyenne",
    class_progress_text_practiced_chapter: "Chapitre le plus pratiqué",
    class_progress_sub_text_practiced_chapter:
      "Nombre maximal de quizzs suivis dans un chapitre",
    class_progress_text_max_sets_chapter:
      "Chapitre avec le plus grand nombre de quizzs",
    class_progress_text_min_sets_chapter:
      "Chapitre avec le plus petit nombre de quizzs",

    class_progress_text_progress_rate: "Taux de progression",
    class_progress_text_total_sets: "Nombre de quizzs",
    class_progress_text_last_attempted_set: "Dernière tentative de quizz",
    class_progress_text_last_practic_set: "Dernier quizz pratiqué",
    class_progress_set_text_no_ch_progress: "Aucune progression disponible",

    class_progress_set_text_set_report: "Rapport sur le quizz",
    class_progress_set_text_filter_report: "Filtrer",
    class_progress_set_text_class_progress: "Progression de la classe",
    class_progress_set_text_all_times: "Toute l’année",
    class_progress_set_text_all_chapters: "Tous les chapitres",
    class_progress_set_text_avg_time_on_set: "Temps moyen passé sur le quizz",
    class_progress_set_text_avg_class_score:
      "Moyenne des notes obtenues en classe",
    class_progress_set_text_set_completion_rate:
      "Taux d'achèvement de la classe",
    class_progress_set_text_no_set_available: "Aucun ensemble disponible",
    class_progress_set_text_no_record_available:
      "Aucun enregistrement disponible",
    class_progress_set_th_rank: "Rang",
    class_progress_set_th_student: "Students",
    class_progress_set_th_practice: "Practice",
    class_progress_set_th_attempted: "Commencé",
    class_progress_set_th_completed: "Achevé",
    class_progress_set_th_avg_score: "Score moyen",
    class_progress_set_th_progress: "Progrès",

    // Students
    student_new_button: "Nouvel étudiant",
    student_search_not_available:
      "Aucun étudiant disponible pour les données recherchées",
    student_not_available: "Vous n'avez pas encore créé d'étudiant",
    student_not_available_subtitle: "Voulez-vous en créer un maintenant ?",

    student_text_share_link: "Partager le lien",
    student_text_share_link_text:
      "Vous pouvez partager ce lien directement à votre étudiant pour qu'il s'inscrive",
    student_th_name: "Nom",
    student_th_email: "Email",
    student_th_class: "Classe par défaut",
    student_add_form_title: "Ajouter un nouvel élève",
    student_edit_form_title: "Mettre à jour",
    student_form_tab_title1: "Ajouter un élève",
    student_form_tab_title1_enroll: "Ajouter un membre",
    student_form_tab_title2: "Inviter un élève",
    student_form_tab_title2_enroll: "Inviter un membre",
    student_form_tab_title3: "Ajouter un membrer",
    student_form_label_fname: "Prénom",
    student_form_ph_fname: "Entrer le prénom",
    student_form_label_lname: "Nom de famille",
    student_form_ph_lname: "Entrer le nom de famille",
    student_form_label_uname: "Afficher le nom",
    student_form_ph_uname: "Entrer un nom d'utilisateur",
    student_form_ph_uname: "Entrer un nom d'utilisateur",
    student_form_label_dob: "Date de naissance",
    student_form_ph_dob: "DD/MM/YYYY",
    student_form_label_parent_email: "Email",
    student_form_ph_parent_email: "Entrer un email",
    student_form_label_usertype: "Type",
    student_form_label_usertype_option1: "Elève",
    student_form_label_usertype_option2: "Enseignant",
    student_form_label_class: "Classe par défaut",
    student_form_ph_class: "Choisir une classe par défaut",
    student_form_label_enroll_member: "Ajouter un membre",
    student_form_ph_enroll_member: "Sélectionner les utilisateurs à inscrire",
    student_form_notes_csv_emails:
      "Entrez une ou plusieurs adresses e-mail valides des utilisateurs que vous souhaitez inviter, séparées par une virgule [,]",
    student_form_add_success: "Étudiant ajouté avec succès !",
    student_form_invitation_success: "Invitation envoyée avec succès !",
    student_form_invitation_error_existing_emails:
      "Ces e-mails existent déjà, vous pouvez utiliser la fonction d'inscription pour inscrire les e-mails existants",
    student_form_update_success: "L'étudiant a été mis à jour avec succès !",

    student_import_title: "Importer des étudiants",
    student_import_text_sample_csv: "Exemple de fichier CSV",
    student_import_text_sample_xls: "Exemple de fichier XLS",
    student_import_text_doenload_template: "Modèle de téléchargement",
    student_import_th_rowno: "1",
    student_import_th_fname: "Prénom",
    student_import_th_lname: "Nom de famille",
    student_import_th_uname: "Nom d’utilisateur",
    student_import_th_email: "Email",
    student_import_th_dob: "Dob",
    student_import_note1: "Les données en double seront supprimées",
    student_import_note2:
      "Les erreurs dans les informations seront ignorées (non créées)",
    student_import_note3: "Format de la date de naissance : YYYY-MM-DD",
    student_users_left: "users left",
    student_import_validation1:
      "Le format du fichier CSV/xls n'est pas bien formaté, veuillez vous référer au modèle",
    student_import_validation2:
      "Le fichier CSV/xls est vide, veuillez ajouter des données.",
    student_import_validation3:
      "Le fichier CSV/xls contient une ou plusieurs entrées en double.",
    student_import_success: "L'utilisateur a été importé avec succès !",
    student_import_error: "L'importation a échoué, réessayez !",

    student_details_tab_title1: "Programmes inscrits",
    student_details_tab_title2: "Quizzs suivis",
    student_details_tab_title3: "Vidéos visionnées",
    student_details_tab_title4: "Progrès",

    student_class_not_available: "Vous n'êtes pas encore inscrit à une classe",
    student_class_not_subtitle:
      "Contactez votre professeur pour vous inscrire en classe",
    student_class_th_name: "Programme - Chapitre",
    student_class_th_qst_set: "Quizz",
    student_class_th_mq_set: "Quizz Kojyto",
    student_class_th_progress: "Progrès dans la matière",

    student_quiz_not_available: "Vous n'avez encore tenté aucun quiz",
    student_quiz_not_subtitle: "Lancer un quiz pour obtenir la liste ici.",
    student_quiz_th_set: "Titre du quizz",
    student_quiz_th_createdby: "Créé par",
    student_quiz_th_type: "Type",
    student_quiz_th_takenon: "Suivi le",
    student_quiz_th_progress: "Progrès",
    student_quiz_text_terms_checked: "Termes vérifiés",

    student_video_not_available: "Vous n'avez pas encore regardé de vidéo",
    student_video_not_subtitle: "Regarder une vidéo pour obtenir la liste ici.",
    student_video_th_title: "Titre de la vidéo",
    student_video_th_duration: "Durée",
    student_video_th_watched: "Visionné le",

    student_progress_title_student_details: "Progrès de l'élève",
    student_progress_title_class_activity: "Activités de l'élève",
    student_progress_text_set_attempted: "quizz(s) tenté(s)",
    student_progress_text_set_completed: "quizz(s) complété(s)",
    student_progress_text_set_aborted: "quizz(s) abandonné(s)",
    student_progress_text_set_practiced: "quizz(s) pratiqué(s)",
    student_progress_text_set_member_class: "quizz(s) du membre de la classe",
    student_progress_text_more_practiced: "quizzs suivis en plus depuis hier",
    student_progress_text_less_practiced: "quizzs en moins depuis hier",
    student_progress_text_more_completed:
      "quizzs achevés en plus par rapport à hier",
    student_progress_text_less_completed:
      "quizzs achevés en moins par rapport à hier",
    student_progress_text_no_class_activity_for_date:
      "Aucune activité disponible pour la date sélectionnée",
    student_progress_text_practic_private_set:
      "A pratiqué un quizz privé dans le chapitre",
    student_progress_text_practiced_set: "Pratiqué",
    student_progress_text_started_set: "A commencé le quiz",
    student_progress_text_completed_set: "Quiz terminé",
    student_progress_text_initial_completed_set: "Quiz initial terminé",
    student_progress_text_aborted_set: "Interrogation interrompue",
    student_progress_text_added_exam: "Examen ajouté dans le chapitre",

    student_progress_program_text_program_report: "Rapport d'avancement",
    student_progress_program_text_filter_report: "Filtrer",
    student_progress_program_text_program_enroll: "Matière inscrite",
    student_progress_program_text_avg_time_on_class:
      "Temps moyen passé en classe",
    student_progress_program_text_avg_score: "Note moyenne",
    student_progress_program_text_class_completion_rate:
      "Taux d'achèvement de la classe",
    student_progress_program_text_no_program_available:
      "Aucun programme disponible",
    student_progress_program_text_no_record_available:
      "Aucun enregistrement disponible",
    student_progress_program_th_set: "Quizzs",
    student_progress_program_th_practice: "Réviser",
    student_progress_program_th_attempted: "Tentative",
    student_progress_program_th_completed: "Terminé",
    student_progress_program_th_avg_score: "Score moyen",
    student_progress_program_th_progress: "Progrès",

    // Myspace
    myspace_search_not_available:
      "Vous ne vous êtes pas inscrit aux données recherchées",
    myspace_not_available: "Vous n'êtes pas encore inscrit à une classe",
    myspace_not_available: "Vous n'êtes pas encore inscrit à une classe",
    myspace_not_available_subtitle:
      "Contactez votre professeur pour vous inscrire à une classe",

    myspace_chapter_not_available: "Aucun chapitre ajouté par le professeur.",
    myspace_chapter_not_subtitle:
      "Contactez votre professeur pour ajouter un nouveau chapitre.",

    myspace_member_not_available: "Aucun membre ajouté par le professeur.",
    myspace_member_not_subtitle:
      "Contactez votre professeur pour ajouter un nouveau membre",

    myspace_upcoming_exam_title: "Examens à venir",
    myspace_upcoming_exam_text_manage_exam: "Gérer l'examen",
    myspace_upcoming_exam_delete_confirmation_title:
      "Supprimer la date de l'examen",
    myspace_upcoming_exam_delete_confirmation_text:
      "Souhaitez-vous supprimer la date de l'examen ?",
    myspace_upcoming_exam_delete_success: "Examen supprimé avec succès !",

    // Cognitive videos
    videos_not_search_text: "Rechercher une vidéo",
    videos_not_available:
      "Aucune vidéo n'a encore été téléchargée pour l'étudiant",
    videos_not_available_subtitle:
      "Contactez votre professeur pour obtenir la vidéo",
    videos_search_not_available:
      "Aucune vidéo disponible pour les données recherchées",
    videos_th_name: "Titre de la vidéo",
    videos_th_duration: "Durée",

    // Question sets
    sets_search_not_available:
      "Aucun ensemble de questions disponible pour les données recherchées.",
    sets_not_available: "Aucun ensemble de questions n'a encore été créé.",
    sets_not_available_subtitle: "Voulez-vous en créer un maintenant ?",
    sets_quiztaken_not_available: "Vous n'avez encore tenté aucun quiz",
    sets_quiztaken_not_available_subtitle:
      "Tentez un quiz pour obtenir la liste ici.",
    sets_general_not_available: "Aucun quizz ajouté par d'autres membres.",
    sets_general_not_available_subtitle:
      "Veuillez patienter jusqu'à ce qu'ils ajoutent un quizz.",
    sets_global_not_available: "Aucun quizz public ajouté par les enseignants",
    sets_global_not_available_subtitle:
      "Veuillez attendre jusqu'à ce qu'ils ajoutent un quizz",
    sets_button_new_net: "Ajouter un quizz",
    sets_tabs_tab1: "Ma table",
    sets_tabs_tab2: "Quizzes suivis",
    sets_tabs_tab3: "Bibliothèque générale",
    sets_tabs_tab4: "Autres bibliothèques",
    sets_th_name: "Titre du quizz",
    sets_th_class: "Classe",
    sets_th_chapter: "Chapitre",
    sets_th_createdby: "Créé par",
    sets_th_takenon: "Suivi le",
    sets_th_mq_set: "Quizz Kojyto",
    sets_th_terms: "Questions",
    sets_th_privacy: "Accès",
    sets_th_action_option1: "Révision",
    sets_th_action_option2: "Evaluation",
    sets_form_ph_chapter: "Sélectionnez un chapitre",
    sets_add_form_title: "Ajouter un nouveau quizz",
    sets_edit_form_title: "Mettre à jour le quizz",
    sets_form_label_class: "Class",
    sets_form_ph_class: "Sélectionner la classe",
    sets_form_label_chapter: "Chapitre",

    setsattempt_text_your_ans: "Votre réponse est",
    setsattempt_text_completed_quiz: "Vous avez terminé votre quizz",
    setsattempt_text_practice_quiz: "Vous avez terminé votre quizz",
    setsattempt_text_goto_dashboard: "Aller au tableau de bord",
    setsattempt_text_correct_ans: "Bonne réponse",
    setsattempt_text_earned_point: "Nombre de points gagnés",
    setsattempt_text_quiz_result: "Résultat du quiz",
    setsattempt_finish_quiz_title: "Terminer le quizz",
    setsattempt_finish_quiz_text: "Avez-vous terminé votre quiz?",
    setsattempt_abort_quiz_confirm_title: "Interrompre le Quiz",
    setsattempt_abort_quiz_confirm_text:
      "Si vous abandonnez le quiz, vous n'obtiendrez aucun point. Continuer ?",
    setsattempt_congrats_text_practice_quiz_completed:
      "Bravo! Grâce à ce quizz de révision, ta mémoire et tes connaissances se renforcent!",

    // Rapports
    report_text_all_classes: "Toutes les classes",
    report_tabs_tab1: "Etudiants",
    report_tabs_tab2: "Chapitres",
    report_tabs_tab3: "Séries de questions",
    report_text_program: "Programme",
    report_text_chapter: "Chapitre",
    report_text_std_attm_chart: "Graphique des résultats de l'élève",
    report_text_ch_attm_chart: "Diagramme des résultats par chapitre",
    report_text_sets_attm_chart: "Graphique des ensembles tentés",
    report_text_top_correct_ans_title: "Top 50 des bonnes réponses",
    report_text_top_incorrect_ans_title:
      "Top 50 des questions à réponses incorrectes",
    report_text_top_correct_ans_no_list:
      "Aucune liste n'est disponible pour lister ici",
    report_text_top_incorrect_ans_no_list:
      "Aucune liste n'est disponible pour lister ici",

    // Messages
    message_not_search_text: "Rechercher un élève ou une classe",
    message_thread_not_available: "Pas de conversation",
    message_thread_not_available_subtitle: "Démarrer une conversation",
    message_not_available: "Aucune Conversation",
    message_not_available_subtitle:
      "Sélectionnez un contact pour commencer une conversation",
    message_thread_search_teacher: "Rechercher un étudiant ou une classe",
    message_thread_search_student: "Rechercher un professeur ou une classe",
    message_text_send_message: "Envoyez votre message...",
    message_text_search_message: "Rechercher un message",
    message_text_not_allowed_to_sent_message:
      "Désolé !!! Vous n'êtes pas autorisé à envoyer un message dans ce fil de discussion",

    // Settings
    settings_text_your_plan: "Votre abonnement",
    settings_text_plans: "Abonnements",
    settings_text_plans_success: "Succès",
    settings_text_plans_failed: "Échec",
    settings_text_invoices: "Factures",
    settings_text_user_default_class: "Ma classe",
    settings_text_user_default_class_subtext:
      "Sélectionnez votre classe par défaut",
    settings_text_user_default_level: "Ma classe",
    settings_text_user_default_level_subtext: "Sélectionnez votre classe",
    settings_text_user_default_level_update_confirm_title:
      "Mettre à jour le niveau de la classe",
    settings_text_user_default_level_update_confirm_subtext:
      "En mettant à jour le niveau de la classe, cela changera toutes vos données associées éventuellement avec un professeur. Continuer ?",
    settings_text_contact_details: "Détails du contact",
    settings_text_contact_details_subtext: "Mettez à jour vos coordonnées",
    settings_text_update_password: "Mettre à jour le mot de passe",
    settings_text_update_password_subtext: "Mettre à jour votre mot de passe",

    profile_setting_text_personal_info: "Information personnelle",
    profile_setting_form_label_profile: "Image du profil",
    profile_setting_form_label_email: "Email",
    profile_setting_form_label_fname: "Prénom",
    profile_setting_form_ph_fname: "Entrer votre prénom",
    profile_setting_form_label_lname: "Nom de famille",
    profile_setting_form_ph_lname: "Entrez le nom de famille",
    profile_setting_form_label_uname: "Nom d’utilisateur",
    profile_setting_form_ph_uname: "Entrer nom d’utilisateur",
    profile_setting_form_label_dob: "Date de naissance",
    profile_setting_form_ph_dob: "DD/MM/YYYY",
    profile_setting_form_update_success: "Profil mis à jour avec succès !",

    profile_setting_facebook_disconnect_confirmation_text:
      "Voulez-vous vraiment déconnecter votre connexion facebook ?",
    profile_setting_facebook_disconnect_success:
      "Connexion Facebook supprimée avec succès !",
    profile_setting_facebook_connect_success:
      "Connexion Facebook ajoutée avec succès !",
    profile_setting_facebook_connect_error_email_not_matched:
      "Connexion Facebook non ajoutée car l'adresse électronique de connexion et l'adresse électronique de Facebook ne sont pas les mêmes !",

    profile_setting_twitter_disconnect_confirmation_text:
      "Voulez-vous vraiment déconnecter votre connexion Twitter ?",
    profile_setting_twitter_disconnect_success:
      "Connexion Twitter supprimée avec succès !",
    profile_setting_twitter_connect_success:
      "Connexion Twitter ajoutée avec succès !",
    profile_setting_twitter_connect_error_email_not_matched:
      "La connexion Twitter n'a pas été ajoutée car l'adresse électronique de connexion et l'adresse électronique Twitter ne sont pas les mêmes !",
    profile_setting_google_disconnect_confirmation_text:
      "Voulez-vous vraiment déconnecter votre connexion google ?",
    profile_setting_google_disconnect_success:
      "Connexion Google supprimée avec succès !",
    profile_setting_google_connect_success:
      "Connexion Google ajoutée avec succès !",
    profile_setting_google_connect_error_email_not_matched:
      "La connexion Google n'a pas été ajoutée car l'adresse électronique de connexion et l'adresse électronique de Google ne sont pas les mêmes !",
    profile_setting_social_link_disconnect_confirmation_text:
      "Voulez-vous vraiment déconnecter votre réseau social ?",
    profile_setting_social_link_disconnect_success:
      "Lien supprimé avec succès !",

    password_setting_form_label_old_password: "Ancien mot de passe",
    password_setting_form_ph_old_password: "Entrez l’ancien mot de passe",
    password_setting_form_label_new_password: "Nouveau mot de passe",
    password_setting_form_ph_new_password: "Entrez un nouveau mot de passe",
    password_setting_form_label_repeat_password: "Répéter le mot de passe",
    password_setting_form_ph_repeat_password: "Répéter le nouveau mot de passe",
    password_setting_form_validation_min_password:
      "Veuillez entrer un mot de passe d'au moins 6 caractères !",
    password_setting_form_validation_repeat_password_required:
      "Veuillez répéter le nouveau mot de passe !",
    password_setting_form_validation_repeat_old_password_not_match:
      "Le mot de passe ne correspond pas au nouveau mot de passe !",
    password_setting_form_old_password_error:
      "Le mot de passe fourni ne correspond pas.",
    password_setting_form_success:
      "Le mot de passe a été modifié avec succès !",

    subscription_title: "Plans pour l'abonnement",
    subscription_suptitle: "Choisissez une formule d’abonnement",
    subscription_text_annual_discount:
      "Économisez avec la facturation annuelle",
    subscription_purchase_offer_1:
      "Accès à tous les contenus, quizzs intelligents, algorithme de gestion des révisions.",
    subscription_purchase_offer_2:
      "Accès au tableau de bord et à la messagerie interne pour communiquer avec les enseignants.",
    subscription_purchase_offer_3:
      "Vidéos de sensibilisation aux facteurs clefs du sommeil et de la concentration.",
    subscription_purchase_suitable: "Convient pour",
    subscription_purchase_subjects: "Matières",
    subscription_purchase_subjects_1: "Maths",
    subscription_purchase_subjects_2: "Anglais",
    subscription_purchase_subjects_3: "Sciences",
    subscription_purchase_moke: "Examens blancs",
    subscription_purchase_moke_1: "Examens blancs personnalisés illimités",
    subscription_purchase_moke_2:
      "Programme personnalisé pour les examens à venir",
    subscription_purchase_moke_3: "Tests adaptatifs",
    subscription_not_available:
      "Vous n'avez pas de plan actif, souscrivez votre nouveau plan",
    subscription_success_page_message:
      "Merci pour votre abonnement, vous pouvez maintenant continuer à accéder aux fonctionnalités MQ",
    subscription_failed_page_message:
      "Désolé..... Votre abonnement a échoué, réessayez",
    subscription_cancel_success: "Votre abonnement a été annulé avec succès !",
    subscription_changed_success:
      "Votre abonnement a été mis à jour avec succès !",

    invoice_th_invoice_no: "Numéro de facture",
    invoice_th_period: "Période",
    invoice_th_action: "Action",
  },
  en: {
    // Menu
    dashboard: "Dashboard",
    classes: "Classes",
    students: "Students",
    myspace: "My Space",
    videos: "Cognitive Sc Videos",
    questionsets: "Question Sets",
    reports: "Reports",
    message: "Messages",
    settings: "Settings",
    logout: "Logout",

    // Common
    common_fail_message: "Something went wrong!",
    common_plan_expired:
      "Your subscription is expired, Renew you subscription!",
    common_trial_expired: "Your trial period is expired, Subscribe a plan!",
    welcome_back: "Welcome back",
    search: "Search",
    copy: "Copy",
    copied: "Copied",
    members: "Members",
    admin: "Admin",
    guest: "Guest",
    owner: "Owner",
    you: "You",
    invalid: "Invalid",
    not_assigned: "Not Assigned",
    notes: "Notes",
    term: "Term",
    by: "By",
    general: "General",
    today: "Today",
    sets: "Sets",
    times_practiced: "times practiced",
    quiz: "Quiz",
    minutes: "minutes",
    program: "Program",
    congratulate: "Congratulate",
    expires: "Expires",
    practice: "Practice",
    practice1: "Practice",
    assessment: "Assessment",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    this_week: "This Week",
    this_month: "This Month",
    good_morning: "Good Morning",
    age: "age",
    courses: "courses",
    continue: "continue",
    points: "points",
    class: "class",
    student: "student",
    month: "month",
    year: "year",
    years: "years",
    trial: "trial",
    google: "google",
    facebook: "facebook",
    twitter: "twitter",
    connect: "Connect",
    disconnect: "Disconnect",
    download_template: "Download template",
    teacher: "Teacher",
    exam_on: "Exam on",
    groups: "groups",
    step: "step",
    correct_answers: "Correct Answers",
    incorrect_answers: "Incorrect Answers",
    time: "time",
    times: "times",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    su: "Su",
    mo: "Mo",
    tu: "Tu",
    we: "We",
    th: "Th",
    fr: "Fr",
    sa: "Sa",

    // Common Validations
    required: "Required",
    invalid_email: "Invalid email",
    invalid_phone: "Invalid phone",
    invalid_date: "Invalid date",
    invalid_url: "Invalid url",
    invalid_fb_url: "Invalid facebook url",
    invalid_linkedin_url: "Invalid linkedin url",
    invalid_twitter_url: "Invalid twitter url",
    required_terms_condition: "Check the terms & conditions.!",
    invalid_profile_image_size:
      "Image is smaller than recommended image, upload another image.",
    csv_file_not_parsed: "Could not parse file.",

    // Buttons
    button_login: "Sign In",
    button_send: "Send",
    button_reset_password: "Reset Password",
    button_signup: "Sign Up",
    button_logout: "Logout",
    button_create: "Create",
    button_save: "Save",
    button_close: "Close",
    button_cancel: "Cancel",
    button_edit: "Edit",
    button_delete: "Delete",
    button_back: "Back",
    button_yes: "Yes",
    button_no: "No",
    button_previous: "Previous",
    button_next: "Next",
    button_finish: "Finish",
    button_remove: "Remove",
    button_download: "Download",
    button_import: "Import",
    button_send_invite: "Send Invite",
    button_enroll_member: "Enrol Member",
    button_options: "Options",
    button_crop: "Crop",
    button_make_admin: "Make Admin",
    button_remove_admin: "Remove Admin",
    button_edit_profile: "Edit Profile",
    button_assessment: "Assessment",
    button_practice: "Practice",
    button_invite: "Invite",
    button_notify_class: "Notify Class",
    button_end_quiz: "End Quiz",
    button_skip: "Skip",
    button_correct: "Correct",
    button_incorrect: "Incorrect",
    button_retake_quiz: "Retake Quiz",
    button_change_plan: "Change Plan",
    button_invoices: "Invoices",
    button_update: "Update",
    button_invite_set: "Invite Set",
    button_new_quiz: "New Quiz",
    button_view_quiz: "View Quiz",
    button_view_all: "View All",
    button_report: "Report",
    button_practice_quiz: "Practice Quiz",
    button_take_quiz: "Take Quiz",
    button_buy: "Buy",
    button_pay: "Pay",
    button_go_to_plan: "Go to Plan",
    button_cancel_subscription: "Cancel Subscription",

    // Login & Signup
    facebook_signin: "Sign in with Facebook",
    twitter_signin: "Sign in with Twitter",
    google_signin: "Sign in with Google",

    login_common_text_or_login_with: "Or continue with",

    login_title_login: "Sign in to your account",
    login_form_label_email: "Email",
    login_form_ph_email: "Enter your email address",
    login_form_label_password: "Password",
    login_form_ph_password: "Enter your password",
    login_form_label_rememberme: "Remember me",
    login_text_forgot_password: "Forgot your password",
    login_text_dont_have_account: "Don't have an account?",
    login_text_go_to_login: "Go to",
    login_error_text_email_send_success: "Email send successfully!",
    login_error_text_invalid_email_password: "Invalid email or password!",
    login_error_text_social_account_not_connected:
      "Sorry... You are not connected with us, Create an account first!",
    logout_confirmation_title: "Logout",
    logout_confirmation_text: "Do you really want to logout?",

    login_forgot_pass_title_login: "Forgot Password",
    login_reset_pass_title_login: "Reset Password",
    login_reset_pass_form_label_email: "Email",
    login_reset_pass_form_ph_email: "Enter your email address",
    login_reset_pass_form_label_code: "Confirmation Code",
    login_reset_pass_form_ph_code: "Enter confirmation code",
    login_reset_pass_form_label_password: "Password",
    login_reset_pass_form_ph_password: "Enter new password",
    login_reset_pass_form_label_confirm_pass: "Confirm Password",
    login_reset_pass_form_ph_confirm_pass: "Repeat new password",
    login_reset_pass_form_error_text_invalid_credential: "Invalid credential!",

    signup_title_signup: "Create a new account",
    signup_form_label_usertype: "I'm a",
    signup_form_usertype_option1: "Teacher",
    signup_form_usertype_option2: "Student",
    signup_form_label_dob: "Date of Birth",
    signup_form_ph_dob: "Select your dob",
    signup_form_label_email: "Email Address",
    signup_form_ph_email: "Enter your email address",
    signup_form_label_password: "Password",
    signup_form_ph_password: "Enter your password",
    signup_form_validation_min_password: "Please enter minimum 6 char",
    signup_error_registration_failed: "Your registration failed, try again!",
    signup_error_check_terms_conditions: "Please check the terms & conditions!",
    signup_text_already_an_account: "Already have an account?",

    signup_user_info_screen_title1:
      "Welcome, let's start with some quick information about you.",
    signup_user_info_screen_title2: "Now let's create the first class of",
    signup_user_form_label_username: "What do your student call you?",
    signup_user_form_ph_fname: "Enter your first name",
    signup_user_form_ph_lname: "Enter your last name",
    signup_user_stu_form_label_username: "What do your teacher call you?",
    signup_user_stu_form_ph_username: "Enter your display name",
    signup_user_teach_form_label_programname: "Enter Program",
    signup_user_teach_form_ph_programname: "Add program name",
    signup_user_form_success: "Details saved successfully!",
    signup_user_button_logout: "I'll save later. Logout",

    // Profile & Settings
    ps_text_account_settings: "Account settings",
    ps_text_signedin: "Signed in as",

    // Dashboard
    dashboard_text_quiz_taken: "Quiz Taken",
    dashboard_text_video_watched: "Videos Watched",
    dashboard_text_total_classes: "Total Classes",
    dashboard_text_total_members: "Total Members",
    dashboard_text_quiz_invitation: "Quiz Invitation",
    dashboard_text_no_quiz_invitation_sent:
      "No question invitation is available",
    dashboard_text_upcoming_exams: "Upcoming Exams",
    dashboard_text_no_upcoming_exams:
      "No upcoming exam schedule is added by you",
    dashboard_th_chapter_name: "Chapter Name",
    dashboard_th_deadline: "Deadline",
    dashboard_th_exam_date: "Exam Date",
    dashboard_text_quiz_overview: "Quiz Overview",
    dashboard_text_quiz_mark_scored: "Mark obtained",
    dashboard_text_my_activity: "My Activity",
    dashboard_text_no_activity: "No activity available to list here",
    dashboard_text_last_quiz_taken: "Last quiz taken",
    dashboard_text_top_program: "Top program",
    dashboard_text_least_program: "Least taken program",
    dashboard_th_quiz_name: "Quiz Name",
    dashboard_th_program: "Program",
    dashboard_th_student: "Student",
    dashboard_th_invited_on: "Invited On",
    dashboard_th_quiz_taken: "Quiz Taken",
    dashboard_th_leaderboard: "Leaderboard",
    dashboard_th_user_name: "Name",
    dashboard_th_points: "Points",
    dashboard_th_trophy: "Trophies",
    dashboard_th_badges: "Badegs",
    dashboard_th_reward: "Reward",
    dashboard_th_date: "Date",
    dashboard_th_gold_medal: "Gold Medals",
    dashboard_th_silver_medal: "Silver Medals",
    dashboard_th_rank: "Rank",
    dashboard_th_global_rank: "Global Rank",
    dashboard_th_quiz_teacher: "Teacher",
    dashboard_text_no_leaderboard_available:
      "No data available to show in leaderboard",
    dashboard_text_no_course_available:
      "You have not enrolled in any program yet.",
    dashboard_label_program: "Select program",
    dashboard_ph_program: "Select your program",
    dashboard_label_chapter: "Select chapter",
    dashboard_ph_chapter: "Select your chapter",
    dashboard_label_set: "Select set",
    dashboard_ph_set: "Select your set",
    dashboard_label_student: "Select student",
    dashboard_ph_student: "Select your student",
    dashboard_invitation_to_student_for_quiz_success:
      "Invitation send successfully!",
    dashboard_invitation_to_student_for_quiz_error_no_student:
      "No student is enrolled in this class!",
    dashboard_text_all_reward_list: "All reward list",
    dashboard_text_no_award_available: "No award is given to you!",

    // Classes
    class_new_button: "New Class",
    class_search_not_available: "No class available for searched data",
    class_not_available: "You have not created any class yet.",
    class_not_available_subtitle: "Would you like to create one now?",
    class_text_chapters_completed: "chapters completed",
    class_text_invitations_sent: "invitations sent",
    class_add_form_title: "Create New Class",
    class_edit_form_title: "Update Class",
    class_form_label_image: "Program Image",
    class_form_label_level: "Level",
    class_form_ph_level: "Select class level",
    class_form_label_classname: "Class Name",
    class_form_ph_classname: "Enter class name",
    class_form_label_programname: "Select or Enter Program",
    class_form_ph_programname: "Select or add program name",
    class_form_label_description: "Class Description (Optional)",
    class_form_ph_description: "Write class description",
    class_form_label_setquestion: "Question Set Permission",
    class_form_sublabel_setquestion:
      "Allow class members to add or remove own question set.",
    class_form_label_invitemember: "Invite Members",
    class_form_sublabel_invitemember:
      "Allow class members to invite new members.",
    class_form_error_invalid_level: "Invalid level",
    class_form_error_duplicate: "Duplicate class and program name!",
    class_form_add_success: "Class added successfully!",
    class_form_update_success: "Class updated successfully!",

    class_details_button_new_chapter: "New Chapter",
    class_details_button_import_member: "Import Member",
    class_details_button_create_member: "New Member",
    class_details_tab_title1: "Chapters",
    class_details_tab_title2: "Mes élèves",
    class_details_tab_title3: "Progress",
    class_details_tab_title4: "Class Settings",
    class_details_text_teacher_name: "Teacher Name",
    class_details_text_upcoming_exam: "Upcoming Exams",

    class_chapter_search_not_available:
      "No chapter available for searched data",
    class_chapter_not_available: "You have not added any chapter yet.",
    class_chapter_not_subtitle: "Would you like to create one now?",
    class_chapter_text_first_set: "Launch New Quiz",
    class_chapter_text_new_set: "Generate New Quiz",
    class_chapter_text_existing_set: "Reprendre le quiz précédent",
    class_chapter_th_name: "Program - Chapter",
    class_chapter_th_qst_set: "Question Sets",
    class_chapter_th_mq_set: "Mq Set",
    class_chapter_form_title: "Add New Chapter",
    class_chapter_form_label_chaptername: "Chapter Name",
    class_chapter_form_ph_chaptername: "Enter chapter name",
    class_chapter_form_label_image: "Chapter Image",
    class_chapter_form_duplicate_error: "Chapter already exist!",
    class_chapter_form_add_success: "Chapter added successfully!",
    class_chapter_form_update_success: "Chapter updated successfully!",
    class_chapter_delete_title: "Delete Chapter",
    class_chapter_delete_text:
      "This will delete chapter related sections too. Continue?",
    class_chapter_delete_success: "Chapter deleted successfully!",

    class_chapter_details_set_not_available:
      "You have not added any question set yet.",
    class_chapter_details_set_not_subtitle: "Would you like to create one now?",
    class_chapter_details_set_search_not_available:
      "No set available for searched data.",
    class_chapter_details_button_new_own_set: "Create My Own Quiz",
    class_chapter_details_button_new_set: "New Set",
    class_chapter_details_button_import: "Import from Library",
    class_chapter_details_th_set_title: "Question Set Title",
    class_chapter_details_th_createdby: "Created By",
    class_chapter_details_th_mq_set: "Mq Set",
    class_chapter_details_th_terms: "Terms",
    class_chapter_details_th_privacy: "Privacy",
    class_chapter_details_text_my_set: "My set",

    class_chapter_set_add_form_text_create_terms: "Create Terms",
    class_chapter_set_add_form_text_import: "Import From Excel",
    class_chapter_set_add_form_text_import_terms: "Import Terms",
    class_chapter_set_add_form_import_validation1:
      "The CSV/xls file format is not well formatted please refer to the template.",
    class_chapter_set_add_form_import_validation2:
      "The CSV/xls file is empty, Please add some data.",
    class_chapter_set_add_form_import_th_rowno: "1",
    class_chapter_set_add_form_import_th_question: "Question",
    class_chapter_set_add_form_import_th_mode: "Mode",
    class_chapter_set_add_form_import_th_opt1: "Option1",
    class_chapter_set_add_form_import_th_opt2: "Option2",
    class_chapter_set_add_form_import_th_opt3: "Option3",
    class_chapter_set_add_form_import_th_opt4: "Option4",
    class_chapter_set_add_form_import_th_ans: "Answer",
    class_chapter_set_add_form_import_th_diff: "Difficulty",
    class_chapter_set_add_form_import_th_skill: "Skill",
    class_chapter_set_add_form_import_th_duration: "Duration",
    class_chapter_set_add_form_import_th_competence: "Competence",
    class_chapter_set_add_form_import_validation_ans_not_match:
      "Answer is not matched with given options",
    class_chapter_set_add_form_import_validation_invalid_mode: "Invalid mode",
    class_chapter_set_add_form_import_validation_invalid_diff:
      "Invalid difficulty",
    class_chapter_set_add_form_import_validation_invalid_skill: "Invalid skill",
    class_chapter_set_add_form_import_validation_invalid_duration:
      "Enter duration in number only",
    class_chapter_set_add_form_import_note1: "Default modes are term, mcq",
    class_chapter_set_add_form_import_note2:
      "Default difficulties are low, medium, high",
    class_chapter_set_add_form_import_note3:
      "Default skills are general, memory",
    class_chapter_set_add_form_import_note4:
      "Errors in any information will be ignored (not created)",
    class_chapter_set_add_form_import_note5:
      "For term mode questions options are optional",
    class_chapter_set_add_form_import_note6:
      "For mcq mode questions option1 and option2 is required",
    class_chapter_set_add_form_btn_new_term: "Add New Term",
    class_chapter_set_add_form_btn_add_mcq: "Add MCQ",
    class_chapter_set_add_form_btn_remove_mcq: "Remove MCQ",
    class_chapter_set_add_form_text_term: "Term",
    class_chapter_set_add_form_text_mcq: "Multiple choice",
    class_chapter_mq_set_add_form_title: "Launch New Set",
    class_chapter_set_add_form_title: "Add New Set",
    class_chapter_set_edit_form_title: "Update Set",
    class_chapter_set_form_label_createdfor: "Created for",
    class_chapter_set_form_createdfor_option1: "Program",
    class_chapter_set_form_createdfor_option2: "General",
    class_chapter_set_form_label_privacy: "Privacy",
    class_chapter_set_form_privacy_option1: "Private",
    class_chapter_set_form_privacy_option2: "Global",
    class_chapter_set_form_label_level: "Level",
    class_chapter_set_form_ph_level: "Select levels",
    class_chapter_set_form_label_title: "Set Title",
    class_chapter_set_form_ph_title: "Enter set title",
    class_chapter_set_form_label_term: "Term",
    class_chapter_set_form_ph_term: "Enter term",
    class_chapter_set_form_label_definition: "Definition",
    class_chapter_set_form_ph_definition: "Enter definition",
    class_chapter_set_form_label_option1: "Option 1",
    class_chapter_set_form_ph_option1: "Enter option 1",
    class_chapter_set_form_label_option2: "Option 2",
    class_chapter_set_form_ph_option2: "Enter option 2",
    class_chapter_set_form_label_option3: "Option 3",
    class_chapter_set_form_ph_option3: "Enter option 3",
    class_chapter_set_form_label_option4: "Option 4",
    class_chapter_set_form_ph_option4: "Enter option 4",
    class_chapter_set_form_label_difficulty_option1: "High",
    class_chapter_set_form_label_difficulty_option2: "Medium",
    class_chapter_set_form_label_difficulty_option3: "Low",
    class_chapter_set_form_label_skill_option1: "Memory",
    class_chapter_set_form_label_skill_option2: "General",
    class_chapter_set_form_ph_duration: "Enter duration (in min)",
    class_chapter_set_form_label_notify_class: "Notify Class",
    class_chapter_set_form_sublabel_notify_class:
      "Notify class members about the quiz.",
    class_chapter_set_form_validation_correct_ans_select:
      "Select the correct answer button from options",
    class_chapter_set_form_add_success: "Question set added successfully!",
    class_chapter_set_form_update_success: "Question set updated successfully!",
    class_chapter_set_form_update_note1:
      "Add or delete of any question may effect the already attempted result of this set.",
    class_chapter_set_form_question_delete_title: "Delete Question",
    class_chapter_set_form_question_delete_text:
      "This will alse delete the question from attempted test by any students. Continue?",

    class_chapter_set_import_not_available_mylibrary:
      "You have not created any question set yet.",
    class_chapter_set_import_not_available_otherslibrary:
      "No question set added by others member.",
    class_chapter_set_import_not_available_mqlibrary:
      "MQ has not added any question set yet.",
    class_chapter_set_import_title: "Import Question Set",
    class_chapter_set_import_subtitle: "Import from question set library",
    class_chapter_set_import_label_import_from: "Import from",
    class_chapter_set_import_label_import_from_option1: "My Library",
    class_chapter_set_import_label_import_from_option2: "Others Library",
    class_chapter_set_import_label_import_from_option3: "MQ Library",
    class_chapter_set_import_th_set_title: "Set Title",
    class_chapter_set_import_th_set_class: "Class-Chapter",
    class_chapter_set_import_th_set_term: "Terms",
    class_chapter_set_import_th_set_privacy: "Privacy",
    class_chapter_set_import_text_imported_from: "Question set imported from",
    class_chapter_set_import_clone_add_success:
      "Question set imported successfully!",

    class_chapter_exam_date_form_title: "Set New Exam Date",
    class_chapter_xam_date_form_label_date: "Select Exam Date",
    class_chapter_xam_date_form_ph_date: "DD/MM/YYYY",
    class_chapter_xam_date_form_add_success:
      "Next exam date saved successfully!",

    class_chapter_set_details_button_edit: "Edit Set",
    class_chapter_set_details_button_delete: "Delete Set",
    class_chapter_set_details_qst_view_ans_text: "View Answer",
    class_chapter_set_details_qst_view_qst_text: "View Question",
    class_chapter_set_details_qst_button_delete: "Delete Term",
    class_chapter_set_delete_title: "Delete Question Set",
    class_chapter_set_delete_text:
      "This will delete all related questions, other data and attempted result by students. Continue?",
    class_chapter_set_delete_success: "Question set deleted successfully!",
    class_chapter_set_notify_title: "Notify all class students",
    class_chapter_set_notify_text:
      "Would you like to send notification email about this set to all class students. Continue?",
    class_chapter_set_notify_success:
      "Notification send to all student successfully!",
    class_chapter_set_qst_delete_success: "Question deleted successfully!",

    class_member_import_title: "Import Member",
    class_member_add_form_title: "Add New Member",
    class_member_edit_form_title: "Update Member",
    class_member_search_not_available: "No member available for searched data",
    class_member_not_available: "You have not added any member yet.",
    class_member_not_subtitle: "Would you like to create one now?",
    class_member_new_button: "New Chapter",
    class_member_th_name: "Name",
    class_member_th_role: "Role",
    class_member_remove_title: "Remove Member",
    class_member_remove_text:
      "User will have no longer access of this class. Continue?",
    class_member_remove_success: "Member removed successfully!",
    class_member_make_admin_title: "Make Admin",
    class_member_make_admin_text: "Mark this user as admin. Continue?",
    class_member_make_admin_success: "Marked as admin successfully!",
    class_member_remove_admin_title: "Remove Admin",
    class_member_remove_admin_text: "Remove this user from admin. Continue?",
    class_member_remove_admin_success: "Removed from admin successfully!",
    class_member_enroll_success: "Student enrolled successfully!",
    class_member_text_member_left: "members left",

    class_progress_title_class_details: "Class Snapshot",
    class_progress_title_class_activity: "Class Activities",
    class_progress_title_chapter_analysis: "Chapter Analysis",
    class_progress_title_chapter_progress: "Chapter Progress",
    class_progress_text_notify_class: "Notify class to attend quiz",
    class_progress_text_set_attempted: "set(s) attempted",
    class_progress_text_set_completed: "set(s) completed",
    class_progress_text_set_initial_completed: "set(s) initial completed",
    class_progress_text_set_aborted: "set(s) aborted",
    class_progress_text_set_practiced: "set(s) practiced",
    class_progress_text_chapter_in_class: "chapter(s) in class",
    class_progress_text_set_in_class: "set(s) in class",
    class_progress_text_more_practiced: "more practice from yesterday",
    class_progress_text_less_practiced: "less practice from yesterday",
    class_progress_text_more_completed: "more completed from yesterday",
    class_progress_text_less_completed: "less completed from yesterday",
    class_progress_text_no_class_activity_for_date:
      "No activity available for selected date",
    class_progress_text_practic_private_set:
      "practiced a private set in chapter",
    class_progress_text_practiced_set: "practiced",
    class_progress_text_started_set: "started quiz",
    class_progress_text_completed_set: "completed quiz",
    class_progress_text_aborted_set: "aborted quiz",
    class_progress_text_added_exam: "added exam in chapter",
    class_progress_text_try_set_again: "Ask to try again",
    class_progress_text_remind_for_qize: "Remind to take quiz",
    class_progress_set_user_reminder_title: "Notify to student",
    class_progress_set_user_reminder_text:
      "Would you like to send notification email about this set to this students. Continue?",
    class_progress_user_remind_send_success:
      "Notification send to student successfully!",

    class_progress_text_popular_chapter: "Most popular chapter",
    class_progress_sub_text_popular_chapter:
      "max completed sets in a chapter on average",
    class_progress_text_neglected_chapter: "Most neglected chapter",
    class_progress_sub_text_neglected_chapter:
      "least completed sets in a chapter on average",
    class_progress_text_practiced_chapter: "Most practiced chapter",
    class_progress_sub_text_practiced_chapter:
      "max times practiced sets in a chapter",
    class_progress_text_max_sets_chapter: "Chapter with highest number of sets",
    class_progress_text_min_sets_chapter: "Chapter with least number of sets",

    class_progress_text_progress_rate: "Progress rate",
    class_progress_text_total_sets: "No. of set(s)",
    class_progress_text_last_attempted_set: "Last attempted set",
    class_progress_text_last_practic_set: "Last practiced set",
    class_progress_set_text_no_ch_progress: "No progress available",

    class_progress_set_text_set_report: "Question Set Report",
    class_progress_set_text_filter_report: "Filter Report",
    class_progress_set_text_class_progress: "Class Progress",
    class_progress_set_text_all_times: "All Times",
    class_progress_set_text_all_chapters: "All Chapters",
    class_progress_set_text_avg_time_on_set: "Avg time spent on set",
    class_progress_set_text_avg_class_score: "Avg class score",
    class_progress_set_text_set_completion_rate: "Class set completion rate",
    class_progress_set_text_no_set_available: "No set available",
    class_progress_set_text_no_record_available: "No record available",
    class_progress_set_th_rank: "Rank",
    class_progress_set_th_student: "Students",
    class_progress_set_th_practice: "Practice",
    class_progress_set_th_attempted: "Attempted",
    class_progress_set_th_completed: "Completed",
    class_progress_set_th_avg_score: "Average Score",
    class_progress_set_th_progress: "Progress",

    // Students
    student_new_button: "New Student",
    student_search_not_available: "No student available for searched data",
    student_not_available: "You have not created any student yet.",
    student_not_available_subtitle: "Would you like to create one now?",
    student_text_share_link: "Share Join Link",
    student_text_share_link_text:
      "You can share this link to your student directly to join",
    student_th_name: "Name",
    student_th_email: "Email",
    student_th_class: "Default Class",
    student_add_form_title: "Add New Student",
    student_edit_form_title: "Update Student",
    student_form_tab_title1: "Add Student",
    student_form_tab_title1_enroll: "Add Member",
    student_form_tab_title2: "Invite Student",
    student_form_tab_title2_enroll: "Invite Member",
    student_form_tab_title3: "Enrol Member",
    student_form_label_fname: "First Name",
    student_form_ph_fname: "Enter first name",
    student_form_label_lname: "Last Name",
    student_form_ph_lname: "Enter last name",
    student_form_label_uname: "Display Name",
    student_form_ph_uname: "Enter display name",
    student_form_label_dob: "Date of Birth",
    student_form_ph_dob: "DD/MM/YYYY",
    student_form_label_parent_email: "Email",
    student_form_ph_parent_email: "Enter email",
    student_form_label_usertype: "Type",
    student_form_label_usertype_option1: "Student",
    student_form_label_usertype_option2: "Teacher",
    student_form_label_class: "Default Class",
    student_form_ph_class: "Select default class",
    student_form_label_enroll_member: "Enrol Member",
    student_form_ph_enroll_member: "Select users to enrol",
    student_form_notes_csv_emails:
      "Enter one or more valid email addresses of users you want to invite separated by comma[,]",
    student_form_add_success: "Student added successfully!",
    student_form_invitation_success: "Invitation sent successfully!",
    student_form_invitation_error_existing_emails:
      "these email(s) are alreaddy exist, you can use enrol feature to enrol existing email(s)",
    student_form_update_success: "Student updated successfully!",

    student_import_title: "Import Students",
    student_import_text_sample_csv: "Sample CSV",
    student_import_text_sample_xls: "Sample XLS",
    student_import_text_doenload_template: "Download Template",
    student_import_th_rowno: "1",
    student_import_th_fname: "Firstname",
    student_import_th_lname: "Lastname",
    student_import_th_uname: "Displayname",
    student_import_th_email: "Email",
    student_import_th_dob: "Dob",
    student_import_note1: "Duplicate data will be deleted",
    student_import_note2:
      "Errors in any information will be ignored (not created)",
    student_import_note3: "DOB date format: YYYY-MM-DD",
    student_users_left: "users left",
    student_import_validation1:
      "The CSV/xls file format is not well formatted please refer to the template.",
    student_import_validation2:
      "The CSV/xls file is empty, Please add some data.",
    student_import_validation3:
      "The CSV/xls contains 1 or more duplicate entries.",
    student_import_success: "User imported successfully!",
    student_import_error: "Import was failed, try again!",

    student_details_tab_title1: "Programs Enrolled",
    student_details_tab_title2: "Quizes Taken",
    student_details_tab_title3: "Viewed Videos",
    student_details_tab_title4: "Progress",

    student_class_not_available: "You have not enrolled in any class yet.",
    student_class_not_subtitle:
      "Contact your teacher to get enrolled in class.",
    student_class_th_name: "Program - Chapter",
    student_class_th_qst_set: "Question Sets",
    student_class_th_mq_set: "MQ Set",
    student_class_th_progress: "Program Progress",

    student_quiz_not_available: "You have not attempted any quiz yet.",
    student_quiz_not_subtitle: "Attempt a quiz to get list here.",
    student_quiz_th_set: "Set Title",
    student_quiz_th_createdby: "Created By",
    student_quiz_th_type: "Type",
    student_quiz_th_takenon: "Taken On",
    student_quiz_th_progress: "Progress",
    student_quiz_text_terms_checked: "Terms checked",

    student_video_not_available: "You have not watched any video yet.",
    student_video_not_subtitle: "Watch a video to get list here.",
    student_video_th_title: "Video Title",
    student_video_th_duration: "Duration",
    student_video_th_watched: "Watched On",

    student_progress_title_student_details: "Student's Snapshot",
    student_progress_title_class_activity: "Student's Activities",
    student_progress_text_set_attempted: "set(s) attempted",
    student_progress_text_set_completed: "set(s) completed",
    student_progress_text_set_aborted: "set(s) aborted",
    student_progress_text_set_practiced: "set(s) practiced",
    student_progress_text_set_member_class: "set(s) in member class",
    student_progress_text_more_practiced: "more practice from yesterday",
    student_progress_text_less_practiced: "less practice from yesterday",
    student_progress_text_more_completed: "more completed from yesterday",
    student_progress_text_less_completed: "less completed from yesterday",
    student_progress_text_no_class_activity_for_date:
      "No activity available for selected date",
    student_progress_text_practic_private_set:
      "Practiced a private set in chapter",
    student_progress_text_practiced_set: "Practiced",
    student_progress_text_started_set: "Started quiz",
    student_progress_text_completed_set: "Completed quiz",
    student_progress_text_initial_completed_set: "Initial completed quiz",
    student_progress_text_aborted_set: "Aborted quiz",
    student_progress_text_added_exam: "Added exam in chapter",

    student_progress_program_text_program_report: "Program Progress Report",
    student_progress_program_text_filter_report: "Filter Report",
    student_progress_program_text_program_enroll: "Program Enrolled",
    student_progress_program_text_avg_time_on_class: "Avg time spent on class",
    student_progress_program_text_avg_score: "Avg score",
    student_progress_program_text_class_completion_rate:
      "Class completion rate",
    student_progress_program_text_no_program_available: "No program available",
    student_progress_program_text_no_record_available: "No record available",
    student_progress_program_th_set: "Sets",
    student_progress_program_th_practice: "Practice",
    student_progress_program_th_attempted: "Attempted",
    student_progress_program_th_completed: "Completed",
    student_progress_program_th_avg_score: "Average Score",
    student_progress_program_th_progress: "Progress",

    // Myspace
    myspace_search_not_available: "You have not enrolled in searched data",
    myspace_not_available: "You have not enrolled in any class yet.",
    myspace_not_available_subtitle:
      "Contact your teacher to get enrolled in class.",

    myspace_chapter_not_available: "No chapter added by teacher.",
    myspace_chapter_not_subtitle: "Contact your teacher to add a new chapter.",

    myspace_member_not_available: "No member added by teacher.",
    myspace_member_not_subtitle: "Contact your teacher to add a ne member",

    myspace_upcoming_exam_title: "Upcoming Exams",
    myspace_upcoming_exam_text_manage_exam: "Manage Exam",
    myspace_upcoming_exam_delete_confirmation_title: "Delete Exam Date",
    myspace_upcoming_exam_delete_confirmation_text:
      "Would you like to delete exam date?",
    myspace_upcoming_exam_delete_success: "Exam deleted successfully!",

    // Cognitive Videos
    videos_not_search_text: "Search video",
    videos_not_available: "No video uploaded for student yet.",
    videos_not_available_subtitle: "Contact your teacher to get video",
    videos_search_not_available: "No video available for searched data",
    videos_th_name: "Video Title",
    videos_th_duration: "Duration",

    // Question Sets
    sets_search_not_available: "No question set available for searched data.",
    sets_not_available: "No question set created yet.",
    sets_not_available_subtitle: "Would you like to create one now?",
    sets_quiztaken_not_available: "You have not attempted any quiz yet.",
    sets_quiztaken_not_available_subtitle: "Attempt a quiz to get list here.",
    sets_general_not_available: "No question set added by other members.",
    sets_general_not_available_subtitle:
      "Please wait untill they will add any set.",
    sets_global_not_available: "No global question set added by teachers.",
    sets_global_not_available_subtitle:
      "Please wait untill they will add any set.",
    sets_button_new_net: "Add New Set",
    sets_tabs_tab1: "My Table",
    sets_tabs_tab2: "Quizes Taken",
    sets_tabs_tab3: "General Library",
    sets_tabs_tab4: "Others Library",
    sets_th_name: "Set Title",
    sets_th_class: "Class",
    sets_th_chapter: "Chapter",
    sets_th_createdby: "Created By",
    sets_th_takenon: "Taken On",
    sets_th_mq_set: "Mq Set",
    sets_th_terms: "Terms",
    sets_th_privacy: "Privacy",
    sets_th_action_option1: "Training",
    sets_th_action_option2: "Evaluation",
    sets_form_ph_chapter: "Select chapter",
    sets_add_form_title: "Add New Question Set",
    sets_edit_form_title: "Update Question Set",
    sets_form_label_class: "Class",
    sets_form_ph_class: "Select class",
    sets_form_label_chapter: "Chapter",

    setsattempt_text_your_ans: "Your answer is",
    setsattempt_text_completed_quiz:
      "You have completed your assessment for the quiz",
    setsattempt_text_practice_quiz:
      "You have completed your practice for the quiz",
    setsattempt_text_goto_dashboard: "Go to Dashboard",
    setsattempt_text_correct_ans: "Correct answer",
    setsattempt_text_earned_point: "Your earned point",
    setsattempt_text_quiz_result: "Quiz result",
    setsattempt_finish_quiz_title: "Finish Quiz",
    setsattempt_finish_quiz_text: "Have you completed your quiz?",
    setsattempt_abort_quiz_confirm_title: "Abort Quiz",
    setsattempt_abort_quiz_confirm_text:
      "If you abort the quiz you will not get any point. Continue?",
    setsattempt_congrats_text_practice_quiz_completed:
      "Bravo! Thanks to this review quiz, your memory and knowledge are reinforced!",

    // Reports
    report_text_all_classes: "All Classes",
    report_tabs_tab1: "Students",
    report_tabs_tab2: "Chapters",
    report_tabs_tab3: "Questions Sets",
    report_text_program: "Program",
    report_text_chapter: "Chapter",
    report_text_std_attm_chart: "Student attempted sets chart",
    report_text_ch_attm_chart: "Chapter's sets attempted chart",
    report_text_sets_attm_chart: "Set's attempted chart",
    report_text_top_correct_ans_title: "Top 50 correct answer's questions",
    report_text_top_incorrect_ans_title: "Top 50 incorrect answer's questions",
    report_text_top_correct_ans_no_list: "No list is available to list here",
    report_text_top_incorrect_ans_no_list: "No list is available to list here",

    // Messages
    message_not_search_text: "Search student or class",
    message_thread_not_available: "No Conversation",
    message_thread_not_available_subtitle: "Start a conversation to list here",
    message_not_available: "No Conversation",
    message_not_available_subtitle: "Select a contact to start a conversation",
    message_thread_search_teacher: "Search student or class",
    message_thread_search_student: "Search teacher or class",
    message_text_send_message: "Send your message...",
    message_text_search_message: "Search message",
    message_text_not_allowed_to_sent_message:
      "Sorry!!! You are not allowed to send message in this thread.",

    // Settings
    settings_text_your_plan: "Your Plan",
    settings_text_plans: "Plans",
    settings_text_plans_success: "Success",
    settings_text_plans_failed: "Failed",
    settings_text_invoices: "Invoices",
    settings_text_user_default_class: "My Class",
    settings_text_user_default_class_subtext: "Select your default class",
    settings_text_user_default_level: "My Level",
    settings_text_user_default_level_subtext: "Select your default level",
    settings_text_user_default_level_update_confirm_title: "Update Level",
    settings_text_user_default_level_update_confirm_subtext:
      "By updating level its will change all your existing associated class with selected level's classes of you teacher. Continue?",
    settings_text_contact_details: "Contact Details",
    settings_text_contact_details_subtext: "Update your contact details",
    settings_text_update_password: "Update Password",
    settings_text_update_password_subtext: "Update your password",

    profile_setting_text_personal_info: "Personal Information",
    profile_setting_form_label_profile: "Profile Image",
    profile_setting_form_label_email: "Email",
    profile_setting_form_label_fname: "First Name",
    profile_setting_form_ph_fname: "Enter first name",
    profile_setting_form_label_lname: "Last Name",
    profile_setting_form_ph_lname: "Enter last name",
    profile_setting_form_label_uname: "Display Name",
    profile_setting_form_ph_uname: "Enter display name",
    profile_setting_form_label_dob: "Date of Birth",
    profile_setting_form_ph_dob: "DD/MM/YYYY",
    profile_setting_form_update_success: "Profile updated successfully!",

    profile_setting_facebook_disconnect_confirmation_text:
      "Do you really want to disconnect your facebook connection?",
    profile_setting_facebook_disconnect_success:
      "Facebook connection removed successfully!",
    profile_setting_facebook_connect_success:
      "Facebook connection added successfully!",
    profile_setting_facebook_connect_error_email_not_matched:
      "Facebook connection not added as logged in email and facebook email is not same!",

    profile_setting_twitter_disconnect_confirmation_text:
      "Do you really want to disconnect your twitter connection?",
    profile_setting_twitter_disconnect_success:
      "Twitter connection removed successfully!",
    profile_setting_twitter_connect_success:
      "Twitter connection added successfully!",
    profile_setting_twitter_connect_error_email_not_matched:
      "Twitter connection not added as logged in email and twitter email is not same!",

    profile_setting_google_disconnect_confirmation_text:
      "Do you really want to disconnect your google connection?",
    profile_setting_google_disconnect_success:
      "Google connection removed successfully!",
    profile_setting_google_connect_success:
      "Google connection added successfully!",
    profile_setting_google_connect_error_email_not_matched:
      "Google connection not added as logged in email and google email is not same!",

    profile_setting_social_link_disconnect_confirmation_text:
      "Do you really want to disconnect your linked connection?",
    profile_setting_social_link_disconnect_success:
      "Link removed successfully!",

    password_setting_form_label_old_password: "Old Password",
    password_setting_form_ph_old_password: "Enter old password",
    password_setting_form_label_new_password: "New Password",
    password_setting_form_ph_new_password: "Enter a new password",
    password_setting_form_label_repeat_password: "Repeat Password",
    password_setting_form_ph_repeat_password: "Repeat new password",
    password_setting_form_validation_min_password:
      "Please enter minimum 6 char password!",
    password_setting_form_validation_repeat_password_required:
      "Please repeat new password!",
    password_setting_form_validation_repeat_old_password_not_match:
      "Password doesn't match with new password!",
    password_setting_form_old_password_error:
      "The password supplied does not match.",
    password_setting_form_success: "Password changed successfully!",

    subscription_title: "Plans for subscription",
    subscription_suptitle: "Choose an affordable plan",
    subscription_text_annual_discount: "Save with annual billing",
    subscription_purchase_offer_1:
      "Access to all content, smart quizzes, review management algorithm.",
    subscription_purchase_offer_2:
      "Access to the dashboard and internal messaging to communicate with teachers.",
    subscription_purchase_offer_3:
      "Awareness videos of the factors of being, sleep and concentration.",
    subscription_purchase_suitable: "Suitable For",
    subscription_purchase_suitable_1: "College/School students",
    subscription_purchase_suitable_2: "Preparation for School Exams",
    subscription_purchase_suitable_3: "Entrance Exam Preparation",
    subscription_purchase_subjects: "Subjects",
    subscription_purchase_subjects_1: "Math",
    subscription_purchase_subjects_2: "English",
    subscription_purchase_subjects_3: "Science",
    subscription_purchase_moke: "Moke Tests",
    subscription_purchase_moke_1: "Unlimited Adaptive Mock Tests",
    subscription_purchase_moke_2: "Upcoming exams algorithms",
    subscription_purchase_moke_3: "Adaptive Tests",
    subscription_not_available:
      "You don't have any active plan, Subscribe your new plan",
    subscription_success_page_message:
      "Thak you for your subscription, Now you can continue to access MQ features",
    subscription_failed_page_message:
      "Sorry..... Your subscription process is failed, Try again",
    subscription_cancel_success: "Your subscription cancelled successfully!",
    subscription_changed_success: "Your subscription updated successfully!",

    invoice_th_invoice_no: "Invoice Number",
    invoice_th_period: "Period",
    invoice_th_action: "Action",
  },
});

export default LocaleStrings;
