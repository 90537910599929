import {
  CLASS_LIST,
  MEMBER_ALL_CLASS_LIST,
  USER_WISE_PROGRAM_LIST,
  CREATE_CLASS,
  CLASS_DETAILS,
  CREATE_CLASS_CHAPTER,
  CLASS_CHAPTER_LIST,
  CLASS_ALL_SETS_LIST,
  CLASS_ALL_MEMBER_LIST,
  CLASS_MEMBER_LIST,
  CLASS_ALL_CHAPTER_LIST,
  CLASS_CHAPTER_DETAILS,
  CREATE_SET_FROM_MQ_MODAL,
  CLASS_CHAPTER_SET_LIST,
  CHAPTER_NEXT_EXAM,
  CREATE_CHAPTER_NEXT_EXAM,
  CREATE_QUESTIONSET,
  IMPORT_QUESTIONSET,
  QUESTION_SET_DETAILS,
  GLOBAL_QUESTIONSET_LIST,
  CLASS_PROGRESS_CLASS_SNAP_DATA,
  CLASS_PROGRESS_CLASS_ACTIVITY,
  CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA,
  CLASS_PROGRESS_CHAPTER_PROGRESS_DATA,
  CLASS_PRGRESS_SET_LIST,
  CLASS_PROGRESS_CLASS_SET_DATA,
  TEACHER_COURSE,

} from "@classesaction";

/*
export var classList = (state = {}, action) => {
  if (action.type === CLASS_LIST) {
    return action.payload;
  }
  return state;
};*/

export var allClassList = (state = {}, action) => {
  if (action.type === MEMBER_ALL_CLASS_LIST) {
    return action.payload;
  }
  return state;
};

export var userClassProgramList = (state = {}, action) => {
  if (action.type === USER_WISE_PROGRAM_LIST) {
    return action.payload;
  }
  return state;
};

export var createClass = (state = {}, action) => {
  if (action.type === CREATE_CLASS) {
    return action.payload;
  }
  return state;
};

export var classDetails = (state = {}, action) => {
  if (action.type === CLASS_DETAILS) {
    return action.payload;
  }
  return state;
};

export var createChapter = (state = {}, action) => {
  if (action.type === CREATE_CLASS_CHAPTER) {
    return action.payload;
  }
  return state;
};
export var classChapterList = (state = {}, action) => {
  if (action.type === CLASS_CHAPTER_LIST) {
    return action.payload;
  }
  return state;
};

export var classAllSets = (state = {}, action) => {
  if (action.type === CLASS_ALL_SETS_LIST) {
    return action.payload;
  }
  return state;
};

export var classAllMembers = (state = {}, action) => {
  if (action.type === CLASS_ALL_MEMBER_LIST) {
    return action.payload;
  }
  return state;
};

export var classMemberList = (state = {}, action) => {
  if (action.type === CLASS_MEMBER_LIST) {
    return action.payload;
  }
  return state;
};

export var classAllChapters = (state = {}, action) => {
  if (action.type === CLASS_ALL_CHAPTER_LIST) {
    return action.payload;
  }
  return state;
};

export var classChapterDetails = (state = {}, action) => {
  if (action.type === CLASS_CHAPTER_DETAILS) {
    return action.payload;
  }
  return state;
};

export var createSetFromMQModal = (state = {}, action) => {
  if (action.type === CREATE_SET_FROM_MQ_MODAL) {
    return action.payload;
  }
  return state;
};

export var classChapterSets = (state = {}, action) => {
  if (action.type === CLASS_CHAPTER_SET_LIST) {
    return action.payload;
  }
  return state;
};

export var chapterNextExam = (state = {}, action) => {
  if (action.type === CHAPTER_NEXT_EXAM) {
    return action.payload;
  }
  return state;
};

export var createChapterNextExam = (state = {}, action) => {
  if (action.type === CREATE_CHAPTER_NEXT_EXAM) {
    return action.payload;
  }
  return state;
};

export var createQuestionSet = (state = {}, action) => {
  if (action.type === CREATE_QUESTIONSET) {
    return action.payload;
  }
  return state;
};

export var importQuestionSet = (state = {}, action) => {
  if (action.type === IMPORT_QUESTIONSET) {
    return action.payload;
  }
  return state;
};

export var questionSetDetails = (state = {}, action) => {
  if (action.type === QUESTION_SET_DETAILS) {
    return action.payload;
  }
  return state;
};

export var globalQuestionSetDetails = (state = {}, action) => {
  if (action.type === GLOBAL_QUESTIONSET_LIST) {
    return action.payload;
  }
  return state;
};

export var classProgressClassSnap = (state = {}, action) => {
  if (action.type === CLASS_PROGRESS_CLASS_SNAP_DATA) {
    return action.payload;
  }
  return state;
};

export var classActivities = (state = {}, action) => {
  if (action.type === CLASS_PROGRESS_CLASS_ACTIVITY) {
    return action.payload;
  }
  return state;
};

export var classPrgressChapterAnalysis = (state = {}, action) => {
  if (action.type === CLASS_PROGRESS_CHAPTER_ANALYSIS_DATA) {
    return action.payload;
  }
  return state;
};

export var classPrgressChapterProgress = (state = {}, action) => {
  if (action.type === CLASS_PROGRESS_CHAPTER_PROGRESS_DATA) {
    return action.payload;
  }
  return state;
};

export var classPrgressSetsList = (state = {}, action) => {
  if (action.type === CLASS_PRGRESS_SET_LIST) {
    return action.payload;
  }
  return state;
};

export var classPrgressSetData = (state = {}, action) => {
  if (action.type === CLASS_PROGRESS_CLASS_SET_DATA) {
    return action.payload;
  }
  return state;
};


export var classList = (state = {}, action) => {
  if (action.type === TEACHER_COURSE) {
    return action.payload;
  }
  return state;
};