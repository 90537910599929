import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PlusIcon } from "@heroicons/react/outline";
import { SearchIcon, UsersIcon } from "@heroicons/react/solid";
import {
  clearStudentList,
  fetchStudentList,
  searchStudentList,
  createStudentModule,
  importStudentModule,
  fetchBulkUplodedLeftUsers,
} from "@studentsaction";
import { teacherClassList } from "@mainaction";
import { classNames } from "@basecomponent";
import { itemCount, MAIN_URL } from "@constant";
import Loader from "@loader";
import Pagination from "@pagination";
import ContentHeader from "@contentheader";
import ItemList from "@studentlists";
import StudentCreate from "@studentaddedit";
import StudentImport from "@studentimport";
import LocaleStrings from "@language";
import studentsimg from "../../assets/image/headerimages/students.svg";
import blankImg from "../../assets/image/blank_students.svg";

const Students = (props) => {
  let { session, studentList, createStudent, importStudent, bulkUplodingLeft } =
    props;
  const [copied, setCopied] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  var intervalUserList = null;
  var processQueue = false;

  useEffect(() => {
    props.clearStudentList();
    props.createStudentModule({ show: false });
    props.importStudentModule({ show: false });

    props.fetchStudentList(session);
    props.teacherClassList(session);
    props.fetchBulkUplodedLeftUsers(session);
    console.log(studentList);
    // console.log("called..... ", processQueue);
    intervalUserList = setInterval(() => {
      // console.log("intervalUserList :- ", intervalUserList);
      props.fetchBulkUplodedLeftUsers(session);
      callFunction();

      if (bulkUplodingLeft?.count > 0) processQueue = true;
      else processQueue = false;

      if (!processQueue) {
        clearDataInterval();
      }
    }, 15000);

    return () => {
      clearDataInterval();
    };
  }, []);

  const open = () => {
    props.createStudentModule({ key: "create", show: true });
  };

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);
    if (obj?.key === "create") props.createStudentModule({ ...obj });
    else if (obj?.key === "import") props.importStudentModule({ ...obj });
  };

  const clearDataInterval = () => {
    clearInterval(intervalUserList);
    intervalUserList = null;
    processQueue = false;
  };

  const callFunction = () => {
    paginationCallback(pageNumber);
  };

  const callbackUploadUser = () => {
    // console.log("called 2nd out.....");
    refresh()
    if (!intervalUserList) {
      /*intervalUserList = setInterval(() => {
        // console.log("called 2nd.....");
        // console.log("this.processQueue 2nd.....", processQueue);
        props.fetchBulkUplodedLeftUsers(session);
        callFunction();

        if (bulkUplodingLeft?.count > 0) processQueue = true;
        else processQueue = false;

        if (!processQueue) {
          clearDataInterval();
        }
      }, 500);*/
    } else {
      // console.log("called 2nd out else.....");
    }
  };

  const copyUrl = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNum = 1;

    if (search === "") {
      if (studentList?.count === 0) {
        props.clearStudentList();
      }

      props.fetchStudentList(session, pageNum);
    } else {
      props.searchStudentList(session, search, pageNum, (response) => {});
    }

    setSearch(search);
    setPageNumber(pageNum);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchStudentList(session, pageNum);
    } else {
      props.searchStudentList(session, search, pageNum, (response) => {});
    }

    setPageNumber(pageNum);
  };

  const refresh = () => {
    let pageNum = 1;
    if (studentList?.count === 0) {
      props.clearStudentList();
    }
    props.fetchStudentList(session, pageNum);
  }

  const renderItems = () => {
    return studentList?.data?.map((item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          listitem={item}
          bgWhite={index % 2 === 0}
          refresh={refresh}
        />
      );
    });
  };
  // console.log("bulkUplodingLeft :- ", bulkUplodingLeft);
  var joinlink = `${MAIN_URL}#/auth/signup?invitedby=${session?.userid}`;

  return (
    <>
      <ContentHeader
        customData={{
          section: "students",
          subsection: "own",
          image: studentsimg,
          breadcrumbs: [{ key: "students", text: "Mes élèves" }],
          button: [
            {
              key: "import",
              name:
                bulkUplodingLeft.count > 0
                  ? `${bulkUplodingLeft.count} ${LocaleStrings.student_users_left}`
                  : LocaleStrings.button_import,
              class: "default-button-md",
              icon: "",
              disabled: bulkUplodingLeft.count > 0 ? true : false,
            },
            {
              key: "create",
              name: LocaleStrings.student_new_button,
              class: "theme-button-md",
              icon: PlusIcon,
            },
          ],
        }}
        handleAction={handleActionButton}
      />
      {studentList?.data ? (
        <div
          className={classNames(
            studentList?.count > 0 ? "" : "h-5/6",
            "content-body"
          )}
        >
          <div className="bg-gray-50 mb-6 2xl:mb-10 border rounded-xl shadow-lg">
            <div className="px-4 py-4 sm:px-4 sm:py-4 lg:px-5 lg:py-5">
              <div className="md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div>
                    <div className="text-2xl 2xs:text-3xl font-normal text-primary">
                      {LocaleStrings.student_text_share_link}
                    </div>
                    <div>
                      <span className="text-lg 2xs:text-xl font-normal text-secondary">
                        {LocaleStrings.student_text_share_link_text}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-2 space-x-3 md:mt-0 md:ml-4">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="space-x-2 bg-white  border border-borderColor rounded-l-md relative flex items-center flex-grow pr-3 w-74% md:w-80">
                      <div className="inset-y-0 left-0 pl-3 flex items-center justify-center">
                        <UsersIcon
                          className="h-6 w-6 2xs:h-7 2xs:w-7"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="inline text-lg 2xs:text-xl text-normal text-secondary whitespace-nowrap text-ellipsis overflow-hidden">
                        {joinlink}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="relative inline-flex items-center space-x-2 px-4 py-2 border text-lg 2xs:text-xl text-normal text-secondary rounded-r-md bg-gray-50"
                    >
                      <CopyToClipboard text={joinlink} onCopy={copyUrl}>
                        <span>
                          {copied ? LocaleStrings.copied : LocaleStrings.copy}
                        </span>
                      </CopyToClipboard>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {search !== "" || studentList?.count > 0 ? (
            <div className="mb-5 2xs:mb-12 md:flex md:items-center md:justify-between">
              <div className="w-full 2xs:w-5/12">
                <div className="relative w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 pl-3.5 2xs:pl-6 flex items-center">
                    <svg
                      className="h-6 w-6 2xs:h-8	2xs:w-8"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.667 27.5539C21.2944 27.5539 26.667 22.1813 26.667 15.5539C26.667 8.92645 21.2944 3.55386 14.667 3.55386C8.03958 3.55386 2.66699 8.92645 2.66699 15.5539C2.66699 22.1813 8.03958 27.5539 14.667 27.5539Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.2397 28.4737C25.9464 30.607 27.5597 30.8203 28.7997 28.9537C29.933 27.247 29.1863 25.847 27.133 25.847C25.6131 25.8337 24.7597 27.0204 25.2397 28.4737Z"
                        stroke="#1A242D"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="search-input-field"
                    placeholder={LocaleStrings.search}
                    type="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              <div className="mt-2 md:mt-0 w-full md:w-60">
                {/* <select
                  id="sortstudent"
                  name="sortstudent"
                  className="block w-full bg-white pl-3 pr-10 py-2 border border-defaultColor text-sm text-secondary rounded-md"
                  defaultValue="Class 1"
                >
                  <option>Sort by</option>
                  <option>Class 1</option>
                  <option>Class 2</option>
                </select> */}
              </div>
            </div>
          ) : (
            ""
          )}

          {studentList?.count > 0 ? (
            <>
              <div className="flex flex-col">
                <div className="overflow-x-auto border shadow-lg rounded-xl">
                  <div className="align-middle inline-block min-w-full">
                    <div className="overflow-hidden rounded-xl">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_th_name}
                            </th>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_th_email}
                            </th>
                            <th scope="col" className="tableheader">
                              {LocaleStrings.student_th_class}
                            </th>
                           
                          </tr>
                        </thead>
                        <tbody>{renderItems()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* Pagination */}

                {studentList?.count > itemCount ? (
                  <>
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={itemCount}
                      totalItemsCount={studentList?.count}
                      onChange={paginationCallback}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="custom-item-center h-full">
              <div className="text-center">
                <img className="inline" src={blankImg} />
                <div className="mt-2 text-xl 2xs:text-2xl font-normal text-primary">
                  {search === "" ? (
                    LocaleStrings.student_not_available
                  ) : (
                    <>
                      {LocaleStrings.student_search_not_available}
                      {" : "}
                      <span className="text-xl 2xs:text-2xl font-semibold text-primary">
                        {search}
                      </span>
                    </>
                  )}
                </div>

                {search === "" ? (
                  <>
                    <p className="mt-1 text-lg 2xs:text-xl font-normal text-secondary">
                      {LocaleStrings.student_not_available_subtitle}
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="theme-button-md"
                        onClick={open}
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        {LocaleStrings.student_new_button}
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {createStudent?.show ? (
            <StudentCreate finishCallback={additionCallback} />
          ) : (
            ""
          )}
          {importStudent?.show ? (
            <StudentImport refresh={refresh} callbackUploadUser={callbackUploadUser} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    studentList: state.studentList,
    createStudent: state.createStudent,
    importStudent: state.importStudent,
    bulkUplodingLeft: state.bulkUserUplodingLeft,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearStudentList,
      fetchStudentList,
      searchStudentList,
      createStudentModule,
      importStudentModule,
      teacherClassList,
      fetchBulkUplodedLeftUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Students);
