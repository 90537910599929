import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import {
  clearClassBasicProgress,
  fetchClassBasicProgress,
  clearClassActivities,
  fetchClassActivities,
  clearClassAllChapters,
  fetchClassAllChapters,
  clearClassProgressSets,
  fetchClassProgressSets,
  clearClassSetProgress,
  fetchClassSetProgress,
  notifyClassAboutSet,
} from "@classesaction";
import ClassSnap from "./class-snap";
import ClassActivity from "./class-activity";
import ChapterAnalysis from "./chapter-analysis";
import ChapterProgress from "./chapter-progress";
import SetData from "./set-data";
import Loader from "@loader";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";

const Progress = (props) => {
  const { classid } = useParams();
  var {
    session,
    classProgressClassSnap,
    classActivities,
    classAllChapters,
    classPrgressSetsList,
  } = props;
  const [selectedSet, setSelectedSet] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  var [startDate, endDate] = dateRange;

  useEffect(() => {
    props.clearClassBasicProgress();
    props.fetchClassBasicProgress(session, classid);
    props.clearClassActivities();
    props.fetchClassActivities(session, {
      classid: classid,
      today: moment().format("YYYY-MM-DD"),
    });
    props.clearClassAllChapters();
    props.fetchClassAllChapters(session, classid);
    props.clearClassProgressSets();
    props.clearClassSetProgress();
    props.fetchClassProgressSets(session, { classid: classid }, (res) => {
      if (res?.data?.length > 0) {
        setSelectedSet(res?.data?.[0]?.setid ?? 0);
        props.fetchClassSetProgress(session, {
          classid: classid,
          setid: res?.data?.[0]?.setid ?? 0,
        });
      }
    });
  }, []);

  const handleChapteSets = (e) => {
    var chap = e.target.value;
    // console.log("chap :- ", chap);

    props.clearClassProgressSets();
    props.clearClassSetProgress();
    if (chap == "all") {
      props.fetchClassProgressSets(session, { classid: classid }, (res) => {
        if (res?.data?.length > 0) {
          setSelectedSet(res?.data?.[0]?.setid);
          props.fetchClassSetProgress(session, {
            classid: classid,
            setid: res?.data?.[0]?.setid,
            ...(startDate && endDate
              ? {
                  startdate: moment(startDate).format("YYYY-MM-DD"),
                  enddate: moment(endDate).format("YYYY-MM-DD"),
                }
              : ""),
          });
        }
      });
    } else {
      props.fetchClassProgressSets(
        session,
        { classid: classid, chapterid: chap },
        (res) => {
          if (res?.data?.length > 0) {
            setSelectedSet(res?.data?.[0]?.setid);
            props.fetchClassSetProgress(session, {
              classid: classid,
              setid: res?.data?.[0]?.setid,
              ...(startDate && endDate
                ? {
                    startdate: moment(startDate).format("YYYY-MM-DD"),
                    enddate: moment(endDate).format("YYYY-MM-DD"),
                  }
                : ""),
            });
          }
        }
      );
    }
  };

  const handledateFilter = (dates) => {
    // console.log("dates :- ", dates);
    [startDate, endDate] = dates;
    setDateRange(dates);
    // console.log("startDate, endDate :- ", startDate, endDate);
    if (selectedSet !== "") {
      if (startDate && endDate) {
        props.clearClassSetProgress();
        props.fetchClassSetProgress(session, {
          classid: classid,
          setid: selectedSet,
          startdate: moment(startDate).format("YYYY-MM-DD"),
          enddate: moment(endDate).format("YYYY-MM-DD"),
        });
      }
      if (startDate || endDate) {
        // No Api Call
      } else {
        props.clearClassSetProgress();
        props.fetchClassSetProgress(session, {
          classid: classid,
          setid: selectedSet,
        });
      }
    }
  };

  const handleSetData = (item) => {
    setSelectedSet(item?.setid);
    props.clearClassSetProgress();
    props.fetchClassSetProgress(session, {
      classid: classid,
      setid: item?.setid,
      ...(startDate && endDate
        ? {
            startdate: moment(startDate).format("YYYY-MM-DD"),
            enddate: moment(endDate).format("YYYY-MM-DD"),
          }
        : ""),
    });
  };

  const handleNotifyClass = (item) => {
    // console.log("item :- ", item);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.class_chapter_set_notify_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.class_chapter_set_notify_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  onClose();
                  props.notifyClassAboutSet(
                    session,
                    item[0].setid,
                    (res) => {}
                  );
                  setTimeout(() => {
                    toast.success(
                      LocaleStrings.class_chapter_set_notify_success
                    );
                  }, 1000);
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const renderSets = () => {
    return classPrgressSetsList?.data?.map((item, i) => {
      return (
        <div key={`class_set_key_${i}`}>
          <div
            className={classNames(
              selectedSet == item?.setid ? "bg-gray-300" : "cursor-pointer",
              "text-xl 2xs:text-xl font-normal text-primary py-4 px-5 flex items-center justify-between"
            )}
            onClick={
              selectedSet == item?.setid ? null : () => handleSetData(item)
            }
          >
            <span>{item?.setname}</span>
            {selectedSet == item?.setid ? (
              <>
                <ChevronDownIcon className="block md:hidden w-5 h-5 2xs:w-5 2xs:h-5" />
                <ChevronRightIcon className="hidden md:block w-5 h-5 2xs:w-5 2xs:h-5" />
              </>
            ) : (
              <ChevronRightIcon className="w-5 h-5 2xs:w-7 2xs:h-7" />
            )}
          </div>
          <div className="block md:hidden">
            {selectedSet == item?.setid ? (
              <div className="h-36rem overflow-auto">
                {classPrgressSetsList?.count > 0 ? <SetData /> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  };

  var selectedSetData = _.filter(classPrgressSetsList?.data, (o) => {
    return o?.setid == selectedSet;
  });

  // console.log("classProgressClassSnap :- ", classProgressClassSnap);
  // console.log("classActivities :- ", classActivities);
  // console.log("classAllChapters :- ", classAllChapters);
  // console.log("classPrgressSetsList :- ", classPrgressSetsList);
  // console.log("dateRange :- ", dateRange);
  // console.log("startDate, endDate ------:- ", startDate, endDate);

  return (
    <>
      <div className="pt-6 2xs:pt-10">
        {classProgressClassSnap?.data && classActivities?.data ? (
          <div className="">
            <div className="max-w-full mx-auto">
              {/* Class Progress */}
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2">
                <ClassSnap />

                <ClassActivity classid={classid} />
              </div>

              {/* Chapter Progress */}
              <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2">
                <ChapterAnalysis />

                <ChapterProgress />
              </div>
            </div>

            <div className="mt-10 mb-5 space-y-3 md:space-y-0 md:flex items-center justify-between">
              <div className="text-2xl 2xs:text-3xl font-normal text-primary">
                {LocaleStrings.class_progress_set_text_set_report}
              </div>
              <div className="xs:flex items-center justify-between xs:space-x-3 space-y-3 xs:space-y-0">
                <div className="text-xl 2xs:text-2xl font-normal text-primary">
                  {LocaleStrings.class_progress_set_text_filter_report}
                </div>
                <div>
                  <DatePicker
                    placeholderText={
                      LocaleStrings.class_progress_set_text_all_times
                    }
                    className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => handledateFilter(update)}
                    isClearable={true}
                    maxDate={new Date()}
                    dateFormat={`dd/MM/yyyy`}
                  />
                </div>
                <div>
                  <select
                    className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
                    onChange={handleChapteSets}
                  >
                    <option value="all">
                      {LocaleStrings.class_progress_set_text_all_chapters}
                    </option>
                    {classAllChapters?.data?.map((item, i) => {
                      return (
                        <option
                          key={`chapter_key_${i}`}
                          value={item?.chapterid}
                        >
                          {item?.chaptername}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="max-w-full mx-auto shadow-lg rounded-xl">
              <div className="bg-gray-400 xs:flex items-center justify-between py-5 px-5 rounded-t-xl space-y-3 xs:space-y-0">
                <div className="text-2xl 2xs:text-2xl font-semibold text-primary">
                  {LocaleStrings.class_progress_set_text_class_progress}
                </div>
                <div className="text-2xl 2xs:text-xl font-semibold text-primary">
                  {selectedSetData?.[0]?.setname
                    ? LocaleStrings.quiz + " - " + selectedSetData?.[0]?.setname
                    : ""}
                </div>
                {classPrgressSetsList?.count > 0 &&
                selectedSetData?.length > 0 &&
                session?.usertype === "teacher" ? (
                  <div
                    className="text-2xl 2xs:text-xl font-semibold text-primary"
                    onClick={() => handleNotifyClass(selectedSetData)}
                  >
                    {LocaleStrings.class_progress_text_notify_class}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="grid grid-cols-12 border border-defaultColor rounded-b-xl">
                <div className="col-span-12 md:col-span-4 border-r-2 divide-y-1 border-defaultColor h-36rem overflow-auto">
                  {classPrgressSetsList?.data ? (
                    <>
                      {classPrgressSetsList?.count > 0 ? (
                        <>{renderSets()}</>
                      ) : (
                        <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
                          {
                            LocaleStrings.class_progress_set_text_no_set_available
                          }
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Loader />
                    </>
                  )}
                </div>
                <div className="hidden md:block col-span-12 md:col-span-8 h-36rem overflow-auto">
                  {classPrgressSetsList?.count > 0 ? (
                    <SetData selectedSet={selectedSet} />
                  ) : (
                    <div className="h-full px-5 flex items-center justify-center text-xl 2xs:text-xl font-normal text-primary">
                      {
                        LocaleStrings.class_progress_set_text_no_record_available
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classProgressClassSnap: state.classProgressClassSnap,
    classActivities: state.classActivities,
    classAllChapters: state.classAllChapters,
    classPrgressSetsList: state.classPrgressSetsList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearClassBasicProgress,
      fetchClassBasicProgress,
      clearClassActivities,
      fetchClassActivities,
      clearClassAllChapters,
      fetchClassAllChapters,
      clearClassProgressSets,
      fetchClassProgressSets,
      clearClassSetProgress,
      fetchClassSetProgress,
      notifyClassAboutSet,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
