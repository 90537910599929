import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import { classNames } from "@basecomponent";
import { openVideoModal, getUserVideoStatus } from "@cognivideosaction";

const ItemList = (props) => {
  let { session, listitem, device } = props;

  const handleVideo = () => {
    props.openVideoModal({ show: true, values: listitem });
    props.getUserVideoStatus(session, { videoid: listitem?.id });
  };

  return (
    <>
      {device === "dasktop" ? (
        <tr
          className={classNames(
            "bg-white cursor-pointer hover:bg-secondaryHover"
          )}
          onClick={handleVideo}
        >
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center space-x-5">
              <div className="h-24 w-24 rounded-xl bg-theme custom-videoplayer">
                <ReactPlayer
                  controls={true}
                  light={true}
                  volume={1}
                  muted={true}
                  playsinline={true}
                  pip={true}
                  progressInterval={10000}
                  url={listitem.url}
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="text-xl 2xs:text-xl font-normal text-primary">
                {listitem.name}
              </div>
            </div>
          </td>
          <td className="tabledata">{`00:32:00`}</td>
        </tr>
      ) : (
        <li
          className={classNames("relative cursor-pointer")}
          onClick={handleVideo}
        >
          <div className="h-88 w-full rounded-xl bg-theme custom-videoplayer">
            <ReactPlayer
              controls={true}
              light={true}
              volume={1}
              muted={true}
              playsinline={true}
              pip={true}
              progressInterval={10000}
              url={listitem.url}
              width="100%"
              height="100%"
            />
          </div>
          <div className="absolute bottom-6 px-5 flex items-end w-full">
            <span className="flex-1 text-3xl font-normal text-white">
              {listitem.name}
            </span>
            <span className="text-lg font-normal text-white">{`00:32:00`}</span>
          </div>
        </li>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openVideoModal, getUserVideoStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
