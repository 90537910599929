import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { fetchDashboardLeaderBoard } from "@dashboardaction";
import { classNames } from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import blankImg from "../../../assets/image/blank_leaderboardD.svg";
import ClassementSvg from '../../../assets/image/Classement.svg'
import { useNavigate } from "react-router-dom";

const Leaderboard = (props) => {
  var { session, allClassAndItsChapter, dashboardLeaderboardData } = props;
  const [selectedClass, setSelectedClass] = useState("");
  const [lboardperiod, setLboardPeriod] = useState("weekly");
  const navigate = useNavigate();
  const goToMySpace = (e) => {
    navigate(`/myspace`);
  };

  const handleClass = (e) => {
    var val = e.target.value;
    // console.log("class : - ", val);

    apiCall(val, lboardperiod);

    setSelectedClass(val);
  };

  const handleLboardPeriod = (e) => {
    var val = e.target.value;
    // console.log("class : - ", val);

    apiCall(selectedClass, val);

    setLboardPeriod(val);
  };

  const apiCall = (classid = "", lperiod = "weekly") => {
    props.fetchDashboardLeaderBoard(session, classid, lperiod);
  };

  const renderLeaderboard = () => {
    return _.map(dashboardLeaderboardData?.data, (item, index) => {
      var image = item?.image
        ? `${BASE_IMAGES_URL}/${item?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : "";

      return (
        <tr
          key={`lboad_key_${index}`}
          className={classNames(index % 2 === 0 ? "bg-white" : "bg-gray-50")}
        >
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <span className="inline-block h-12 w-12 2xs:h-12 2xs:w-12 rounded-full bg-theme">
                {image !== "" ? (
                  <img
                    className="inline-block h-full w-full rounded-full"
                    src={image}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </span>
              <span className="capitalize text-xl 2xs:text-xl font-normal text-primary ml-5">
                {item?.username}
              </span>
            </div>
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.points}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.quiztaken}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.trophy}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.badges}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.medals_gold}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.medals_silver}
          </td>
          <td className="hidden 2xs:table-cell tabledata  whitespace-nowrap">
            {item?.rank}
          </td>
          <td className="tabledata  whitespace-nowrap">{item?.global_rank}</td>
        </tr>
      );
    });
  };

  var classes = [];
  _.map(allClassAndItsChapter?.data, (obj) => {
    classes.push({
      value: obj?.classid,
      text: obj?.classname + " - " + obj?.programname,
    });
  });

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  // console.log("dashboardLeaderboardData :- ", dashboardLeaderboardData);

  return (
    <>
      <div className="grid sm:flex items-center justify-between mb-8">
        <div className="col-span-12 flex-1 text-2xl sm:text-3xl font-semibold text-primary capitalize mb-4">
          {LocaleStrings.dashboard_th_leaderboard}
        </div>
        <div className="col-span-12 flex items-center gap-3">
          <select
            id="classid"
            name="classid"
            className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
            onChange={handleClass}
            value={selectedClass}
          >
            <option value="">All</option>
            {_.map(classes, (obj, index) => {
              return (
                <option key={`class_key_${index}`} value={obj?.value}>
                  {obj?.text}
                </option>
              );
            })}
          </select>
          <select
            name="lboardperiod"
            className="block w-full 2xs:w-56 h-12 2xs:h-14 text-xl 2xs:text-xl font-normal text-primary placeholder-default border-inputbox shadow-lg border rounded-lg 2xs:rounded-xl focus:ring-0 focus:border-gray-300"
            onChange={handleLboardPeriod}
            value={lboardperiod}
          >
            <option value="weekly">{LocaleStrings.this_week}</option>
            <option value="monthly">{LocaleStrings.this_month}</option>
          </select>
        </div>
      </div>
      {dashboardLeaderboardData?.data?.length > 0 ? (
        <div className="flex flex-col">
          <div className="align-middle inline-block min-w-full">
            <div className="border shadow-lg rounded-xl overflow-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_user_name}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_points}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_quiz_taken}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_trophy}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_badges}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_gold_medal}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_silver_medal}
                    </th>
                    <th
                      scope="col"
                      className="hidden 2xs:table-cell tableheader"
                    >
                      {LocaleStrings.dashboard_th_rank}
                    </th>
                    <th scope="col" className="tableheader">
                      {LocaleStrings.dashboard_th_global_rank}
                    </th>
                  </tr>
                </thead>
                <tbody>{renderLeaderboard()}</tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="custom-item-center max-w-full mx-auto shadow-lg rounded-xl border h-60 md:h-26rem p-4">
           <div className="custom-item-center w-full 2xl:w-80% 2xl:m-auto h-full p-4">
            <div className="text-center flex items-center space-x-3 2xs:space-x-5">
              <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                <img className="inline w-full h-full" src={ClassementSvg} />
              </div>
              <div className="mt-7 text-xl 2xs:text-xl font-medium text-primary">
                Ton classement s'affichera ici lorsque tu auras répondu à tes premiers quizzs !
                Tu pourras alors voir ta progression et comparer ton score avec tes camarades !
                <p>
                  <button onClick={goToMySpace} className="mt-10 text-lg font-light py-1.5 text-white w-full bg-gradient-to-r from-[#675af0] to-[#f0a45e] rounded-3xl">Commence une activité !</button>
                </p>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  var allClassAndItsChapter =
    state?.session?.usertype === "student"
      ? state.studentAllClassList
      : state.allClassAndItsChapter;

  return {
    session: state.session,
    allClassAndItsChapter,
    dashboardLeaderboardData: state.dashboardLeaderboardData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDashboardLeaderBoard }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
