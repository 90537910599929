import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, change  } from "redux-form";
import moment from "moment";
import _ from "lodash";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { fetchClassAllChapters, clearClassChapterList, fetchSingleSetDetails,} from "@classesaction";
import { useNavigate } from "react-router-dom";
import {
  renderHiddenFieldText,
  renderSelect,
  renderFieldText,
  renderDatalistSelect,
  renderFieldTextarea,
  renderFieldCheckbox,
  classNames,
} from "@basecomponent";
import { BASE_IMAGES_URL } from "@constant";
import ImageCropper from "@imagecropper";
import LocaleStrings from "@language";
import { teacherClassList,copyQuestionSet } from "@mainaction";
import { fetchUserPlans } from "@settingsaction";
import { convertDateIntoLocal, DATE_FORMAT } from "@constant";



const CopyQuestions = (props) => {

    let { 
      session,
      isOpened,
      setIsOpened,
      pristine,
      invalid,
      submitting,
      handleSubmit,
      setid,
      questionSetDetails,
      userActivePlan
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [test,setTest] = useState(false);
    const cancelButtonRef = useRef(null);
    const [classes, setClasses] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [questionSelected,setQuestionSelected] = useState([]);
    const navigate = useNavigate();
    const copyQuestionsClose = () => {
      setIsOpened(false)
    };

    let isLimited = userActivePlan?.data?.[0] == undefined ? true : false
    if(userActivePlan?.data?.[0] !== undefined){
      isLimited = !moment(
        convertDateIntoLocal(userActivePlan?.data?.[0]?.["planexpirydate"]).format(
          DATE_FORMAT
        )
      ).isSameOrAfter(moment().format(DATE_FORMAT))
    }
   
    var [loading, setLoading] = useState(false);
    const handleSelectChange = (event, newValue, previousValue, name) => {
      const temp = []
      if(newValue == "" && name === "classidfk"){
        setChapters(temp)
      }

      if(newValue == ""){
        return;
      }
      if (name === "classidfk") {
        props.fetchClassAllChapters(session, newValue, (res)=>{
          res?.data?.map((item) => {
              temp.push({
                value: item?.chapterid,
                text: item.chaptername,
              });
            });
          
        });

        
        setChapters(temp)
      }
    }
    const onSubmitForm = (data) => {
      var values = { ...data };
      // console.log("values :- ", values);
      // console.log("initialValues :- ", initialValues);
      console.log("passe 1")
      props.copyQuestionSet(session, values.classidfk, values.chapteridfk, setid, questionSelected,(res) => {
        //setIsOpened(false)
        console.log("passe")
        if(values.chapteridfk == undefined || values.chapteridfk == ""){
          navigate("/classes/" + values.classidfk);
        }else{
          navigate("/classes/" + values.classidfk + "/" + values.chapteridfk);
          navigate(0);//because have bug no change page but change url
        }
      });
      
      //values.classidfk
    };


    const toogleQuestion = (questionid) => {
      if(questionSelected.includes(questionid)){
        const index = questionSelected.indexOf(questionid);
        questionSelected.splice(index,1);
        setQuestionSelected([...questionSelected])
      }else{
        if(questionSelected.length >= 10){
          return;
        }
        setQuestionSelected([...questionSelected,questionid])
        //questionSelected.push(questionid)
      }
    }


    const renderQuestion = () => {
      return _.map(questionSetDetails?.data?.questions_by_questionsetidfk, (question, index) => {
        return (
          <>
            <p key={index} onClick={(e) => toogleQuestion(question.questionid)} className="text-left pt-4 hover:bg-secondaryHover">
              {questionSelected.includes(question.questionid)
                ?
                  <svg viewBox="0 0 24 24" className="inline-block text-green-300 w-10 h-10 sm:w-5 sm:h-5 mr-3">
                    <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                  </svg>
                :
                  <svg viewBox="0 0 24 24" className="inline-block text-green-300 w-10 h-10 sm:w-5 sm:h-5 mr-3">
                    <path fill="#525252" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                  </svg>
              }
              
              {question.question}
            </p>
            <hr></hr>
          </>
        );
      })
    }

    useEffect(() => {
      const temp = [];
      props.teacherClassList(session, (res)=>{
        res?.data?.map((item) => {
            temp.push({
              value: item?.classid,
              text: `${item?.classname} - ${item?.programname}`,
            });
        });

        props.fetchSingleSetDetails(session, { setid: setid });
        setClasses(temp);
      });
  
      return () => {
        
      };
    }, [questionSetDetails]);

    
    return (
      <>
        <Transition.Root
          show={isOpened}
          as={Fragment}
          initialFocus={cancelButtonRef}
          onClose={copyQuestionsClose}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
          >
            <div className="relative flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[75%]  text-xl sm:w-full sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title className="text-2xl 2xs:text-3xl font-normal text-primary">
                        Importer le questionnaire
                      </Dialog.Title>
                      <div className="flex items-center">
                        <button
                          type="button"
                          className="outline-none absolute right-0 top-0 mt-4 mr-4 focus:outline-none"
                          onClick={() => copyQuestionsClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon
                            className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                            aria-hidden="true"
                          />
                        </button>
                      </div>

                      <form onSubmit={handleSubmit(onSubmitForm)}>
                        <Field
                          name="classidfk"
                          label="classe"
                          placeholder="Selectionner une classe"
                          component={renderSelect}
                          mandatory="true"
                          opts={classes}
                          onChange={handleSelectChange}
                        />


                        <Field
                          name="chapteridfk"
                          label="chapitre"
                          placeholder="Selectionner un chapitre"
                          component={renderSelect}
                          mandatory="false"
                          required={false}
                          opts={chapters}
                        />

                      {isLimited == true &&
                       <>
                          <h4 className="text-center">{questionSelected.length} / 10 questions sélectionner</h4>
                          <div className="overflow-y-scroll max-h-96">
                  
                              {renderQuestion()}
                        
                          </div>
                        </>
                      }
                        <div className="flex-shrink-0 px-4 py-4 flex justify-end gap-3">
                          <button
                            type="button"
                            className="default-button-md"
                          >
                            {LocaleStrings.button_cancel}
                          </button>
                          <button
                            type="submit"
                            disabled={pristine || invalid || submitting || loading}
                            className="theme-button-md"
                          >
                            <svg
                              className={classNames(
                                loading ? "" : "hidden",
                                "cust-btn-spinner"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="btnloading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {"Copier"}
                          </button>
                        </div>
                      </form>
                      
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      session: state.session,
      questionSetDetails: state.questionSetDetails,
      userActivePlan: state.userActivePlan,
    };
  };
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        teacherClassList,
        fetchClassAllChapters,
        clearClassChapterList,
        copyQuestionSet,
        change,
        fetchSingleSetDetails,
        fetchUserPlans
      },
      dispatch
    );
  
    const validate = (values, ownProps) => {
      var errors = {};
      var classe = values["classidfk"];
      var chapter = values["chapteridfk"];
      
    
      if (!classe || classe.trim() === "") {
        errors["classidfk"] = LocaleStrings.required;
      }
    
      return errors;
    };
    
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "copyquestions",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(CopyQuestions)
);