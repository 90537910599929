import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  renderFieldTextarea,
} from "@basecomponent";
import { Field, reduxForm, change } from "redux-form";



const Responseset = (props) => {

    const handleSelectChange = (event) => {
        props.reponseCb(event.target.value)
    }

    return (
        <Field
            isdisabled={props.isdisabled}
            name="reponse"
            placeholder="Votre réponse"
            component={renderFieldTextarea}
            input={{value : props.value, onChange:handleSelectChange}}
        />
    );
}

const mapStateToProps = (state) => {
    return {
    };
};
  
const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        change
    },
    dispatch
);

const validate = (values, ownProps) => {
    let errors = {};

    return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "responseset",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Responseset)
);