import axios from "axios";
import randomstring from "randomstring";
import { INSTANCE_URL, BASE_IMAGES_URL, MAIN_URL, itemCount,STRIPE_URL } from "@constant";
import {
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFile,
} from "@network";
import { updateSession } from "@mainaction";

export const LOGIN_USER_DETAILS = "LOGIN_USER_DETAILS";
export const USER_PLANS_LIST = "USER_PLANS_LIST";
export const LOGIN_USER_ACTIVE_PLAN = "LOGIN_USER_ACTIVE_PLAN";
export const CHANGE_SUBSCRIPTION_MODAL = "CHANGE_SUBSCRIPTION_MODAL";

export function fetchLoginUserDetails(session, callback) {
  var filter = encodeURI(`(userid=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: LOGIN_USER_DETAILS,
          payload: { data: response?.resource?.[0] },
        });
        callback({ status: 1, data: response?.resource?.[0] });
      },
      (error) => {}
    );
  };
}

export function clearLoginUserDetails() {
  return (dispatch) => {
    dispatch({
      type: LOGIN_USER_DETAILS,
      payload: {},
    });
  };
}

export function updateUser(session, values, callback) {
  var image = values?.image ?? "";

  if (image === "") {
    values.image = "";
    image = "";
  }
  var filename = `users/${
    values?.userid
  }/profile/${randomstring.generate()}.jpg`;

  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users`;

  return (dispatch) => {
    _uploadImage(session, image, filename, dispatch, (image) => {
      // console.log("image :- ", image);

      if (image.success == 1) {
        values = {
          ...values,
          ...(image?.data?.path
            ? {
                image: image?.data?.path,
              }
            : {}),
        };
        var body = { resource: [{ ...values }] };
        // console.log("body :- ", body);

        patchRequest(
          url,
          body,
          session,
          dispatch,
          (data) => {
            // console.log("data :- ", data);

            // Updating session
            session = {
              ...session,
              username: values?.username,
              session_token: session?.sessionToken,
              image: image?.data?.path ?? values?.image,
            };
            dispatch(updateSession(session));

            setTimeout(() => {
              callback({ success: 1, response: data });
            }, 1500);
          },
          (error) => {
            // console.log("error :- ", error);
            callback({ success: 0, message: error });
          }
        );
      } else {
        callback({ success: 2, message: image?.message });
      }
    });
  };
}

export function updateBasicUserData(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        callback({ success: 1, response: data });
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateUserLevel(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/userlevelupdate`;
  var body = { ...values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        // console.log("data :- ", data);
        if (data?.iserror == 0) {
          callback({ success: 1, response: data });
        } else {
          callback({ success: 2, message: data });
        }
      },
      (error) => {
        // console.log("error :- ", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export function _uploadImage(session, imagedata, filename, dispatch, callback) {
  if (imagedata.search(";base64,") > 0) {
    imagedata = imagedata.replace(/^data:image\/[a-z]+;base64,/, "");
    var imageJson = {
      resource: [
        {
          name: filename,
          type: "file",
          is_base64: true,
          content: imagedata,
        },
      ],
    };

    uploadEncodedFile(
      `${BASE_IMAGES_URL}?check_exist=false`,
      session,
      imageJson,
      dispatch,
      (data) => {
        if (data?.error) {
          callback({ success: 0, message: data?.error?.message });
        } else {
          callback({ success: 1, data: data });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  } else {
    callback({ success: 1 });
  }
}

export function changePassword(session, values, callback) {
  var url = `${INSTANCE_URL}api/v2/user/password?reset=true&login=true`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log("response :- ", response);

        // Updating session
        session = {
          ...session,
          session_token: response?.session_token,
        };
        dispatch(updateSession(session));

        setTimeout(() => {
          var returnData = { success: 1 };
          callback(returnData);
        }, 1500);
      },
      (error) => {
        var returnData = { success: 0, data: error.message };
        callback(returnData);
      }
    );
  };
}

export function fetchUserPlans(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${itemCount}&offset=${offset}`;
  var order = `order=planstartdate%20DESC`;
  var filter = encodeURI(`(useridfk=${session?.userid})`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users_plan_history?include_count=true&filter=(${filter})&${offSetQuery}&${order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_PLANS_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({
          status: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {}
    );
  };
}

export function fetchUserActivePlan(session, callback) {
  var filter = encodeURI(`(useridfk=${session?.userid})AND(isactive=1)`);
  var url = `${INSTANCE_URL}api/v2/memoryquiz/_table/users_plan_history?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: LOGIN_USER_ACTIVE_PLAN,
          payload: { data: response?.resource },
        });
        callback({ success: 1, data: response?.resource });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
}

export function subscription(session, values, callback) {
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "content-Type": "application/json",
      "X-DreamFactory-Api-Key": session?.apiKey,
    },
  };
  var url = STRIPE_URL;
  var body = { ...values };
  var response = axios.post(url, body, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, resource: data });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function cancelSubscription(session, values, callback) {
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "content-Type": "application/json",
      "X-DreamFactory-Api-Key": session?.apiKey,
    },
  };
  var url = STRIPE_URL;
  var body = { ...values };
  var response = axios.delete(url, body, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, resource: data });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function getPreviousPlanStatus(session, values, callback) {
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "X-DreamFactory-Api-Key": session?.apiKey,
    },
  };
  var url = `${STRIPE_URL}?res=${values?.res}&subId=${values?.subId}&plan=${values?.plan}`;
  var response = axios.get(url, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, resource: data });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function showUpcomingInvoiceModal(obj) {
  return { type: CHANGE_SUBSCRIPTION_MODAL, payload: obj };
}

export function updateSubscription(session, values, callback) {
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "content-Type": "application/json",
      "X-DreamFactory-Api-Key": session?.apiKey,
    },
  };
  var url = STRIPE_URL;
  var body = { ...values };
  var response = axios.patch(url, body, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, resource: data });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function getFreeSub(session,callback){
  var filter = encodeURI(`?userid=${session?.userid}`);
  var url = `${INSTANCE_URL}api/v2/getfreesub${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: LOGIN_USER_ACTIVE_PLAN,
          payload: { data: response },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
}