import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import LocaleStrings from "@language";

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
//gtm

const tagManagerArgs = {
  gtmId: 'GTM-W4JSZ7C',
  
  //gtmId: 'GTM-WWBFWHP'
}
const TRACKING_ID = "G-RETZD3PQZD"; // YOUR_OWN_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);
TagManager.initialize(tagManagerArgs)
function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: location.pathname,
        pageTitle: 'kojyto',
      },
    });
  }, [location])
    //ReactGA.send({ hitType: "pageview", page: location.pathname });
  }


function App() {
  usePageViews();
  useEffect(() => {
    var userLang = LocaleStrings.getInterfaceLanguage();
    // console.log("userLang", userLang);

    LocaleStrings.setLanguage(userLang);
    // console.log("getLanguage :- ", LocaleStrings.getLanguage());
  });

  return (
    <div className="App h-screen overflow-hidden">
      <Routes>
        <Route path="/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
      </Routes>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
