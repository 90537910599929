import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { selectedClassTeacher } from "@myspaceaction";
import { selectedClass,fetchCourse } from "@classesaction";

import { BASE_IMAGES_URL } from "@constant";
import LocaleStrings from "@language";
import classImg from "../../../assets/image/defaultclassimg.png";
import blankImg from "../../../assets/image/blank_classes.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TeacherCarousel = (props) => {
  const navigate = useNavigate();
  var { session, allClassAndItsChapter,fetchCourse } = props;
  const [Chapter,SetChapter] = useState([]);
  const slider = React.useRef(null);
    const [Init,SetInit] = useState(false);
  
  useEffect(()=>{
    if(Init == false){
        SetInit(true);
        fetchCourse(session, "" ,{
            userid: session?.userid,
            classmembers: false,
            myclass: false,
            classchapters: true,
        }, 0,999,function(res){
            SetChapter(res.data)
            console.log(res.data);
        });
    }

  })

  const navigateClasses = (item) => {
    // console.log("item :- ", item);
    var teacher = _.filter(item?.programmembers_by_classidfk, (obj) => {
      return obj?.isadmin == 1 && item?.createdby == obj?.useridfk;
    });
    // console.log("teacher :- ", teacher);

    //props.selectedClassTeacher(teacher);
    //props.selectedClass(item);
    navigate(`/classes/${item.classid}`);
  };

  const renderCourese = () => {
    return _.map(Chapter, (item, index) => {
      var classImage = item?.image
        ? `${BASE_IMAGES_URL}/${item?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
        : classImg;
      return (
        <div
          key={`course_key_${index}`}
          onClick={() => navigateClasses(item)}
          className="bg-white border shadow-lg rounded-xl cursor-pointer hover:shadow-xl"
        >
          <div className="w-full bg-theme rounded-tl-xl rounded-tr-xl">
            <div className="h-48">
              <img
                className="object-cover rounded-tl-xl rounded-tr-xl h-full w-full"
                src={classImage}
              />
            </div>
          </div>
          <div className="px-4 py-5">
            <div>
              <div className="text-2xl 2xs:text-3xl text-primary font-normal h-20">
                {item?.programname} <br/>
                {item.level}
              </div>
              
              
            </div>
          </div>
        </div>
      );
    });
  };

  const next = () => slider.current.slickNext();
  const previous = () => slider.current.slickPrev();

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="text-2xl 2xs:text-3xl font-semibold text-primary capitalize mb-8">
            Choisir mon Quizz
      </div>
      {Chapter?.length > 0 ? (
        <>
          <Slider {...settings} className="custom-silck" ref={slider}>
            {renderCourese()}
          </Slider>
          <div className="mt-8 text-center space-x-3">
            <button type="button" className="" onClick={previous}>
              <ChevronLeftIcon className="h-6 w-5" />
            </button>
            <button type="button" className="" onClick={next}>
              <ChevronRightIcon className="h-6 w-5" />
            </button>
          </div>
        </>
      ) : (
        <div className="custom-item-center max-w-full mx-auto shadow-lg border-1 rounded-lg h-60 md:h-26rem">
          <div className="text-center">
            <img className="inline" src={blankImg} />
            <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
              {LocaleStrings.myspace_not_available}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    classList: state.classList,
    allClassAndItsChapter: state.studentAllClassList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ selectedClassTeacher, selectedClass, fetchCourse }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeacherCarousel);
